.quill {
  height: 100%;

  .ql-container {
    border: none;
    font-size: 15px;
    font-family: 'Urbanist';
    font-weight: 400;

    &.ql-snow {
      strong {
        font-weight: 700 !important;
      }
    }

    .ql-editor {
      padding: 0;

      &::before {
        left: 0;
      }
    }
  }
}

// --ql-snow css override--

.ql-snow {
  .ql-picker {
    &.ql-expanded {
      .ql-picker-options {
        top: unset;
        bottom: 25px;
      }

      .ql-picker-label {
        background: #ece9e9;
        border-color: #ece9e9 !important;
        border-radius: 6px;
        color: #5f5f5f;
      }
    }
  }
}
