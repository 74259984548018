.profilePageContent {
  .profileTabTeam {
    display: flex;
    height: 100%;
    @include phone {
      display: block;
    }
    .illumeetProfile {
      width: 100%;
      .illuProfileContent {
        display: flex;
        height: 100%;
        @include phone {
          display: block;
        }
        .react-tabs {
          display: flex;
          height: 100%;
          width: 100%;
          @include mobileTabletNew {
            display: block;
          }
        }
        .illuProfileButton {
          border-right: 1px solid #ebebeb;
          flex: 0 0 25%;
          position: relative;
          padding-top: 40px;
          @include mobileTabletNew {
            border-right: none;
          }
          @include phone {
            border: none;
            padding-top: 20px;
          }
          .react-tabs__tab-list {
            border: none;
            @include mobileTabletNew {
              display: flex;
              width: 100%;
              overflow-x: auto;
              padding-top: 0px;
              overflow-y: hidden;
              margin-bottom: 60px;
            }
            .react-tabs__tab {
              padding-left: 0px;
              margin-bottom: 12px;
              height: 31px;
              display: flex;
              align-items: center;
              font-size: 16px;
              display: block;
              @include mobileTabletNew {
                height: 31px;
              }
              &:focus {
                box-shadow: none !important;
                display: block;
                border-color: none !important;
                &::after {
                  position: absolute !important;
                  right: 0px !important;
                  width: 100% !important;
                  display: flex;
                  justify-content: flex-end;
                }
              }
              @include mobileTabletNew {
                font-size: 14px;
                display: block;
                min-width: 129px;
                margin-bottom: 0px;
              }
              &:first-child {
                @include mobileTabletNew {
                  min-width: 85px !important;
                }
              }
            }
            .react-tabs__tab--selected {
              border: none;
              height: 31px;
              position: relative;
              &:after {
                content: '\f054';
                font-family: 'Font Awesome 5 Free';
                position: absolute;
                font-weight: 900;
                top: 7px;
                right: 0px;
                width: 19px;
                height: 5px;
                font-size: 14px;
                color: #333;
                @include mobileTabletNew {
                  display: none;
                }
              }
            }
          }
          // .MuiTabs-flexContainer{
          //     display: block;
          //     white-space: pre-wrap;
          //     @include phone{
          //         display: flex;
          //     }
          //     .MuiButtonBase-root{
          //         width: 100%;
          //         margin-bottom: 10px;
          //         .MuiTab-wrapper{
          //             display: block;
          //             text-align: left;
          //             font-family: $FontRubik;
          //             font-size: 16px;
          //             text-transform: capitalize;
          //             @include phone{
          //                 font-size: 13px;
          //                 line-height: 17px;
          //             }
          //         }
          //     }
          //     .Mui-selected{
          //         .MuiTab-wrapper{
          //             color: #02000a;
          //         }
          //     }
          // }
          // .MuiTabs-indicator{display: none;}
        }
        .illuProfileBox {
          flex: 0 0 75%;
          margin-top: 40px;
          padding: 0px 20px;
          box-sizing: border-box;
          @include phone {
            padding: 0px;
          }
        }
        .manageAccountColl {
          .filterSearchBar {
            .SearchGrid {
              @include phone {
                width: 100%;
              }
            }
            .eventControlSearch {
              width: 100%;
              .filterSerchSubmit {
                @include phone {
                  width: 100%;
                }
                input {
                  @include phone {
                    max-width: 100%;
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .illumeetProfileTeam {
      flex: 0 0 25%;
      background: #f1f1f1;
      border-radius: 25px;
      margin-top: 40px;
      display: none;
      @include phone {
        padding: 70px 20px;
        border-radius: 0px;
        position: fixed;
        top: 40px;
        width: 76%;
        right: -90%;
        display: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        bottom: 0px;
      }
      .profileTeamContent {
        padding: 30px 25px 30px;
        background: #f1f1f1;
        border-radius: 20px;
        position: relative;
        @include phone {
          padding: 15px;
          padding-left: 0px;
        }
        @include large {
          padding: 60px 25px 30px;
        }
        .teamTitle {
          color: #3f3e43;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 40px;
          @include phone {
            margin-bottom: 20px;
            margin-top: 10px;
          }
        }
        .addMemberBox {
          margin-top: 20px;
          @include large {
            margin-top: 40px;
          }
          a {
            color: #5b33f1;
            font-weight: 500;
            font-size: 15px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .teamMemberBox {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e4dcdc;
          padding-bottom: 5px;
          margin-bottom: 5px;
          .teamMemberImg {
            margin-right: 10px;
            border: 2px solid #fff;
            border-radius: 50%;
            img {
              border-radius: 50%;
              height: 50px;
              object-fit: cover;
              width: 50px;
              @include phone {
                width: 40px;
                height: 40px;
              }
            }
          }
          .teamMemberName {
            h3 {
              color: #02000a;
              font-size: 15px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

// edit profile
.editProfileColl {
  width: 70%;
  margin: auto;
  @include mobileTabletNew {
    width: 100%;
  }
}
.updatePasswordCol {
  width: 70%;
  margin: auto;
  @include mobileTabletNew {
    width: 100%;
  }
}
.profileBox {
  .editProfieImg {
    text-align: center;
    .editProfieInner {
      display: inline-block;
      position: relative;
      .profileImgBox {
        border: 1px solid $themeOrangeColor;
        padding: 3px;
        border-radius: 50%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .profileEditButton {
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border: 3px solid #fff;
        border-radius: 50%;
        input {
          height: 35px;
          left: 0;
          opacity: 0;
          position: absolute;
          width: 100%;
          &:after {
            background: transparent;
            content: '';
            cursor: pointer;
            height: 35px;
            left: 0;
            position: absolute;
            top: 0;
            width: 35px;
            z-index: 0;
          }
        }
        .buttonText {
          background: #5b33f1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 15px;
          font-weight: 500;
          color: #fff;
          cursor: pointer;
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
.formSection {
  .submitForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include phone {
      margin-top: 50px;
    }
    button {
      text-transform: uppercase;
      font-size: 17px;
      &.outlineButton {
        background-color: transparent;
      }
    }
  }
  .formRestLink {
    margin-top: 40px;
    p {
      color: #8c8b8f;
      font-size: 15px;
      @include phone {
        font-size: 13px;
        text-align: center;
      }
      a {
        color: #ff456e;
        text-decoration: none;
      }
    }
  }
}
.react-swipeable-view-container {
  .MuiBox-root {
    padding: 0px 20px;
    @include phone {
      padding: 0px;
    }
  }
}

.manageAccountColl {
  .manageAccountBox {
    padding: 40px 0;
    border-bottom: 1px solid #ebebeb;
    h3 {
      font-size: 18px;
      color: #3f3e43;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 0px;
    }
    p {
      font-size: 15px;
      color: #7b7b82;
      margin-bottom: 10px;
    }
    a {
      color: #5b33f1;
      font-weight: 500;
      font-size: 15px;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #5b33f1;
        text-decoration: underline;
      }
    }
    &:last-child {
      border-bottom: 0px;
    }
  }
  .deleteAccount {
    a {
      color: #ff456e;
      cursor: pointer;
      &:hover {
        color: #ff456e;
      }
    }
  }
}

// Profile Illumeet swipe
.illumeetProfileTeam {
  &.Open {
    right: -40px !important;
    padding: 40px 20px !important;
    display: block !important;
  }
}
.closeMobileSidebar {
  position: absolute;
  top: 9px;
  width: 27px;
  height: 27px;
  background-color: #7b7b82;
  color: #fff;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.illumeetProfileTeam.Open .closeMobileSidebar {
  display: flex;
}
.ProfileSidebArOpener {
  position: fixed;
  top: 143px;
  right: 0px;
  width: 48px;
  height: 48px;
  background-color: #3e2abe;
  border: 1px solid #3e2abe;
  font-size: 22px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  z-index: 99999;
  display: none;
  @include phone {
    display: flex;
  }
}
.domyImageBox {
  width: 90px;
  height: 90px;
  background-color: lightgrey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #000;
    font-size: 13px;
  }
}
.manageAccountColl {
  .dashboardMiddleArea {
    padding: 0px !important;
    .contactTableInner {
      padding-left: 0px !important;
      table {
        tr {
          th {
            &:last-child {
              @include phone {
                display: none;
              }
            }
          }
          td {
            &:last-child {
              @include phone {
                display: none;
              }
            }
          }
        }
      }
    }
    .themeBlueButton {
      min-width: 200px;
      font-size: 15px;
    }
  }
}

.manageTeam_table{
  .table{
    tbody{
      tr{
        td{
          &.tableDotMore{
            max-width: initial !important;
            min-width: auto !important;
          }
        }
      }
    }
  }
}