.EmailTemplatesArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include phone {
    display: block;
  }
  .EmailTemplateList {
    margin-bottom: 50px;
    flex: 0 0 23%;
    @include tablet {
      flex: 0 0 32%;
    }
    .EmailTemplateImage {
      box-shadow: 0 3px 70px rgba(41, 62, 130, 0.16);
      background: #ffffff;
      border-radius: 22px;
      border: 6px solid #fff;
      overflow: hidden;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
    .EmailTemplateContent {
      h3 {
        margin: 0px;
        padding-bottom: 10px;
        color: #02000a;
        font-family: 'Segoe UI Bold';
        font-size: 16px;
      }
      p {
        color: #7b7b82;
        font-size: 15px;
        margin: 0px;
        font-family: 'Segoe UI Regular';
      }
    }
  }
}

// Templates Sequance
.templateSequance {
  margin-top: 30px;
  h2 {
    font-size: 32px;
    color: #02000a;
    font-weight: 400;
    margin-bottom: 25px;
    @include phone {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  .templateSeqArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include phone {
      display: block;
    }
    .templateSeqBoxen {
      background: #f8f8f8;
      border-radius: 17px;
      padding: 20px;
      height: 100%;
      margin-bottom: 30px;
      flex: 0 0 20%;
      @include tablet {
        flex: 0 0 26%;
      }
      @include phone {
        margin-bottom: 15px;
      }
      .pms_heading {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        display: -webkit-flex;
        img {
          margin-right: 10px;
        }
        h6 {
          color: #3f3e43;
          font-weight: 500;
          font-size: 15px;
          margin: 0px;
        }
      }
      .pms_details {
        height: 60px;
        p {
          color: #908e9a;
          font-size: 15px;
          line-height: 20px;
          margin-top: 0px;
        }
      }
    }
  }
}
