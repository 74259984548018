// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$red: (
  "base":       #ff0404
);
$blue-primary: #000056;
$blue-base: #070029;
$blue-darken: #090039;
$blue-lighten: #3a4580;
$blue-lighten-1: #F5F5FF;
$blue-lighten-2: #e5f5ff;
$blue-lighten-3: #EBF7FF;
$blue: (
  "primary": #000056,
  "base":    #070029,
  "darken":  #090039,
  "lighten": #3a4580,
  "lighten-1":#F5F5FF,
  "lighten-2":#e5f5ff,
  "lighten-3":#EBF7FF,
);
$green-base: #00BE6B;
$green-lighten: #ebfaf3;
$green-lighten-1: #E1FBF5;
$green-lighten-2: #E9F5F0;
$green-lighten-3: #e5f8f0;
$green: (
  "base":       #00BE6B,
  "lighten":   #ebfaf3,
  "lighten-1":  #E1FBF5,
  "lighten-2":  #E9F5F0,
  "lighten-3": #e5f8f0,
  
);
$purple-primary-color: #5141E7;
$purple-primary-hover: #4737DB;
$purple-primary-selected: #ECEAFF;
$purple: (
  "primary-color": #5141E7,
  "primary-hover": #4737DB,
  "primary-selected": #ECEAFF,
);

$orange-base: #FF8B55;
$orange: (
  "base":       #FF8B55
);

$red: (
  "base":       #ED5267
);



$red-base: #ED5267;
 

$grey-base: #000000;
$grey-darken: #2B2B30;
$grey-darken-1: #666666;
$grey-darken-2: #999999;
$grey-lighten-1: #eeeff2;
$grey-lighten-2: #434343;
$grey-lighten-3: #6a6a6a;
$grey-lighten-4: #EEEFF2;
$grey-lighten-5 : #bdbdbd;
$grey-lighten-6 : #dadada;
$grey-lighten-7: #E5E5E5;
$grey-lighten-8: #f4f4f4;
$grey-lighten-9: #f8f8f8;

$grey: (
  "base":       #000000,
  "darken":     #2B2B30,
  "darken-1":   #666666,
  "darken-2":   #999999,
  "lighten-1":  #eeeff2,
  "lighten-2":  #434343,
  "lighten-3":  #6a6a6a,
  "lighten-4":  #EEEFF2,
  "lighten-5" : #bdbdbd,
  "lighten-6" : #dadada,
  "lighten-7":  #E5E5E5,
  "lighten-8":  #f4f4f4,
  "lighten-9":  #f8f8f8
);

$shades-black:#000000;
$shades-white: #ffffff;
$shades-transparent: transparent;
  

$shades: (
  "black":        #000000,
  "white":        #ffffff,
  "transparent":  transparent,
 
);


$colors: (
  "red": $red,
  "blue": $blue,
  "green": $green,
  "purple": $purple,
  "orange": $orange,
  "grey": $grey,
  "shades": $shades,
) !default;

$primary-text: #000056;
$text-light: #726F8E;
$lighten-blue: #3a4580;
$bg-green: #28E686;
$bg-orange: #F29B35;
$orange-number: #F26135;
$blue-number: #16D1FC;
$purple-number: #7D35C1;
$green-number: #0ADDB4;
$bg-grey: #C5C7D0;
$heading-color: #000056;
$tags-color: #5141e7;

.bg-green{
  background-color: $bg-green !important;
}
.bg-orange{
  background-color: $bg-orange !important;
}
.bg-grey{
  background-color: $bg-grey !important;
}

.orange-color{
  color: $orange-number !important;
}
.blue-color{
  color: $blue-number !important;
}
.purple-color{
  color: $purple-number !important;
}
.green-color{
  color: $green-number !important;
}
.primary-text{
  color: $primary-text !important;
}
.secondary-text{
  color: $tags-color !important;
}

.text-primary-light{
  color: $text-light !important;
}

// Color Classes

@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else if $color_name != "shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

// Shade classes
@each $color, $color_value in $shades {
  .#{$color} {
    background-color: $color_value !important;
  }
  .#{$color}-text {
    color: $color_value !important;
  }
}


// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);
    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }
  @return null;
}

