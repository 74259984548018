// .MuiCheckbox-root{

//     .MuiSvgIcon-root{
//         width: 1.6em;
//         height: 1.6em;
//     }
// }
// .MuiTextField-root{

//     .MuiFormLabel-root{
//         font-size: 1.4rem;
//     }
// }
// .MuiInputBase-input{
//     font-size: $fs-14 !important;
// }
// div, p {
//     font-size: 14px;
// }
// .MuiButton-root{
//     font-size: 1.4rem !important;
// }

a{
    font-size: 14px;
}