a {
  text-decoration: none !important;
  color: inherit;
  &:hover {
    color: inherit !important;
  }
}

html {
  line-height: 1.5;
  font-size: 16px;
  font-weight: normal;
}

// Header Styles

.subtitle-1 {
  font-size: $fs-14;
}

.subtitle-2 {
  font-size: $fs-13;
}

.heading {
  font-size: 2rem; // 32px
  font-weight: 500;
  color: $heading-color;
  margin: 0px;
}

.subsecondary {
  font-size: 1.5rem; // 24px
  font-weight: 300;
  color: $heading-color;
}

.secondary {
  font-size: 1.5rem; // 24px
  font-weight: 700;
  color: $heading-color;
}

.subtitle {
  font-size: 1.125rem; // 18px
  font-weight: 500;
  color: $heading-color;
}
.subtitle-regular {
  font-size: 1.125rem; // 18px
  font-weight: 400;
  color: $heading-color;
}

.paragraph {
  font-size: 1rem; // 16px
  font-weight: 400;
  color: $heading-color;
}

h5 {
  font-size: 0.85rem; //13.6px
}

.small-subtitle {
  font-size: 0.875rem; // 14px
  font-weight: 400;
  line-height: 22px;
  color: #726f8e;
}

// Text Styles
em {
  font-style: italic;
}

strong,
.bold {
  font-weight: 500 !important;
}

small {
  font-size: 75%;
}

.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}

.bolder {
  font-weight: 700 !important;
}
