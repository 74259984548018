/* @import 'react-chat-widget/lib/styles.css'; */
.rcw-widget-container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
  position: fixed;
  right: 0;
  z-index: 99999999999;
}
