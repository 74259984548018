.sidebarCompo {
  .sidebarMainScreen {
    background-color: $sidebarBackground;
    padding: 10px 10px;
    height: 100vh;
    box-sizing: border-box;

    @include phone {
      padding: 20px 10px;
    }

    .sidebarBrand {
      text-align: center;

      img {
        width: 100%;

        @include phone {
          width: 55px;
        }
      }
    }

    .navigationMenu {
      margin-top: 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 70px);

      ul, .ul{
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li, .li {
          position: relative;
          margin-bottom: 4px;

          @include phone {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }

          a {
            display: flex;
            padding: 6px 5px;
            color: #fff !important;
            border-radius: 7px;
            text-decoration: none;
            align-items: center;
            flex-direction: column;
            text-align: center;

            @include phone {
              width: 48px;
              box-sizing: border-box;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            span {
              display: inline;

            }

            .leftIcon {
              padding-bottom: 5px;
            }

            .navMenus {
              font-size: 14px;
              font-weight: 400;

              @include phone {
                display: none;
              }
            }
          }

          &.active {
            a {
              background: $themeDarkBlue;
              color: $themelightYellow;
            }
          }
        }
      }
    }
  }
}