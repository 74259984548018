.campaignMainBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  @include phone {
    display: block;
  }
  .campaignFilter {
    flex: 0 0 50%;
    @include phone {
      margin-bottom: 15px;
    }
    ul {
      display: flex;
      li {
        list-style-type: none;
        font-size: 15px;
        font-weight: 500 !important;
        padding-bottom: 0px;
        color: #3f3e43;
        padding: 0px 15px;
        line-height: 15px;
        cursor: pointer;
        border-right: 1px solid $borderColor;
        &:last-child {
          border: none !important;
        }
        &.active {
          color: $themeDarkBlue;
        }
      }
    }
  }
  .campaignSearchBar {
    .searchCampaignsForm {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        width: 60px;
        height: 45px;
        background: #fafafa;
        border: 1px solid #fafafa;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        cursor: pointer;
        i {
          color: #3f3e43;
        }
      }
      .form-control {
        border: none;
        color: #3f3e43;
        outline: 0;
        background: #fafafa;
        border: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        padding: 0;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        height: 45px;
        outline: 0;
        min-width: 210px;
        &:focus {
          box-shadow: none !important;
          outline: 0px !important;
        }
        &::placeholder {
          color: #3f3e43;
        }
      }
    }
  }
}

// edit template Cards
.edittemplateCards {
  .editTcardList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0px;
    @include phone {
      display: block;
    }
    .editTemplateBox {
      flex: 0 0 18.5%;
      margin-right: 15px;
      margin-bottom: 30px;
      width: 18.5%;
      position: relative;
      @include phone {
        margin-bottom: 30px;
        margin-right: 0px;
      }
      @include tabletPortrait {
        flex: 0 0 47%;
        margin-bottom: 20px;
        margin-right: 0px;
        width: 47%;
      }
      @include normalMiddiumScreen {
        flex: 0 0 18%;
        width: 18%;
      }
      @include tablet1 {
        flex: 0 0 47%;
        width: 47%;
      }
      .editTemplateInnerB {
        padding: 18px 12px;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        max-height: 340px;
        min-height: 340px;
        h3 {
          padding-bottom: 5px;
        }
        .EditEmailCampaignImg {
          height: 280px;
          width: 100%;
          overflow: hidden;
          @include phone {
            height: auto;
          }
          @media screen and (min-width: 1025px) and (max-width: 1199px) {
            height: 210px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1366px) {
            height: 230px;
          }
          @media screen and (min-width: 1500px) and (max-width: 1600px) {
            height: 320px;
          }
          @media screen and (min-width: 1601px) and (max-width: 1700px) {
            height: 360px;
          }
          @media screen and (min-width: 1701px) and (max-width: 1800px) {
            height: 400px;
          }
          @media screen and (min-width: 1801px) and (max-width: 1950px) {
            height: 440px;
          }
          @media screen and (min-width: 1951px) and (max-width: 2200px) {
            height: 450px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .templateSaveThe {
          height: unset;
        }
        .editEmailCampaignBlank {
          height: 280px;
          @media screen and (min-width: 1025px) and (max-width: 1199px) {
            height: 210px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1366px) {
            height: 230px;
          }
          @media screen and (min-width: 1500px) and (max-width: 1600px) {
            height: 320px;
          }
          @media screen and (min-width: 1601px) and (max-width: 1700px) {
            height: 360px;
          }
          @media screen and (min-width: 1701px) and (max-width: 1800px) {
            height: 400px;
          }
          @media screen and (min-width: 1801px) and (max-width: 1950px) {
            height: 440px;
          }
          @media screen and (min-width: 1951px) and (max-width: 2200px) {
            height: 450px;
          }
          .EcbInnerBox {
            width: 100%;
            height: 100%;
            span {
              width: 100%;
              display: block;
              &:first-child {
                background-color: #e4e4e4;
                height: 10%;
              }
              &:nth-child(2) {
                height: 30%;
                background-color: #f5f5f5;
              }
              &:nth-child(3) {
                height: 50%;
                background-color: #fff;
              }
              &:last-child {
                height: 10%;
                background-color: #e4e4e4;
              }
            }
          }
        }
      }
      &.EDTActive {
        .CRCircularBox {
          border: 1px solid $themeDarkBlue;
        }
        .selectTText {
          color: $themeDarkBlue;
        }
      }
      h3 {
        font-size: 15px;
        font-weight: 500 !important;
        padding-bottom: 0px;
        color: #3f3e43;
        @media screen and (min-width: 1200px) and (max-width: 1366px) {
          font-size: 13px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1199px) {
          font-size: 12px;
        }
      }
      .selectTText {
        padding: 8px 5px;
        text-align: center;
        cursor: pointer;
      }
      .delete-button {
        background-color: #5b34f1 !important;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        border: none;
        padding: 16px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .edit-button {
        background-color: #5b34f1 !important;
        position: absolute;
        top: 0px;
        right: 33px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        border: none;
        padding: 16px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  .submitForm {
    @include phone {
      padding: 10px;
    }
    button {
      min-width: 170px;
      &:first-child {
        margin-right: 15px;
        @include phone {
          margin: 0px;
          margin-bottom: 15px;
        }
      }
      @include phone {
        width: 100%;
      }
    }
  }
}
.mobileBaseMargin20px {
  @include phone {
    margin-top: 20px;
  }
}
.clean-body {
  .nl-container {
    tr {
      td {
        width: 100%;
        overflow: auto;
      }
    }
  }
}
.emailCampTable {
  padding: 10px 15px;
  box-sizing: border-box;
  padding-bottom: 90px !important;
  table {
    thead {
      tr {
        th {
          box-sizing: border-box;
        }
      }
    }
    tbody {
      tr {
        td {
          box-sizing: border-box;
          &:first-child {
            padding-left: 12px !important;
          }
          &.tableDotMore {
            width: 100% !important;
            display: flex !important;
            justify-content: flex-end;
          }
          span {
            &.anView {
              cursor: pointer;
              text-decoration: none;
              color: $themeDarkBlue;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.reschedule_Page {
  padding: 0px 20px;
}
.recipientsMainsec {
  .contactsTable {
    .contactTableInner {
      table {
        thead {
          tr {
            th {
              white-space: nowrap;
              &:first-child {
                max-width: 230px !important;
                min-width: 230px !important;
              }
              &:nth-child(2) {
                max-width: 180px !important;
                min-width: 180px !important;
              }
              &:nth-child(4) {
                max-width: 150px !important;
              }
              &:last-child {
                width: 100%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              &:first-child {
                max-width: 230px !important;
                min-width: 230px !important;
              }
              &:nth-child(2) {
                max-width: 180px !important;
                min-width: 180px !important;
              }
              &:nth-child(4) {
                max-width: 150px !important;
              }
              &:last-child {
                width: 100%;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

// Edit Template Name Enter
.templateNameField {
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  @include phone {
    padding-top: 85px !important;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
  }
  form {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .fieldFroup20 {
      flex: 0 0 20%;
      select {
        background: #fafafa;
      }
    }
    .fieldFroup22 {
      flex: 0 0 22%;
      select {
        background: #fafafa;
      }
    }
    .field-group {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .fieldFroup30 {
    flex: 0 0 30%;
    select {
      background: #fafafa;
    }
  }
}
.react-confirm-alert-body{
  padding: 15px 15px 85px 15px !important;
  color: #707070;
  font-family: 'Rubik', sans-serif !important;
  h1{
    padding: 10px !important;
    color: #000000;
  }
}
.filterImport_EmailReports{
  width: 100%;
  justify-content: flex-end;
}
.editingDashboard {
  .templateNameField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chooseEmail_fields32 {
      display: flex;
      align-items: center; 
      .inputField{
        margin-left: 15px;
        &:first-child{
          margin-left: 0px;
        }
        @include phone{
          margin-left: 0px;
        } 
      }
      .event_list_che34 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .enable_checkbox32{
          display: flex;
          align-items: center; 
          justify-content: flex-start;
        }
        input {
          margin: 0px;
          margin-bottom: 0px !important;
          box-shadow: none !important;
          margin-right: 8px;
        }
        label {
          margin: 0px !important;
        }
      }
    }
  }
}

.emailEditorForm {
  div {
    min-height: 100vh !important;
  }
}

.analytics_champaign32{
  margin-bottom: 30px;
  .campaign_time32{
    margin-bottom: 20px;
  }
  .campaign_audience32{
    ul{
      padding-left: 0px;
      background-color: #fafafa;
      padding: 20px;
      border-radius: 12px;
      li{
        list-style-type: none;
        a{
          color: #5b33f1 !important;
          text-decoration: none;
          &:hover{
            color: #5b33f1 !important;
          }
        }
      }
    }
  }
  h2{
    color: #3F3E43;
    font-size: 17px;
    font-weight: 500 !important;
    width: 100%;
    display: block;
    padding-bottom: 0px;
    margin-bottom: 5px !important;
  }
}