.pageEditingToolBarRoot {
  .editingHeader {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px -4px;
    @include phone {
      padding: 15px;
      border-bottom: 1px solid #ebebeb;
      border-radius: 0px;
      margin: 0px;
      position: fixed;
      left: 0px;
      background-color: #fff;
      width: 100%;
      box-sizing: border-box;
      z-index: 999;
    }
    .editingHeading {
      h3 {
        display: flex;
        align-items: center;
        font-size: 22px;
        color: #02000a;
        font-weight: 500;
        letter-spacing: 0.2px;
        margin: 0px;
        @include phone {
          font-size: 18px;
        }
        a {
          background: #fff;
          border: 1px solid #e3e3e3;
          border-radius: 17px;
          padding: 4px 7px;
          margin-right: 8px;
          width: 40px;
          height: 42px;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          @include phone {
            width: 26px;
            height: 26px;
          }
          @include large {
            width: 45px;
            height: 48px;
          }
          img {
            @include phone {
              width: 16px;
            }
          }
        }
      }
    }
    .editingSubmitView {
      .mobile-None {
        @include phone {
          display: none;
        }
      }
      .outlineButton {
        font-size: 17px;
        background-color: transparent;
        line-height: 28px;
      }
      .saveButton {
        line-height: 33px;
      }
      .backup {
        color: #908e9a;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        margin-right: 20px;
      }
      .right_checkBttn {
        width: 50px;
        height: 50px;
        background-color: #67d25f;
        border-radius: 50%;
        border: none;
        font-size: 21px;
        cursor: pointer;
        .fa {
          color: #fff;
        }
      }
    }
  }
}

.react-form-builder {
  padding-bottom: 120px;
  .react-form-builder-toolbar {
    margin-top: 0px !important;
    margin-bottom: 20px;
    background-color: #f1f1f1 !important;
    width: 25% !important;
    border-radius: 25px !important;
    box-sizing: border-box;
    padding: 25px 20px 50px 20px;
    @include phone {
      display: none !important;
    }
    h4 {
      margin-top: 20px !important;
      text-align: left !important;
      color: #333;
      font-size: 22px;
      padding: 15px 0px;
      border-bottom: 1px solid lightgrey;
    }
    ul {
      margin-top: 20px !important;
      li {
        cursor: pointer;
        list-style: none;
        margin: 5px;
        padding: 10px;
        border: none !important;
        font-size: 15px;
        padding-left: 0px !important;
        margin-left: 0px !important;
        color: #333;
        border-bottom: 1px solid lightgrey !important;
        position: relative;
        text-align: left;
        &::after {
          content: '\f054';
          font-family: 'Font Awesome\ 5 Free';
          // position: absolute;
          // right: 0px;
          // top: 0px;
        }
        &:last-child {
          border: none !important;
        }
        i {
          margin-left: 0px !important;
        }
      }
    }
  }
}
.react-form-builder > div {
  display: flex;
  justify-content: space-between;
}
.react-form-builder .react-form-builder-preview {
  position: relative;
  width: 70%;
  border: none !important;
  background: #fff !important;
  padding: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  min-height: 750px;
  border-radius: 20px;
  @include phone {
    min-height: auto !important;
  }
}
.react-form-builder-preview {
  .Sortable {
    width: 70%;
    margin: auto;
    padding: 50px 0px;
    @include phone {
      width: 100%;
    }
  }
  h3.static {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
}
.SortableItem {
  .form-group {
    label {
    }
    .form-control {
      width: 100%;
      min-height: 50px;
      background: #fafafa;
      border: 1px transparent;
      color: #3f3e43;
      font-weight: 500;
      font-size: 15px;
      padding: 10px 20px;
      box-sizing: border-box;
      border-radius: 0.35rem;
      font-weight: 400;
      letter-spacing: 0.3px;
      box-shadow: 0px 0px 4px #dedede;
      border: 1px solid transparent;
    }
  }
}
.react-form-builder-form {
  position: relative;
  width: 50%;
  background: #fff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 50px 100px;
  margin: auto;
  margin-top: 70px;
  box-sizing: border-box;
  margin-bottom: 120px;
  @include tabletPortrait {
    padding: 50px;
    width: 80%;
  }
  h3.static {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
  .btn-toolbar {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .btn {
      background-color: #5b33f1 !important;
      border: 1px solid #5b33f1 !important;
      border: none !important;
      font-weight: 500 !important;
      color: #ede83a !important;
      box-sizing: border-box;
      line-height: 21px !important;
      letter-spacing: 1px !important;
      font-family: 'Rubik', sans-serif !important;
      min-height: 60px;
      border-radius: 10px !important;
      cursor: pointer;
      padding: 10px 25px !important;
      min-height: 50px !important;
      min-width: 190px;
      text-transform: uppercase;
    }
  }
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.editingDashboardView23 {
  @include phone {
    padding-top: 130px;
  }
}
.MuiTab-root {
  @media (min-width: 600px) {
    min-width: auto !important;
  }
}
.pageTabletView {
  display: block;
  // background-image: url('../assets/images/tabletBKdd.png');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
  height: 1000px;
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0px;
  .registorEditiorSection {
    // margin-top: 80px;
    width: 60%;
    max-width: 60%;
    margin: auto;
    position: relative;
    height: 1000px !important;
    box-sizing: content-box;
    border-width: 40px 20px 20px;
    border-style: groove;
    border-top-color: #252525;
    border-left-color: #252525;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-radius: 24px;
    &.registerEditingMobil34 {
      padding: 20px;
      box-sizing: border-box;
      overflow: auto;
    }
    .magzine_carousel {
      .magzine_item {
        .magzineImage {
          height: 190px;
        }
      }
    }
    .vedioPortion {
      #introVideo {
        min-height: 270px;
      }
    }
  }
  .pagedesignVhScreen {
    height: 100vh;
    overflow: auto;
    padding-bottom: 30px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #c1c1c1 !important;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 9px;
      width: 6px;
    }
  }
}
.pageMobileView {
  display: block;
  // background-image: url('../assets/images/mobilebkdd.png');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
  height: 90vh;
  width: 100%;
  margin: auto;
  position: relative;
  padding: 0px;
  top: 2vh;
  .registorEditiorSection {
    width: 420px;
    margin: auto;
    position: relative;
    top: 1vh;
    height: 90vh !important;
    overflow: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 5px;
    position: absolute;
    // inset: 90px 19px 30px 10px;
    @include phone {
      width: 75%;
    }
    @media screen and (min-width: 1010px) and (max-width: 1050px) {
      width: 420px;
    }
    @media screen and (min-width: 1270px) and (max-width: 1290px) {
      width: 420px;
    }
    @media screen and (min-width: 1350px) and (max-width: 1380px) {
      width: 420px;
    }
    @include tabletPortrait {
      width: 50% !important;
    }
    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #c1c1c1 !important;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 9px;
      width: 0px;
    }
  }
}
.pageMobileView {
  display: block !important;
  .registorEditiorSection {
    display: block;
    position: relative;
    padding: 15px;
    border-color: #000;
    box-sizing: border-box;
    margin: 0px auto;
    border-width: 40px 20px 20px;
    border-style: groove;
    border-top-color: #252525;
    border-left-color: #252525;
    border-right-color: #212121;
    border-bottom-color: #212121;
    border-radius: 24px;
    &.registerEditingMobil34 {
      box-sizing: border-box;
      // height: 72vh !important;
      .react-form-builder-form {
        box-shadow: none;
        padding: 0px;
      }
    }
    .makeStyles-brandLogo-2 {
      img {
        width: 80px;
      }
    }
    .brandProfileInfo {
      padding: 20px 0px;
    }
    .formBanner {
      background-size: cover;
      min-height: auto;
      box-shadow: none;
    }
    .banner_content {
      display: block;
      padding: 10px;
      .banner_heading {
        h2 {
          font-size: 23px;
          text-align: center;
          padding: 10px;
          width: 90%;
          margin: auto;
          @media (min-width: 1500px) {
            font-size: 19px;
          }
        }
      }
      .banner_form {
        background-color: #ffffff;
        min-width: 96%;
        margin: auto;
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
        border: 1px solid #707070;
        h4 {
          font-size: 23px;
          @media (max-width: 1280px) {
            font-size: 18px;
            margin-bottom: 15px;
          }
        }
        .formSection {
          form {
            display: block;
            .field-group {
              @media (max-width: 1280px) {
                margin-bottom: 7px;
              }
              input {
                @media (max-width: 1280px) {
                  max-height: 40px;
                }
              }
            }
          }
        }
      }
    }
    .meetingSchedulerSection {
      .schedulerList {
        min-height: 200px;
      }
    }
    .library_section {
      .illuflip_lib_sec {
        padding: 20px;
      }
    }
    .meetingSchedulerSection {
      display: block;
      .schedulerList {
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
    .magzine_carousel {
      display: block;
      .magzine_item {
        margin: 0px 15px 25px 15px;
        .magzineImage {
          @include tablet1 {
            height: 200px;
          }
          @media (min-width: 1400px) {
            height: 280px;
          }
          @media (min-width: 1900px) {
            height: 340px;
          }
        }
      }
    }
    .intro_vedioMainSection {
      padding: 20px;
      .vedioOverlyContent {
        display: block;
        .vedioDescription {
          margin-bottom: 15px;
          h2 {
            margin-bottom: 10px;
          }
        }
        .vedioPortion {
          #introVideo {
            min-height: 230px;
            @include phone {
              min-height: auto;
            }
          }
        }
      }
    }
    .illuflip_lib_sec {
      .illuflip_image {
        height: auto;
      }
    }
  }
}
.dynamicEventRegisHeading {
  top: auto !important;
  width: 100%;
  position: relative !important;
  left: auto !important;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  font-size: 23px;
  padding-top: 40px;
  color: #3f3e43;
  @media (min-width: 1450px) {
    font-size: 28px;
  }
}
.pageDesktopView {
  .registorEditiorSection {
    .banner_content {
      .banner_heading {
        flex: 0 0 70%;
      }
      .banner_form {
        flex: 0 0 25%;
      }
    }
    .intro_vedioMainSection {
      .vedioDescription {
        flex: 0 0 58%;
      }
      .vedioPortion {
        flex: 0 0 40%;
      }
    }
    // .magzine_carousel{
    //   .magzine_item{

    //   }
    // }
  }
}
.ViewGuestPrivewMain {
  .registorEditiorSection {
    .banner_content {
      .banner_heading {
        flex: 0 0 70%;
      }
      .banner_form {
        flex: 0 0 25%;
      }
    }
    .intro_vedioMainSection {
      .vedioDescription {
        flex: 0 0 58%;
      }
      .vedioPortion {
        flex: 0 0 40%;
      }
    }
  }
}

.demoRegistrationForm {
  .react-form-builder-form {
    margin-top: 40px !important;
    @include phone {
      width: 90%;
      padding: 20px 40px;
      margin: 0px auto;
    }
    .SortableItem {
      .form-group {
        @include phone {
          margin: 0px;
        }
      }
    }
  }
}
.editingPageWrapper {
  .demoEditDashboard {
    padding: 0px !important;
    .ViewGuestPrivewMain {
      padding-bottom: 90px !important;
    }
  }
}
.priviewTabMObileH {
  width: 100%;
  color: #333;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding-top: 20px;
  text-transform: capitalize;
}
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item
  .toolbar-header
  .toolbar-header-buttons {
  display: flex !important;
}
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item
  .toolbar-header
  .toolbar-header-buttons
  .btn {
  padding: 0px;
}
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item
  .toolbar-header
  .toolbar-header-buttons
  .btn:first-child {
  margin-right: 10px;
}
.react-form-builder-preview {
  .edit-form {
    .clearfix {
      display: none;
    }
  }
}
.welcomeEventName {
  color: #333;
  font-size: 20px;
  margin-right: 10px;
}
