.noEventCreated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
  padding-top: 70px;
  .noEventInner {
    width: 60%;
    text-align: center;
    z-index: 999;
    @include phone {
      width: 100%;
    }
    img {
      width: 180px;
      @include phone {
        width: 160px;
      }
      @include large {
        width: 250px;
      }
    }
    .noEventMessage {
      h3 {
        font-size: 25px;
        color: #02000a;
        font-weight: 400;
        margin: 35px 0 10px;
        @include phone {
          font-size: 17px;
        }
        @include large {
          font-size: 30px;
          margin: 35px 0 20px;
        }
      }
      p {
        font-size: 15px;
        color: #908e9a;
        margin-bottom: 30px;
        @include phone {
          font-size: 16px;
        }
        @include large {
          font-size: 16px;
        }
      }
      .outlineButton {
        box-sizing: border-box;
        padding: 20px 30px !important;
        text-transform: uppercase;
        min-height: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px !important;
        max-width: 280px;
        @include desktop {
          margin: auto;
          font-size: 19px;
          font-weight: 500;
        }
        @include large {
          padding: 20px 50px !important;
          min-height: 74px !important;
          font-size: 19px;
          max-width: 320px;
        }
      }
    }
  }
  .noEventBgIcon {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    span {
      position: absolute;
    }
    .shape-1 {
      left: 30px;
    }
    .shape-2 {
      right: 20px;
      bottom: 0;
    }
    .shape-3 {
      right: 70px;
      top: 50%;
      transform: translate(0%, -50%);
    }
    .shape-4 {
      bottom: -13%;
      left: 13%;
    }
    .shape-5 {
      right: 110px;
    }
  }
}

// Event Domain
.EventDomainFooter {
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.eventDemoLogin {
  .ViewGuestPrivewMain {
    .formBanner {
      .banner_content {
        .banner_heading {
          flex: 0 0 60%;
          h2 {
            width: 100%;
          }
        }
        .banner_form {
          flex: 0 0 30%;
        }
      }
    }
  }
}
.userPicture {
  position: relative;
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
  }
}
.dashboardProfileDropdown {
  display: none;
  background-color: #fff;
  box-shadow: 0px 0px 4px #dcdada;
  border-radius: 3px;
  position: absolute;
  min-width: 170px;
  right: 0px;
  z-index: 9;
  top: 100%;
  ul {
    padding: 6px 0px;
    li {
      list-style-type: none;
      text-align: left;
      a {
        display: block;
        padding: 3px 10px;
        color: #333 !important;
        cursor: pointer;
        &:hover {
          color: #281374 !important;
        }
      }
    }
  }
}
.demoEditDashboard {
  .brandProfileInfo {
    padding-top: 0px !important;
    .userPicture {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
.dropDownEventProfile:hover .dashboardProfileDropdown {
  display: block;
}
.dashboardProfileModal {
  .editProfieImg {
    padding: 30px 0px;
    .editProfieInner {
      margin-bottom: 0px;
    }
  }
}
.profilePicUPdateDB {
  display: flex;
}

// Agenda Page css
.myEventTable {
  .eventAgendaTable {
    table {
      thead {
        tr {
          th {
            &:last-child {
              justify-content: flex-end;
            }
            &:nth-child(3) {
              max-width: 280px !important;
              min-width: 280px !important;
            }
            &:last-child {
              max-width: 100% !important;
              justify-content: flex-end;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(3) {
              max-width: 280px !important;
              min-width: 280px !important;
            }
            &:last-child {
              max-width: 100% !important;
              justify-content: flex-end;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
.FullWidthScheduled {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100% !important;
  justify-content: space-between;
  label {
    flex: 0 0 100%;
  }
  .react-datepicker-wrapper {
    flex: 0 0 65%;
    @include phone {
      flex: 0 0 50%;
    }
  }
  .react-time-picker {
    flex: 0 0 30%;
    box-shadow: 0px 0px 4px #dedede;
    border: 1px solid transparent;
  }
}
.agendaDscription {
  textarea {
    min-height: 200px !important;
    font-weight: 400 !important;
  }
}
.formSection {
  .agendaSchedule {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        left: -5px !important;
      }
    }
  }
}

.no-data-found {
  margin: auto;
  width: 30%;
  padding: 1rem;
}
