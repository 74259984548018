.primery-color-bg {
  background-color: #5141e7;
}

.primery-hover-bg {
  background-color: #4737db;
}

.primery-selected-bg {
  background-color: #4737db;
}

.link {
  color: #5141e7 !important;
  cursor: pointer;
  word-break: break-word;
}

.ai-fetched-data {
  display: flex;
  font-size: 16px;
  color: #f26135;
  align-items: center;
  margin-top: 8px;

  img {
    margin-right: 6px;
  }
}

// .MuiTypography-body1 {
//     font-size: $fs-14 !important;
// }

// .MuiTableCell-root {
//     font-size: $fs-14 !important;
//     padding: 4px 0px !important;
// }

* {
  box-sizing: border-box;
}

.container-with-sidebar {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 10px;

  @media #{$medium-and-up} {
    padding: 20px 20px;
  }

  @media #{$large-and-up} {
    padding: 20px 20px;
  }

  @media #{$extra-large-and-up} {
    padding: 20px 24px;
  }

  @media #{$xx-large-and-up} {
    padding: 20px 40px;
  }

  .react-tabs {
    display: flex;
    flex-direction: column;

    @media #{$extra-large-and-up} {
      justify-content: space-between;
      flex-direction: row;
    }

    .project-sidebar {
      background: #ffffff;
      margin-bottom: 15px;
      margin-top: 15px;

      @media #{$extra-large-and-up} {
        width: 180px;
      }

      @media #{$xx-large-and-up} {
        width: 210px;
      }

      h1 {
        margin: 6px 0 6px 8px;
      }

      ul {
        border: none;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;

        @media #{$extra-large-and-up} {
          flex-direction: column;
        }

        li {
          display: flex;
          justify-content: left;
          @include border-radius($radius-btn-standard);
          padding: 0;
          cursor: pointer;
          border: none;
          margin-right: 8px;
          margin-bottom: 8px;

          a {
            padding: 10px 10px;
            width: 100%;
          }

          @media #{$extra-large-and-up} {
            margin-right: 0px;
          }

          img {
            margin-left: 8px;
          }

          &:hover {
            background: #edecf5;
          }

          &.active {
            background: #edecf5;
          }

          &:focus {
            box-shadow: none;
          }

          &.react-tabs__tab--selected {
            background: #edecf5;
          }
        }
      }
    }

    .container-sidebar-right {
      display: inline-block;

      @media #{$extra-large-and-up} {
        width: calc(100% - 190px);
      }

      @media #{$xx-large-and-up} {
        width: calc(100% - 234px);
      }

      .innerpage-common-heading {
        @include flexbox;
        justify-content: space-between;
        border-bottom: 1px solid #edecf5;
        padding-bottom: 24px;
        margin-top: 14px;
        @include align-items(center);

        h2 {
          margin: 0px;
        }
      }

      .myproject-slider-outer {
        padding-top: 16px;
        padding-bottom: 16px;
        @include flexbox;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #ffffff;
        width: max-content;

        .slider-card {
          margin: 0px 15px 15px 0px;
          width: 216px;
          min-width: 216px;
          background: #f5f4ff;
          box-shadow: none;
          border: 1px solid #f5f4ff;
          border-radius: 8px;
          overflow: visible;

          &.slider-card-left-heading {
            background: #faf9fc;
            display: flex;
            align-items: center;
            padding-left: 16px;
            margin-left: 0px;
            border: none !important;
            font-weight: 500;
            font-size: 14px;
            color: #000056;
            position: sticky;
            left: 0;
            z-index: 3;

            &::after {
              content: '';
              width: 15px;
              background-color: #ffffff;
              height: 100%;
              position: absolute;
              right: -15px;
            }
          }

          .MuiCardContent-root {
            padding: 8px 10px 16px 14px;
          }
        }

        .card-top-heading {
          @include flexbox;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0px;
            font-size: 14px;
            color: #5141e7;
          }
        }

        .card-middle-heading {
          h5 {
            font-weight: 500;
            font-size: 14px;
            color: #000056;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .card-end-heading {
          @include flexbox;
          @include align-items(center);

          p {
            margin: 0px;
            margin-right: 8px;
            font-size: 12px;
            text-transform: uppercase;
            color: #726f8e;
          }

          .level-count {
            background: #000;
            height: 9px;
            width: 4px;
            margin-right: 2px;
          }
        }
      }

      .myproject-accordion-outer {
        .MuiAccordion-root {
          margin-bottom: 10px;
          width: fit-content;
        }

        .custom-accordion-summary {
          background-color: #edecf5;
          height: 48px;
          min-height: 48px;

          .Mui-expanded {
            margin: 0px;
          }

          p {
            @include flexbox;
            @include align-items(center);
            color: #726f8e;
            position: sticky;
            left: 16px;
            text-transform: capitalize;

            img {
              margin-right: 8px;
            }
          }
        }

        .custom-accordion-details {
          padding: 0px;

          table {
            tr {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #edecf5;

              &:last-child td {
                border-bottom: none;
              }
            }
          }
        }

        .MuiTableContainer-root.roles-table {
          overflow: unset;
        }

        .MuiTableCell-body {
          border-bottom: none;
          padding: 3px 7px !important;
          width: 231px;
          min-width: 231px;
          max-width: 231px;
          font-size: 14px;
          line-height: 22px;
          color: #000056;
        }
      }
    }
  }
}

.dashboard-containor {
  padding: 10px 0px 0;
}

.box-shadow-none {
  box-shadow: none !important;
}

.drawer-right {
  .MuiDrawer-paper {
    width: 480px;
    max-width: 100%;
  }
}

.drawer-right {
  .myproject-accordion-outer {
    padding: 30px 40px;

    .drawer-heading {
      @include flexbox;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      img {
        cursor: pointer;
      }

      h2 {
        font-size: 24px;
        color: #000056;
      }
    }

    .drawer-input {
      .MuiFormControl-root {
        width: 100%;
      }

      label {
        span {
          color: #ff0404;
        }
      }
    }

    .drawer-accordion {
      margin-top: 30px;
      margin-bottom: 22px;

      .custom-accordion-summary {
        background-color: #faf9fc;

        p {
          img {
            margin-right: 12px;
          }
        }
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 47px;
        height: 47px;
      }

      .drawer-checkbox-width {
        width: 50px;
      }
    }

    .drawer-footer {
      display: flex;
      flex-direction: column;

      button {
        margin: 8px 0px;
      }
    }
  }
}

.drawer-accordion {
  margin-top: 30px;
  margin-bottom: 22px;

  .custom-accordion-summary {
    background-color: #faf9fc;

    p {
      img {
        margin-right: 12px;
      }
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 47px;
    height: 47px;
  }

  .drawer-checkbox-width {
    width: 50px;
  }
}

.badge-custom {
  background-color: #5141e7;
  color: #fff;
  font-size: $fs-14;
  display: inline-block;
  vertical-align: middle;
  padding: 4px 8px;
  border-radius: 20px;
  line-height: 15px;
}

.inputField {
  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    color: #000056;
  }

  .form-control {
    &.small {
      min-height: 48px;
      height: unset;
    }

    &.text-field {
      > div {
        min-height: 48px;
      }
    }
  }
}

.unlayer-form {
  width: 100%;
  max-width: 832px;
  background: #fafafa;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 20px;
  padding: 42px;

  Button {
    min-height: 49px;
  }
}

.user-search {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  margin-right: 12px;

  .MuiInputLabel-formControl {
    top: -6px;
  }

  .MuiTextField-root {
    width: 100%;

    .MuiInput-formControl {
      margin-top: 10px;
    }
  }
}

.status-table {
  text-transform: uppercase;
  padding: 1px 8px;
  display: inline-block;
  border-radius: 4px;

  &.active {
    background-color: $bg-green;
    color: #fff;
  }
}

.first-letter {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 8px;
  color: #fff;

  &.text-color {
    background: #a358df;
  }
}

.popup-outer-flex {
  @include flexbox;
  @include align-items(center);
  justify-content: center;
  width: 100%;
  height: 100%;

  .popup-outer {
    background: #fff;
    background: #fff;
    padding: 50px 40px;
    border-radius: 12px;
    position: relative;

    .popup-header {
      margin-bottom: 35px;

      h2 {
        margin-bottom: 5px;
      }
    }

    .popup-body {
      margin-bottom: 35px;

      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
}

.project-main {
  @include flexbox;
  justify-content: space-between;
  align-items: center;
}

.project-main-tabs {
  width: 100%;

  ul {
    border: none;
    margin: 0px;

    li {
      width: auto;
      padding: 0px;
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      background-color: #edecf5;
      border-radius: 4px;
      padding: 2px 8px;
      border: none;

      &:hover,
      &.active {
        background-color: #000056;
        color: #fff;
      }
    }
  }
}

.dashboardMainPage .sidebarCompo {
  overflow: auto;
  background-color: #281374;
  z-index: 1;
}

.project-block-listing {
  display: inline-block;
  width: 100%;

  .project-block {
    display: inline-block;
    width: 100%;
    height: 100%;
    // max-width: 541px;
    box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.18);
    border-radius: 4px;

    &:hover {
      box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
    }

    .live-outer {
      @include flexbox;
      justify-content: space-between;
      margin-bottom: 18px;

      .live-outer-left {
        @include flexbox;
        align-items: center;

        span {
          border-radius: 4px;
          color: #fff;
          text-transform: uppercase;
          padding: 5px 9px;
          position: relative;
          margin-right: 8px;

          &::after {
            position: absolute;
            content: '';
            bottom: -7px;
            left: 0px;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }

          &.project-hybrid {
            background-color: #ff4170;

            &::after {
              border-left: 5px solid #ff4170;

              border-top: 5px solid #ff4170;
            }
          }

          &.project-in-person {
            background-color: #5141e7;

            &::after {
              border-left: 5px solid #5141e7;

              border-top: 5px solid #5141e7;
            }
          }

          &.project-sale {
            background-color: #0addb4;

            &::after {
              border-left: 5px solid #0addb4;

              border-top: 5px solid #0addb4;
            }
          }

          &.project-registration {
            background-color: #7d35c1;

            &::after {
              border-left: 5px solid #7d35c1;

              border-top: 5px solid #7d35c1;
            }
          }

          &.project-email {
            background-color: #16d1fc;

            &::after {
              border-left: 5px solid #16d1fc;

              border-top: 5px solid #16d1fc;
            }
          }

          &.project-virtual {
            background-color: #f26135;

            &::after {
              border-left: 5px solid #f26135;

              border-top: 5px solid #f26135;
            }
          }
        }

        p {
          font-size: 14px;
        }
      }

      .live-outer-right {
        svg {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }
    }

    .live-heading {
      margin-bottom: 18px;
      cursor: pointer;
    }

    .live-link {
      position: relative;
      padding-left: 35px;
      margin-bottom: 8px;

      img {
        position: absolute;
        left: 0px;
        top: 0px;
      }

      p,
      span {
        font-size: 14px;
      }

      a {
        text-decoration: underline !important;
      }
    }

    .live-location {
      @include flexbox;
      align-items: center;
      padding-left: 35px;

      svg {
        width: 10px;
        height: 10px;
        color: #a4a1bd;
        margin: 0px 8px;
      }

      span {
        min-width: 70px;
        font-size: 14px;
      }

      .location {
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.live-records {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #edecf5;
  border-bottom: 1px solid #edecf5;
  padding: 0px 5px;

  ul {
    @media #{$medium-and-up} {
      @include flexbox;
      padding: 16px 0px;
    }

    padding: 0px;

    li {
      border-bottom: 1px solid #edecf5;

      @media #{$medium-and-up} {
        border-right: 1px solid #edecf5;
        border-bottom: none;
        padding: 0px 20px;
      }

      align-items: baseline;
      padding: 10px 20px;
      padding-right: 10px;
      min-width: 25%;

      .MuiListItemText-root {
        margin: 0px;
      }

      &:last-child {
        border-right: none;
        border-bottom: none;
      }

      p {
        color: $primary-text;
        line-height: 15px;
        margin-top: 5px;
        font-size: 14px;
      }

      @media #{$small-and-down} {
        span {
          display: flex;

          h2 {
            min-width: 50px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.live-padding {
  padding: 25px;
}

.live-tags {
  padding: 25px;

  @media #{$small-and-down} {
    padding: 10px;
  }

  @media #{$medium-and-up} {
    padding: 15px;
  }

  .MuiList-padding {
    padding: 0px;
  }
}

.language-options {
  .language-selector {
    border-radius: 8px;
    background: #5b33f1;
    padding: 8px;
    max-height: 250px;
    overflow-y: auto;

    ul {
      list-style: none;
      min-width: 172px;
      margin: 0;

      li {
        border-radius: 6px;
        padding: 6px 10px;
        margin: 2px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #ffffff;
        cursor: pointer;
        position: relative;

        &:hover,
        &.selected {
          background: #281374;
          color: #efeb53;
        }

        &.selected {
          &::before {
            content: '';
            background-image: url('../../images/icons/Check-language.svg');
            height: 16px;
            width: 16px;
            display: inline-block;
            position: absolute;
            right: 8px;
            top: calc(50% - 8px);
          }
        }
      }
    }
  }
}

.navigationMenu {
  ul {
    li {
      .nav-link {
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .sidebar-icon {
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .sidebar-projects {
        background-image: url(../../images/sidebar-icons/project-off.svg);
      }

      .sidebar-reports {
        background-image: url(../../images/sidebar-icons/reports-off.svg);
      }

      .sidebar-audience {
        background-image: url(../../images/sidebar-icons/audience-off.svg);
      }

      .sidebar-setings {
        background-image: url(../../images/sidebar-icons/settings-off.svg);
      }

      .sidebar-ai-chat {
        background-image: url(../../images/sidebar-icons/ai-chat-icon-off.svg);
      }

      .sidebar-files {
        background-image: url(../../images/sidebar-icons/files-off.svg);
      }

      .sidebar-opportunity {
        background-image: url(../../images/sidebar-icons/opportunity.svg);
      }

      .sidebar-emails {
        background-image: url(../../images/sidebar-icons/email_icon-off.svg);
      }

      .choose-language {
        height: 38px;
        width: 38px;
        background-repeat: no-repeat;
        background-position: -11px 0px;
        background-image: url(../../images/sidebar-icons/language-0.svg);
      }

      &.active {
        .sidebar-projects {
          background-image: url(../../images/sidebar-icons/project-on.svg);
        }

        .sidebar-reports {
          background-image: url(../../images/sidebar-icons/reports-on.svg);
        }

        .sidebar-audience {
          background-image: url(../../images/sidebar-icons/audience-on.svg);
        }

        .sidebar-setings {
          background-image: url(../../images/sidebar-icons/setting-on.svg);
        }

        .sidebar-ai-chat {
          background-image: url(../../images/sidebar-icons/ai-chat-icon-on.svg);
        }

        .sidebar-files {
          background-image: url(../../images/sidebar-icons/files-on.svg);
        }

        .sidebar-opportunity {
          background-image: url(../../images/sidebar-icons/opportunity.svg);
        }

        .sidebar-emails {
          background-image: url(../../images/sidebar-icons/email_icon-on.svg);
        }
      }

      .upgrade {
        color: #000056;
        background: #efea72;
        border: 1px solid #000056;
        font-size: 12px;
        padding: 1px 6px 5px;
        border-radius: 25px;
        position: absolute;
        right: -5px;
        top: -5px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 0.5px;
        cursor: pointer;
      }

      &:hover,
      &.active {
        background-color: #5b33f1;
        border-radius: 8px;
      }
    }
  }
}

.grid-view {
  background: url(../../images/icons/grid-view-off.svg);
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;

  &:hover {
    background: url(../../images/icons/grid-view-on.svg) #000056;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.list-view {
  background: url(../../images/icons/List-view-off.svg);
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;

  &:hover {
    background: url(../../images/icons/List-view-on.svg) #000056;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.projects-search-grid {
  @include flexbox;

  .grid-list-view {
    @include flexbox;
    align-items: center;
    margin-left: 30px;
  }
}

.pagination-outer {
  display: inline-block;
  width: 100%;

  ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    width: 100%;

    li {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      width: auto;
      margin: 5px 2px;

      a {
        display: inline-block;
        background-color: #edecf5;
        padding: 6px 14px;
        border-radius: 4px;
        color: $primary-text;

        &:hover {
          background-color: $primary-text;
          color: #fff;
          text-decoration: none;
        }
      }

      .MuiListItemText-root {
        margin: 0px;
      }
    }
  }
}

.step1-outer {
  .step1-block {
    display: inline-block;
    width: 100%;

    .project-type-wrapper {
      .project-type {
        padding-right: 0px;
        padding-bottom: 0px;

        .MuiListItemText-root {
          border: 1px solid #f1f0fd;
        }

        .MuiListItemText-root:hover {
          box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23%);
        }

        .icon {
          height: 42px;
          width: 42px;
          border-radius: 4px;

          &.icon1 {
            background: url(../../images/icons/step1-icon1.svg) #ff4170;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.icon2 {
            background: url(../../images/icons/step1-icon2.svg) #5141e7;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.icon3 {
            background: url(../../images/icons/step1-icon3.svg) #0addb4;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.icon4 {
            background: url(../../images/icons/step1-icon4.svg) #7d35c1;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.icon5 {
            background: url(../../images/icons/step1-icon5.svg) #16d1fc;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.icon6 {
            background: url(../../images/icons/step1-icon6.svg) #f26135;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        h3 {
          display: inline-block;
          width: 100%;
          margin-top: 24px;
          margin-bottom: 10px;
        }

        p {
          display: inline-block;
          width: 100%;
        }

        .project-wrapper {
          margin: 0px;
          border-radius: 4px;
          border: 1px solid transparent;
          height: 100%;
        }

        .color-1 {
          background-color: #fff0f4;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #ff4170;
          }
        }

        .color-2 {
          background-color: #f1f0fd;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #5141e7;
          }
        }

        .color-3 {
          background-color: #ebfcf9;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #0addb4;
          }
        }

        .color-4 {
          background-color: #f5effa;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #7d35c1;
          }
        }

        .color-5 {
          background-color: #ecfbff;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #16d1fc;
          }
        }

        .color-6 {
          background-color: #fef7ef;

          &:hover,
          &.active {
            background-color: #fff;
            border: 1px solid #f26135;
          }
        }
      }
    }
  }
}

.step1-outer .step1-block ul li.active {
  .color-1 {
    background-color: #fff;
    border: 1px solid #ff4170;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-outer .step1-block ul li.active {
  .color-2 {
    background-color: #fff;
    border: 1px solid #5141e7;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-outer .step1-block ul li.active {
  .color-3 {
    background-color: #fff;
    border: 1px solid #0addb4;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-outer .step1-block ul li.active {
  .color-4 {
    background-color: #fff;
    border: 1px solid #7d35c1;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-outer .step1-block ul li.active {
  .color-5 {
    background-color: #fff;
    border: 1px solid #16d1fc;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-outer .step1-block ul li.active {
  .color-6 {
    background-color: #fff;
    border: 1px solid #f26135;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.23);
  }
}

.step1-heading {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 48px;
  width: 100%;
  display: inline-block;
}

.step2-heading-block {
  border: 1px solid transparent;
  border-radius: 16px;
  padding: 22px;
  text-align: center;
  padding-bottom: 30px;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.18);
  cursor: pointer;

  h5 {
    padding: 18px 0px;
  }

  &.active {
    border: 1px solid #5b33f1;
  }
}

// .tamplate-wrapper {
//   border: 1px solid transparent;
//   box-shadow: 0px 0px 23px rgba(0, 0, 86, 0.09) !important;
// }

.useTempActive {
  border: 1px solid #5b33f1;
  border-radius: 16px;
  padding: 0px;
  text-align: left;
  padding-bottom: 0px;

  h5 {
    padding: 18px 0px;
  }
}

.step2-search-block {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 40px;
  align-items: center;

  .step2-search-select {
    .MuiSelect-select {
      background: #edecf5;
      width: 122px;
      border-radius: 4px;
      height: 32px;
      padding: 0px;
      padding-left: 10px;
      min-height: 35px;
    }

    .MuiInput-underline:after {
      display: none;
    }

    .MuiInput-underline:before {
      display: none;
    }
  }
}

.inputField {
  .tag-item {
    background: $tags-color;
    padding: 5px 10px;
    margin: 15px 10px 0 0;
    color: #fff;
    border-radius: 5px;
    display: inline-block !important;

    .button {
      border: 0;
      color: #fff;
      background: transparent;
      font-size: 22px;
      line-height: 20px;
      vertical-align: middle;
      margin-left: 10px;
      padding: 0;
      cursor: pointer;
    }
  }
}

.step3-outer {
  width: 100%;
  max-width: 840px;
  margin: auto;

  .CRCircularBox {
    border: none;
    padding: 0px !important;

    .layout__possition {
      > * {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #d3d4da;
        border-radius: 4px;
        height: 48px;
        width: 56px;
        margin-right: 16px;
        cursor: pointer;
        position: relative;

        &:hover,
        &:active,
        &:focus,
        &.active {
          border: 2px solid #5141e7;

          &::before {
            background: #5141e7;
          }
        }

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          background: #726f8e;
          position: absolute;
          border-radius: 12px;
        }
      }

      &_center {
        background: rgba(19, 15, 38, 0.18);

        &::before {
          background: #fff;
          width: 32px;
          height: 20px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
          border: 2px solid #5141e7;

          &::before {
            background: #fff;
          }
        }
      }

      &_bottom-left {
        &::before {
          bottom: 6px;
          left: 6px;
        }

        &:hover,
        &.active,
        &:focus {
          &::before {
            bottom: 5px;
            left: 5px;
          }
        }
      }

      &_bottom-right {
        &::before {
          bottom: 6px;
          right: 6px;
        }

        &:hover,
        &.active,
        &:focus {
          &::before {
            bottom: 5px;
            right: 5px;
          }
        }
      }

      &_top-left {
        &::before {
          top: 6px;
          left: 6px;
        }

        &:hover,
        &.active,
        &:focus {
          &::before {
            top: 5px;
            left: 5px;
          }
        }
      }

      &_top-right {
        &::before {
          top: 6px;
          right: 6px;
        }

        &:hover,
        &.active,
        &:focus {
          &::before {
            top: 5px;
            right: 5px;
          }
        }
      }
    }
  }

  .rsvpEventfieldBox .completeRegisterboxes .eventFullInput .field-group {
    flex: 0 0 48%;

    .formDate {
      & > div {
        width: 100%;
      }

      .MuiOutlinedInput-root {
        border: 1px solid rgba(0, 0, 0, 0.23);
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }

      input {
        background: #ffffff;
        box-shadow: none;

        &:focus {
          border: none !important;
        }
      }
    }
  }

  .rsvpEventfieldBox
    .completeRegisterboxes
    .eventFullInput
    .field-group.width-100 {
    flex: 0 0 100%;
  }

  .rsvpEventfieldBox .completeRegisterboxes .eventFullInput .field_group75Both {
    flex: 0 0 100%;
  }
}

.step2-block {
  ul {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 0px;
      width: 32%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      margin-right: 2%;
      margin-bottom: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .MuiListItemText-root {
        margin: 0px;
      }

      .img-outer {
        img {
          width: 100%;
        }
      }

      .step2-content-outer {
        padding: 24px;

        p {
          color: #726f8e;
        }
      }

      .step2-hash-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        margin-bottom: 12px;

        .tags {
          margin-right: 8px;
        }
      }

      .step2-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        button {
          width: 48%;
          font-size: 13px !important;

          &.step2-use {
            color: #fff;
          }
        }
      }
    }
  }
}

.step3-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .step3-input {
    width: 49%;

    &.full-width {
      width: 100%;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    label {
      span {
        color: #ff0404;
      }
    }
  }
}

.select-custom {
  .MuiInputBase-input {
    height: 1.1876em;
    min-height: 38px;
    border: 1px solid #ccced5;
    border-radius: 4px;
    padding-left: 12px;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInput-underline:after {
    display: none;
  }

  .form-control {
    padding: 0px;
  }

  .MuiInputBase-root {
    width: 100%;

    svg {
      display: none;
    }
  }
}

.steper-outer {
  position: relative;

  .next-back-stepper-btns,
  .next-back-stepper-btns-one {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    background: #fff;
    right: 0px;
    padding: 14px 64px 55px;
    margin: 0;
    width: 100%;

    @media #{$small-and-down} {
      padding: 0px 16px 160px;
    }

    @media #{$medium-and-up} {
      padding: 0px 16px 90px;
    }

    @media #{$extra-large-and-up} {
      padding: 14px 64px 55px;
    }

    .btn-hidden {
      visibility: hidden;
    }
  }

  .next-back-stepper-btns-one {
    padding-bottom: 34px;
  }

  .MuiStepper-horizontal {
    width: 550px;
    background: transparent;
    position: absolute;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    // top: 10px;
    z-index: 2;
    padding: 0;

    @media #{$small-and-down} {
      width: 320px;
      top: 100px;
    }

    @media #{$medium-and-up} {
      top: 55px;
    }

    @media #{$extra-large-and-up} {
      top: 10px;
    }

    .MuiStepLabel-iconContainer {
      svg {
        width: 42px;
        height: 42px;
      }
    }
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    color: #000056;
  }
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #000056 !important;
}

.ReactModalPortal {
  position: relative;
  z-index: 2;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 86, 0.3) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 86, 0.3);
}

// team_add-remove-people
.react-responsive-modal-modal {
  overflow: unset;

  &.modal-default-close-none {
    .react-responsive-modal-closeButton {
      display: none;
    }
  }

  &.team_add-remove-people_modal,
  &.Invite-User-Modal {
    padding: 20px 0px !important;
    border-radius: 16px;
    max-width: 630px;

    @media #{$large-and-up} {
      padding: 44px 20px 35px !important;
    }

    .react-responsive-modal-closeButton {
      top: 18px !important;
      right: 18px;

      svg {
        fill: #1d1929;
      }
    }

    .modal-wrapper {
      padding: 0px 20px;
      max-height: 80vh;
      overflow: auto;

      .modal-header {
        border-bottom: 0;
        padding: 0;
        flex-direction: column;
        margin-bottom: 24px;

        h1 {
          font-weight: 300;
          font-size: 24px;
        }

        p {
          margin-top: 5px;
          color: #726f8e;
        }
      }

      .modal-body {
        padding: 0;

        .people-list {
          max-height: 240px;
          overflow-y: scroll;
          margin: 32px 0;

          table {
            tr {
              td {
                padding: 16px 16px 16px 0;
                border-top: 0;
                border-bottom: 1px solid #dee2e6;

                .person-details {
                  .person-email,
                  .person-role {
                    color: #726f8e;
                    font-size: 13px;
                  }

                  .person-role {
                    position: relative;
                    padding-left: 20px;

                    &::before {
                      content: '';
                      position: absolute;
                      background: #726f8e;
                      width: 6px;
                      height: 6px;
                      z-index: 0;
                      left: 6px;
                      top: 5px;
                      border-radius: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .MuiSelect-select {
        // padding: 12px 14px !important;

        &:focus {
          outline: 0;
          border: none;
          box-shadow: none;
        }
      }
    }

    .invite-user {
      background: #edecf5;
      padding: 16px;

      p {
        color: #000000;
      }
    }
  }

  &.Invite-User-Modal {
    max-width: 550px;
    padding: 20px 0px !important;

    @media #{$large-and-up} {
      padding: 44px 20px 35px !important;
    }

    &.modal-default-close-none {
      .react-responsive-modal-closeButton {
        display: none;
      }
    }

    &.wide {
      max-width: 700px;
    }

    &.wider {
      width: 90%;
      max-width: 900px;
    }

    &.send-notification-popup {
      .modal-header {
        img {
          margin-bottom: 25px;
        }

        h1 {
          font-weight: 400;
          margin-bottom: 15px;
        }

        p {
          font-size: 16px;
          color: #000056;
          font-weight: 400;
        }
      }

      .field-group .formDate .MuiOutlinedInput-root {
        border: 1px solid rgba(0, 0, 0, 0.23);
      }

      .formDate .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    &.change-profile-image {
      max-width: 844px;

      .modal-wrapper {
        padding: 0px 20px;
      }

      canvas {
        border-radius: 100px;
        width: 199px !important;
        height: 199px !important;
        border: 1px solid #edecf5 !important;
      }
    }
  }

  &.tab-modal {
    max-width: 1200px;

    @media #{$large-and-up} {
      width: 60%;
    }

    .react-responsive-modal-closeButton {
      top: 20px !important;
    }

    .MuiTabs-indicator {
      height: 3px;
      background: #ffffff;
    }
  }

  &.file-modal {
    @media #{$large-and-up} {
      width: 100%;
    }
  }
}

// team_add-remove-people end

// full-screen modal

.react-responsive-modal-modal {
  &.full-screen_modal {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 0 !important;
    display: flex;
    background-color: transparent;
    // background: rgba(0, 0, 0, 0.8);
  }
}

.full-screen_modal {
  &__form {
    position: relative;

    &_footer {
      padding: 14px 24px;
      position: absolute;
      bottom: 0;
      background-color: #ffffff;
      width: 100%;
    }
  }
}

.add-notification_modal,
.full-screen_modal {
  &__preview {
    background: #f6f6fc;

    .notification-wraper {
      position: relative;
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.18);
    }
  }

  &__form {
    background-color: #fff;

    &_header {
      padding: 14px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      h3 {
        font-weight: 500;
      }
    }

    &_body {
      max-height: calc(100vh - 70px);
      overflow-y: scroll;
      height: 100%;

      /* Hide scrollbar for IE, Edge and Firefox */

      // &::-webkit-scrollbar {
      //     display: none;
      // }

      // -ms-overflow-style: none;
      // /* IE and Edge */
      // scrollbar-width: none;
      // /* Firefox */
    }
  }

  &.modal-default-close-none {
    .react-responsive-modal-closeButton {
      display: none;
    }

    .modal-close-button {
      background: #edecf5;
      color: #000056;
      width: 37px;
      height: 37px;
      border-radius: 4px;
      padding: 9px;
      cursor: pointer;
    }
  }

  .react-responsive-modal-closeButton {
    background: #edecf5;
    border-radius: 4px;
    padding: 8px 10px;
    right: 24px;

    svg {
      fill: #000056;
      width: 16px;
      height: 16px;
    }
  }
}

// full-screen modal End

// manage-custom-fields

//.manage-custom-fields {
.custom-fields-table {
  table {
    // tr {
    //     &:nth-child(even) {
    //         .tableBodyCell {
    //             background-color: transparent;
    //         }
    //     }
    // }

    .tableHeadCell,
    .tableBodyCell {
      &.checkbox {
        padding: 0;
        width: 42px;
        min-width: 42px;
        max-width: 42px;
      }

      &.email {
        padding-left: 0;
      }
    }

    .tableHeadCell {
      border-bottom: 0;
      font-size: 1rem;
      font-weight: 500;
      color: $heading-color;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #ffffff;

      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &:last-child {
        width: 120px;
        max-width: 120px;
      }
    }

    .tableBodyCell {
      border-bottom: 5px solid #fff;
      background: #fafafa;
      padding: 8px 16px;

      .action-button {
        cursor: pointer;
      }

      .list-order {
        max-width: 50px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid #ccced5;
        background-color: #fff;
        box-shadow: none;
        min-height: 30px;
      }
    }

    .dragging {
      .tableBodyCell {
        border-bottom: none;
        width: inherit;
      }
    }
  }
}

//}

// manage-custom-fields end

//add-notification
.add-notification_overlay,
.full-screen_overlay {
  ~ .react-responsive-modal-container {
    height: auto;
  }
}

.react-responsive-modal-modal {
  &.add-notification_modal,
  &.full-screen_modal {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 0;
    display: flex;

    &.doc-preview {
      backdrop-filter: blur(3px);
      background: rgba(0, 0, 0, 0.18);
    }
  }
}

.page-notification {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.notification-wraper {
  position: relative;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.18);
}

.notification-popup {
  padding: 24px 16px;
  width: 398px;
  height: auto;
  max-height: calc(100vh - 400px);
  box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
  border-radius: 8px;
  position: absolute;
  border-top: 8px solid #ff4170;
  border-bottom: 8px solid #ff4170;
  background-color: #ffffff;

  h1 {
    font-weight: bold;
    font-size: 18px;
  }

  p {
    padding: 16px 0px 30px;
    font-size: 14px;
  }

  button {
    padding: 7px 17px;
    background-color: #ff4170;
    color: #fff;
    border: #ff4170;
    border-radius: 5px;
  }

  &.center {
    padding: 50px;
    width: 596px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 16px solid #ff4170;
    border-bottom: 16px solid #ff4170;

    h1 {
      font-weight: bold;
      font-size: 24px;
    }

    p {
      padding: 24px 0px 32px;
      font-size: 16px;
    }
  }

  &.bottom-left {
    bottom: 30px;
    left: 30px;
  }

  &.bottom-right {
    bottom: 30px;
    right: 30px;
  }

  &.top-left {
    top: 30px;
    left: 30px;
  }

  &.top-right {
    top: 30px;
    right: 30px;
  }
}

.add-notification_modal {
  &__preview {
    // width: 75%;
    background: url(../../images/skeleton-bg.svg) #f6f6fc center no-repeat;

    .notification-wraper {
      position: relative;
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.18);
    }

    .notification-popup {
      padding: 24px 16px;
      width: 398px;
      height: auto;
      max-height: calc(100vh - 400px);
      box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
      border-radius: 8px;
      position: absolute;
      border-top: 8px solid #ff4170;
      border-bottom: 8px solid #ff4170;
      background-color: #ffffff;

      h1 {
        font-weight: bold;
        font-size: 18px;
      }

      p {
        padding: 16px 0px 30px;
        font-size: 14px;
      }

      button {
        padding: 7px 17px;
        background-color: #ff4170;
        color: #fff;
        border: #ff4170;
        border-radius: 5px;
      }

      &.center {
        padding: 50px;
        width: 596px;
        max-height: 60vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-top: 16px solid #ff4170;
        border-bottom: 16px solid #ff4170;

        h1 {
          font-weight: bold;
          font-size: 24px;
        }

        p {
          padding: 24px 0px 32px;
          font-size: 16px;
        }
      }

      &.bottom-left {
        bottom: 30px;
        left: 30px;
      }

      &.bottom-right {
        bottom: 30px;
        right: 30px;
      }

      &.top-left {
        top: 30px;
        left: 30px;
      }

      &.top-right {
        top: 30px;
        right: 30px;
      }
    }
  }

  &__form {
    //  width: 25%;

    &_header {
      padding: 14px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      h3 {
        font-weight: 500;
      }
    }

    &_body {
      max-height: calc(100vh - 66px);
      overflow-y: scroll;

      // &::-webkit-scrollbar {
      //     display: none;
      // }

      // /* Hide scrollbar for IE, Edge and Firefox */

      // -ms-overflow-style: none;
      // /* IE and Edge */
      // scrollbar-width: none;
      // /* Firefox */

      label {
        font-size: 16px;
        line-height: 24px;
        color: #000056;
      }

      .form-control {
        min-height: 48px;
      }

      .color-swatch {
        position: relative;

        .form-control {
          padding-left: 50px;
        }
      }

      .layout__possition {
        > * {
          display: inline-block;
          background: #ffffff;
          border: 1px solid #d3d4da;
          border-radius: 4px;
          height: 48px;
          width: 56px;
          margin-right: 16px;
          cursor: pointer;
          position: relative;

          &:hover,
          &:active,
          &:focus,
          &.active {
            border: 2px solid #5141e7;

            &::before {
              background: #5141e7;
            }
          }

          &::before {
            content: '';
            width: 24px;
            height: 12px;
            background: #726f8e;
            position: absolute;
            border-radius: 2px;
          }
        }

        &_center {
          background: rgba(19, 15, 38, 0.18);

          &::before {
            background: #fff;
            width: 32px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:hover,
          &:active,
          &:focus,
          &.active {
            border: 2px solid #5141e7;

            &::before {
              background: #fff;
            }
          }
        }

        &_bottom-left {
          &::before {
            bottom: 6px;
            left: 6px;
          }

          &:hover,
          &.active,
          &:focus {
            &::before {
              bottom: 5px;
              left: 5px;
            }
          }
        }

        &_bottom-right {
          &::before {
            bottom: 6px;
            right: 6px;
          }

          &:hover,
          &.active,
          &:focus {
            &::before {
              bottom: 5px;
              right: 5px;
            }
          }
        }

        &_top-left {
          &::before {
            top: 6px;
            left: 6px;
          }

          &:hover,
          &.active,
          &:focus {
            &::before {
              top: 5px;
              left: 5px;
            }
          }
        }

        &_top-right {
          &::before {
            top: 6px;
            right: 6px;
          }

          &:hover,
          &.active,
          &:focus {
            &::before {
              top: 5px;
              right: 5px;
            }
          }
        }
      }
    }

    &_footer {
      padding: 14px 24px;
    }
  }

  .react-responsive-modal-closeButton {
    background: #edecf5;
    border-radius: 4px;
    padding: 8px 10px;
    right: 24px;

    svg {
      fill: #000056;
      width: 16px;
      height: 16px;
    }
  }

  .ql-toolbar.ql-snow {
    border: 0;
    background: #edecf5;
    padding: 12px 8px;
  }

  .ql-container.ql-snow {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 200px;
  }

  .ql-editor {
    padding: 24px 32px;
  }
}

.view-notification_modal {
  .layout__possition {
    > * {
      pointer-events: none;
    }
  }

  .quill {
    pointer-events: none;
  }

  .add-notification_modal__form_body {
    max-height: calc(100vh - 51px);
  }
}

.ql-container.ql-snow {
  min-height: 200px;
}

// add-notification end

iframe.page-preview {
  border: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

// manage-page--
.manage-page-wrapper {
  background: #f6f6fc;

  .page-card {
    //  min-width: 360px;
    width: 100%;
    height: auto;
    padding: 0;
    //   margin: 0 24px 24px 0;
    border-radius: 8px;
    background-color: #fff;
    float: left;

    &:hover {
      transition-duration: 0.3s;
      box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
    }

    &-img {
      width: 100%;
      height: 300px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;

      .page-preview {
        border: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
      }
    }

    &-body {
      padding: 24px;
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: #000056;
      margin-bottom: 5px;
      word-break: break-all;
    }

    &-url {
      display: flex;

      a {
        font-size: 14px;
        font-weight: 400px;
        color: #000056;
        text-decoration: underline !important;
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }

      span {
        img {
          margin-left: 12px;
          margin-top: -5px;
        }
      }
    }

    &-status {
      font-size: 14px;
      line-height: 22px;
      color: #83819c;
      margin: 10px 0 15px;
    }
  }

  .add-page {
    width: 100%;
    min-height: 529px;
    max-height: 529px;
    padding: 0;
    border-radius: 8px;
    border: 1px dashed #5141e7;
    color: #5141e7;
    margin-bottom: 24px;
    position: relative;

    .add-icon {
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      text-align: center;
      cursor: pointer;
      top: 50%;

      img {
        background: #5141e7;
        border-radius: 16px;
        padding: 20px;
        margin-bottom: 15px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.common-header {
  display: flex;

  h2 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 32px;
    color: #000056;
    margin-left: 25px;
    margin-bottom: 40px;
  }
}

.MuiButton-outlined {
  &.back-button {
    width: 54px;
    height: 42px;
    border: 1px solid #e8e7f4;
  }
}

.stepper-outer-common {
  .tamplate-wrapper {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 86, 0.18) !important;

    .thumnail-placeholder {
      background-image: url(../../images/icons/slider-thumnail-icon.png);
      background-position: center center;
      background-size: 100px;
      background-repeat: no-repeat;
      background-color: #ededed;
    }

    .tamplate-description {
      display: -webkit-box;
      //-webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.tamplate-wrapper {
  .tamplate {
    width: 379px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.18);
    border-radius: 4px;
    position: relative;

    @media #{$small-and-down} {
      width: 275px;
    }

    @media #{$medium-and-up} {
      width: 290px;
    }

    &-thum {
      width: 100%;
      height: 224px;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 16px;
      overflow: hidden;
      pointer-events: none;
      background-size: 100%;
    }

    &-action {
      width: calc(100% - 20px);
      height: 224px;
      opacity: 0;
      transition-duration: 200ms;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 10px;

      .delete {
        font-size: 10px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 2px 12px;
        border-radius: 2px;
        transition-duration: 100ms;

        &:hover {
          background: #ff5151;
        }
      }

      .white-btn {
        background-color: #ffffff;
        color: #5141e7;
        border: 1px solid #5141e7;
      }
    }

    &-name {
      p {
        font-size: 16px;
        font-weight: 500;
        color: #000056;
        text-align: center;
        margin-bottom: 16px;
      }
    }

    &:hover {
      .tamplate-action {
        opacity: 1;
      }
    }
  }
}

//manage-page-end--

// agenda
.detail-popup {
  position: relative;
  padding: 0px 0;
  max-height: 22px;

  .count {
    font-size: 10px;
    line-height: 22px;
    color: #ffffff;
    background-color: #000059;
    border-radius: 2px;
    padding: 2px 4px;
    position: relative;

    &::before {
      content: '';
      bottom: -10px;
      left: calc(50% - 7px);
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgb(255, 255, 255);
      z-index: 12px;
      display: none;
    }
  }

  .info {
    display: none;
    position: absolute;
    min-width: 120px;
    max-height: 175px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 28px rgba(0, 0, 89, 0.23);
    border-radius: 4px;
    padding: 8px;
    top: 30px;
    right: -25px;
    z-index: 2;

    div {
      width: 100%;
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #000056;
      cursor: pointer;
      display: flex;
      align-items: center;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 150px;
      }

      img {
        margin-right: 10px;
      }

      &:hover {
        background: #edecf5;
      }

      a {
        font-size: 14px;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    &:hover {
      display: block;
    }
  }

  .count {
    &:hover {
      & + .info {
        display: block;
      }
    }
  }

  &:hover {
    .info {
      display: block;
    }

    .count {
      &::before {
        display: block;
      }
    }
  }
}

.agenda-status {
  font-size: 11px;
  color: #ffffff;
  background-color: #9ea7ad;
  border-radius: 3px;
  padding: 2px 20px;
  min-width: fit-content;
}

// agenda end

// project-navigation

.project-navigation {
  .MuiTabs-indicator {
    height: 3px;
    background: #5b33f1;
  }

  // .MuiButtonBase-root {
  //     color: $blue-primary;

  //     &.Mui-selected {
  //         color: $blue-primary;
  //     }
  // }

  // .MuiBadge-badge {
  //   background-color: #5141E7;
  // }

  .MuiTabPanel-root {
    background: #f6f6fc;
    min-height: calc(100vh - 145px);
    max-height: calc(100vh - 145px);
    padding: 0;
    overflow: auto;

    @media #{$large-and-up} {
      padding: 24px;
    }
  }

  .disabled-tab {
    background-color: #e0e0e0;
  }
}

.Opportunity-Contacts .MuiTabPanel-root {
  // min-height: unset;
  // max-height: unset;
  min-height: calc(100vh - 220px);
  max-height: calc(100vh - 220px);
}

.upgrade-tooltip {
  color: #000056;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 86, 0.23);

  h5 {
    font-size: 12px;
  }

  p {
    font-size: 12px;

    a {
      color: #5141e7;
    }
  }

  & ~ .MuiTooltip-arrow,
  ~ span {
    color: #fff;

    &::before {
      box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.23);
    }
  }

  &.long-description {
    max-width: 300px;
    max-height: 350px;
    overflow: auto;

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// project-navigation end

// Project Header
.project-header {
  padding: 15px;
  border-bottom: 1px solid #edecf5;

  .back-button {
    @media #{$small-and-down} {
      min-width: 28px;
      margin-left: -12px;
    }
  }

  @media #{$medium-and-up} {
    padding: 15px 25px 25px 65px;
  }

  @media #{$small-and-down} {
    .MuiAvatar-root {
      width: 30px !important;
      height: 30px !important;
      border-radius: 5px !important;

      img {
        max-width: 20px;
      }
    }
  }

  .project-icon-hybrid {
    background-color: #ff4170;
  }

  .project-icon-in-person {
    background-color: #5141e7;
  }

  .project-icon-sale {
    background-color: #0addb4;
  }

  .project-icon-registration {
    background-color: #7d35c1;
  }

  .project-icon-email {
    background-color: #16d1fc;
  }

  .project-icon-virtual {
    background-color: #f26135;
  }

  .project-details {
    margin-left: 15px;
    font-size: 14px;
    line-height: 22px;
    color: #726f8e;

    .project-name {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #000056;
    }

    .page-url {
      a {
        color: #726f8e;
        text-decoration: none;
      }
    }

    li {
      list-style: none;

      @media #{$medium-and-up} {
        list-style: disc;
      }
    }
  }
}

// Project Header end

// unlayer-header

.unlayer-header {
  background: #281374;
  box-shadow: 0px 0px 24px rgba(0, 0, 86, 0.23);
  padding: 10px 25px;

  .page-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin: 0 16px 8px;
    margin-bottom: 0;
  }

  .page-url {
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #ffffff;

    span {
      margin-left: 10px;
    }

    &:hover {
      color: #ffffff !important;
      text-decoration: underline !important;
    }
  }

  .buttons {
    .MuiButton-text {
      padding: 6px 8px;
      color: #9a88dc;
      text-transform: capitalize;
    }

    .MuiButton-outlined {
      border: 1px solid #0addb4;
      color: #0addb4;
    }

    .MuiButton-contained {
      color: #000000;
      background: #0addb4;
    }
  }

  .project-navigation {
    .MuiTabs-indicator {
      background: #ffffff;
    }
  }

  .sync {
    color: #fff;
    font-size: 18px;

    .sync-icon {
      color: #fff;
      animation: spin-animation 2s infinite linear;
      font-size: 20px;
    }
  }
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

// unlayer-header end

.finish-pop-ain {
  width: 100%;
  max-width: 770px;
  margin: auto;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;

  .finish-popup-outer {
    text-align: center;
    position: relative;
    padding-top: 64px;
    padding-bottom: 77px;

    img.popupclose {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
}

.finish-popup-head {
  width: 573px;
  margin: auto;

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  p {
    margin-bottom: 20px;
  }

  .finish-single-btn {
    margin-bottom: 40px;

    button {
      width: 100%;
    }
  }

  .finish-popup-btns {
    display: flex;
    justify-content: space-between;

    button {
      img {
        margin-right: 12px;
        position: relative;
      }
    }
  }
}

.finish-popup-footer {
  background: #f3f3ff;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  margin-top: 80px;

  .finish-footer-lft {
    display: flex;
    border: 1px solid #c5c2de;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    position: relative;
    align-items: center;
    width: 49%;
    justify-content: space-between;

    .finish-footer-lft-content {
      display: flex;
    }

    p {
      margin: 0px 12px;
    }

    span {
      background: #ff4170;
      color: #fff;
      border-radius: 4px;
      padding: 2px 4px;

      &.new-bg-green {
        background: $bg-green;
      }
    }
  }
}

.section-divider {
  border-top: 1px solid #c5c2de;
  position: relative;
  margin-top: 40px;
  display: inline-block;
  width: 100%;
  margin-bottom: 50px;

  span {
    color: #c5c2de;
    background: #fff;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    padding: 0 10px;
    width: max-content;
    text-align: center;
    top: -11px;
  }
}

.default-com {
  position: relative;

  span.default-com-inner {
    background: #edecf5;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 10px 10px;
    border-radius: 4px;
  }
}

.sub-add-outer {
  position: relative;

  .subicon {
    position: absolute;
    left: 10px;
    top: 14px;
  }

  .addicon {
    position: absolute;
    right: 10px;
    top: 14px;
  }

  .MuiInputBase-input {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .sub-add-outer-inner input {
    padding: 0px 40px !important;
  }

  img {
    cursor: pointer;
  }
}

.step2-outer {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.steper-outer {
  width: 100%;
}

.project-hybrid {
  &:hover {
    .list-date {
      background: #ff4170;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.project-in-person {
  &:hover {
    .list-date {
      background: #5141e7;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.project-sale {
  &:hover {
    .list-date {
      background: #0addb4;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.project-registration {
  &:hover {
    .list-date {
      background: #7d35c1;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.project-email {
  &:hover {
    .list-date {
      background: #16d1fc;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.project-virtual {
  &:hover {
    .list-date {
      background: #f26135;
      color: #ffffff;

      .date {
        color: #ffffff;
      }
    }
  }
}

.eventOnlyList {
  td {
    border-top: none;
  }

  td.project-info {
    min-width: 500px !important;
    max-width: 500px !important;
  }

  td.project-type {
    min-width: 305px !important;
    max-width: 305px !important;
  }

  td.project-url {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  td.tableDotMore {
    min-width: 50px !important;
    max-width: 50px !important;
  }

  td.project-number {
    min-width: 170px !important;
    max-width: 170px !important;
  }
}

.list-date-outer {
  display: flex;
  align-items: center;

  .list-date {
    transition-duration: 0.25s;
    background: #edecf5;
    border-radius: 8px;
    padding: 10px;
    margin-right: 10px;
    min-width: 80px;
    max-width: 80px;
    height: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .date {
      transition-duration: 0.25s;
      margin: 0;
    }

    .time {
      font-size: 0.875rem;
      margin: 0;
    }
  }

  .live-location {
    display: inline-block;
    align-items: center;
    width: 100%;

    svg {
      width: 10px;
      height: 10px;
      color: #a4a1bd;
      margin: 0px 8px;
    }
  }

  .list-location {
    padding-top: 4px;

    .eventName {
      font-weight: 700;
    }
  }
}

.tab-container {
  padding: 16px;

  @media #{$extra-large-and-up} {
    padding: 16px 24px;
  }
}

.contactsTable {
  .contactTableInner {
    padding: 0;
    overflow: scroll;
    min-height: 300px;
  }
}

.myEventTable {
  .eventTableInner {
    .table {
      .notification-title {
        min-width: 400px;

        .notification-icon {
          width: 32px;
          height: 32px;
          background: #16d1fc;
          border-radius: 8px;
          margin-right: 16px;
          padding: 7px 8px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .display-on {
        min-width: 350px;
      }

      .button-action {
        min-width: 350px;
      }

      .status {
        max-width: 150px;
      }

      thead {
        tr {
          th {
            padding: 12px;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid #edecf5;
        }
      }

      tbody,
      thead {
        tr {
          padding: 4px 12px;

          td {
            padding: 12px;
            align-self: center;
            color: #000056;

            a {
              text-decoration: underline;
              color: #000056 !important;
              text-transform: capitalize;
            }

            span.Tlabel {
              background-color: #ff4170;
              border-radius: 4px;
              color: #fff;
              text-transform: uppercase;
              padding: 5px 9px;
              position: relative;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.options {
  position: relative;
  cursor: pointer;

  &:hover {
    .options-list {
      display: block;
    }
  }

  &-button {
    cursor: pointer;
  }

  &-list {
    display: none;
    position: absolute;
    min-width: 150px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.23);
    right: 0px;
    text-align: left;
    padding: 8px;
    z-index: 1;

    div {
      width: 100%;
      // height: 32px;
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #000056;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.required {
        color: #ff4170;
      }

      img {
        margin-right: 10px;
      }

      &:hover {
        background: #edecf5;
      }

      a {
        font-size: 14px;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    hr {
      margin: 5px -8px;
    }
  }
}

.option-container {
  &:nth-last-child(-n + 3) {
    .options-list {
      bottom: 20px;
    }
  }

  &:nth-child(-n + 3) {
    .options-list {
      bottom: unset;
    }
  }
}

.popper-options-list {
  @extend .options-list;
  display: block;
}

.formSection form .field-group label {
  font-size: 16px !important;
}

.loc1 {
  input {
    border: 1px solid #ccced5 !important;
    background-color: #fff !important;
    box-shadow: none !important;
  }
}

.form-control {
  border: 1px solid #ccced5 !important;
  background-color: #fff !important;
  box-shadow: none !important;

  .PhoneInputInput {
    border: none;
    outline: none;
  }

  &.select {
    border: none !important;
  }

  &.code-interface {
    background-color: #f1f5f9 !important;
    border: none !important;
    resize: none;
  }
}

.react-datepicker__input-container {
  input {
    border: 1px solid #ccced5 !important;
    background-color: #fff !important;
    box-shadow: none !important;
  }
}

.react-time-picker__inputGroup {
  border: 1px solid #ccced5 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.react-time-picker .react-time-picker__inputGroup {
  background-color: #fff !important;
}

.grid-show {
  display: none;
}

.eventGridBoxes {
  .grid-show {
    display: block;
  }

  .list-date {
    display: none;
  }

  .live-location {
    display: none;
  }
}

.header-common {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;
  padding-bottom: 5px;

  @media #{$medium-and-up} {
    padding: 30px 40px;
  }

  .heading {
    font-family: 'rubik';
    text-transform: capitalize;
  }
}

.edit-step-wrapper {
  height: calc(100vh - 50px);
  overflow: auto;
}

.stepper-outer-common {
  padding-top: 108px;
  max-width: 1100px;
  margin: 0 auto;

  @media #{$small-and-down} {
    padding-top: 180px;

    .step1-heading {
      margin-bottom: 8px;
    }
  }
}

.name-circle {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: #a358df;
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 6px 0px;
  margin-right: 10px;
}

.team-dash-right {
  display: flex;
  align-items: center;

  // button {
  //     margin-left: 10px;
  // }
}

.top-filter-area {
  .filter-options {
    z-index: 99;
  }

  .option-list {
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .checkbox-item {
    padding-left: 24px;

    label {
      margin-bottom: 0;

      span {
        padding: 0;
      }

      .checkbox {
        margin-right: 9px;
      }
    }
  }
}

.top-filter-area,
.filterSearchBar {
  display: block;

  @media #{$medium-and-up} {
    display: flex;
  }

  justify-content: space-between;

  .project-main-tabs {
    width: auto;

    .MuiTypography-body1 {
      font-size: 0.85rem;
    }
  }

  .team-dash-right {
    .search {
      input {
        padding: 10px 0 10px;
      }
    }

    .sort {
      margin-left: 35px;
      padding-right: 35px;
      height: 32px;

      background: #edecf5;
      border-radius: 4px;

      &::before,
      &::after {
        border-bottom: 0;
      }

      &:hover {
        &::before,
        &::after {
          border-bottom: 0;
        }
      }

      #sort-simple-select-outlined {
        padding: 6px !important;
        font-weight: 400;
        font-size: 14px;
        color: #000;
      }
    }
  }
}

span.table-status {
  text-transform: uppercase;

  color: #fff;
  border-radius: 4px;
  padding: 3px 10px;

  &.success {
    background: #0addb4;
  }

  &.danger {
    background: #ff4170;
  }

  &.warning {
    background: #f26135;
  }

  &.light {
    background: #edecf5;
    color: #000056;
  }

  &.primary {
    background: #5141e7;
  }

  &.disable {
    background: #d6d6d6 !important;
  }
}

.team-role-color {
  display: flex;
  margin-right: 10px;

  .team-role-color-orange {
    height: 9px;
    width: 6px;
    background: $bg-orange;
    margin: 0px 1px;
  }

  .team-role-color-grey {
    height: 9px;
    width: 6px;
    background: $bg-grey;
    margin: 0px 1px;
  }

  .team-role-color-green {
    height: 9px;
    width: 6px;
    background: $bg-green;
    margin: 0px 1px;
  }
}

.team-role-color-outer {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type='number'] {
  -moz-appearance: textfield;
} */

.finishButton {
  text-align: right;
  align-items: right;
  position: absolute;

  z-index: 1;

  @media #{$small-and-down} {
    top: 50px;
    left: 20px;
  }

  @media #{$medium-and-up} {
    right: 20px;
    top: 4px;
  }

  @media #{$extra-large-and-up} {
    right: 68px;
    top: 18px;
  }
}

.edit-project {
  .finishButton {
    @media #{$small-and-down} {
      top: 0px;
      right: 10px;
    }
  }
}

.custom-control {
  padding-left: 24px;

  .custom-control-label {
    cursor: pointer;

    &::after {
      top: -3px;
      left: -4px;
      width: 24px;
      height: 24px;
      background-size: 10px 10px;
    }

    &::before {
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #dee2e6;
      background: transparent;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3f51b5;
}

// List Table start
.list-table {
  position: relative;
  min-height: 400px;

  .table {
    tr {
      display: flex;
      align-items: center;

      td,
      th {
        font-size: 14px;
        line-height: 22px;
        color: #000056;

        vertical-align: middle;
        border-top: 0;
        border-bottom: 0;
        width: 100%;
        min-width: 170px;
        padding: 15px;
        word-break: break-all;

        &.sticky-cell {
          position: sticky !important;
          left: 0px;
          background-color: #ffffff;
          border-radius: 8px;
          z-index: 1;

          @media #{$small-and-down} {
            position: unset !important;
          }
        }

        &.sticky-cell-last {
          position: sticky;
          right: 0px;
          background-color: #ffffff;
        }

        &.checkbox {
          min-width: 50px;
          max-width: 50px;

          padding-top: 0;
          padding-bottom: 0;
        }

        &.email {
          min-width: 250px;
          max-width: 320px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.status {
          min-width: 250px;
          max-width: 250px;
        }

        &.text {
          min-width: 200px;
          max-width: 200px;
        }

        &.long-text {
          min-width: 300px;
          max-width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.custom-field {
          min-width: 200px;
          // max-width: 350px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.short-text {
          min-width: 180px;
          max-width: 180px;
        }

        &.date-time {
          min-width: 250px;
          //  max-width: 250px;
        }

        &.id {
          min-width: 100px;
          max-width: 100px;
        }

        &.name-avatar {
          min-width: 250px;

          //   max-width: 250px;
          p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.long-title {
          min-width: 300px;

          //   max-width: 250px;
          .paragraph {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 200px;
          }
        }

        &.options {
          min-width: 85px;
          max-width: 85px;
          position: relative;
          cursor: pointer;

          &:hover {
            .options-list {
              display: block;
            }
          }

          .options-button {
            cursor: pointer;
          }

          .options-list {
            display: none;
            position: absolute;
            width: 170px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.23);
            right: 45px;
            text-align: left;
            padding: 8px;
            z-index: 1;

            div {
              width: 100%;
              // height: 32px;
              border-radius: 4px;
              padding: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              color: #000056;
              cursor: pointer;
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
              }

              &:hover {
                background: #edecf5;
              }

              &.disabled {
                opacity: 0.5;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.12);
                }
              }

              a {
                font-size: 14px;
                text-decoration: none;
                text-transform: capitalize;
              }
            }

            hr {
              margin: 5px -8px;
            }
          }

          &.tableDotMore {
            .options-list {
              right: 10px;
            }
          }
        }
      }

      .copy {
        min-width: 70px;
        max-width: 70px;
        font-size: 0;
        transition-duration: 10ms;
        text-align: center;
      }

      &:hover {
        .copy {
          font-size: inherit;
          cursor: pointer;
          transition-duration: 80ms;
        }
      }

      &:nth-last-child(-n + 3) {
        .options-list {
          bottom: 45px;
        }
      }

      &:nth-child(-n + 3) {
        .options-list {
          bottom: unset;
        }
      }

      &:nth-last-child(-n + 3) {
        .info {
          bottom: 23px;
          top: unset !important;
        }
      }

      &:nth-child(-n + 3) {
        .info {
          bottom: unset;
        }
      }
    }

    thead {
      z-index: 0;
      position: relative;

      th {
        font-weight: 500;
        cursor: pointer;

        &.copy {
          cursor: default !important;
        }

        &.sticky-cell {
          background-color: #f6f6fc;
          z-index: 1;
        }

        &.sort-asc,
        &.sort-des {
          position: relative;

          &::after {
            content: '';
            background: url(../../images/icons/sortingArrow.svg) no-repeat
              center;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            transition-duration: 0.2s;
          }
        }

        &.sort-asc {
          &::after {
            transform: translate(5px, -50%) rotateX(0deg);
          }
        }

        &.sort-des {
          &::after {
            transform: translate(5px, -50%) rotateX(180deg);
          }
        }
      }
    }

    tbody {
      tr {
        background: #ffffff;
        border: 1px solid #edecf5;
        border-radius: 8px;
        margin-bottom: 4px;
        position: relative;

        &:hover {
          box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
        }

        td {
          font-weight: 400;
        }
      }
    }
  }

  .react-data-table-container {
    overflow: auto;

    .react-data-table {
      min-height: 400px;
      width: 100%;

      .rdt_TableHeader {
        background: transparent;
        min-height: 18px;
      }

      .rdt_Table {
        background: transparent;
        min-height: 400px;

        .rdt_TableCol,
        .rdt_TableCell {
          font-size: 14px;
          line-height: 22px;
          color: #000056;
          vertical-align: middle;
          border-top: 0;
          border-bottom: 0;
          padding: 15px;
        }

        .rdt_TableHead {
          .rdt_TableHeadRow {
            background: transparent;
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            background: #ffffff;
            border: 1px solid #edecf5;
            border-radius: 8px;
            margin-bottom: 4px;

            .rdt_TableCell {
              font-weight: 400;

              div:first-child {
                overflow: unset;
                white-space: unset;
              }
            }
          }
        }
      }
    }
  }
}

// List Table end

// roles-table

.roles-table {
  .table {
    tr {
      td,
      th {
        width: 231px;
        min-width: 231px;
        max-width: 231px;
      }
    }
  }
}

//groups-list

.groups-list {
  &-card {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
    padding: 16px 22px;
    border-radius: 16px;
    background-color: #fff;
  }
}

.custom-multi-select {
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    top: 11px;
    position: absolute;
  }

  label {
    width: auto;
    margin-left: 25px;
    margin-bottom: 0 !important;
  }
}

// early-access

.outlined-section {
  width: 100%;
  padding: 44px 26px;
  border: 1px solid #ccced5;
  border-radius: 4px;
  position: relative;

  @media screen and (min-width: 1240px) {
    width: calc(100% + 82px);
    margin: 0 -26px;
  }

  .early-access-groups {
    margin-top: 30px;
    padding-top: 25px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 164px;
      height: 1px;
      background-color: #ccc9e2;
      top: 0px;
      left: calc(50% - 82px);
    }

    &:nth-child(2) {
      margin-top: 0px;
      padding-top: 0px;

      &::before {
        content: none;
      }
    }
  }

  .paragraph {
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    background-color: #ffffff;
    top: -10px;
    left: 10px;
    padding: 0 15px;
    line-height: 20px;
  }
}

// edit campaign

.selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 8px;
  background: #eceaff;
  border-radius: 4px;
  margin-top: 16px;

  img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}

// Report Dashboard

.report-summary-card {
  border: 1px solid #edecf5;
  padding: 18px;
  background: #ffffff;
  border-radius: 8px;

  &:hover {
    border: 5px solid #edecf5;
    padding: 14px;
    transition-duration: 0.18s;
  }
}

input.form-control.small.attendeeEmail {
  cursor: not-allowed;
}

// report event timer

.show-counter {
  display: flex;

  .countdown {
    text-align: center;

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #000056;

      background: #edebff;
      border-radius: 4px;
      min-width: 47px;
      height: 45px;
      padding: 12px 10px;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #726f8e;
    }
  }

  & > p {
    width: 8px;
    font-size: 0;
  }
}

.formSection form .field-group.timezone input:focus {
  border: 0 !important;
}

.field-group.timezone {
  input {
    border: 0 !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }
}

// profile

.user-profile {
  position: fixed;
  z-index: 99;
  cursor: pointer;

  .edit-profile {
    position: absolute;
    right: -2px;
    bottom: -2px;
  }

  &.bottom-left {
    bottom: 25px;
    left: 25px;
    top: unset;
    right: unset;
  }

  &.bottom-right {
    bottom: 25px;
    right: 25px;
    top: unset;
    left: unset;
  }

  &.top-left {
    top: 25px;
    left: 25px;
    bottom: unset;
    right: unset;
  }

  &.top-right {
    top: 25px;
    right: 25px;
    bottom: unset;
    left: unset;
  }
}

.update-avtar {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition-duration: 0.2s;
  border-radius: 100px;

  &:hover {
    opacity: 1;
  }
}

.my-profile {
  .gamification {
    .your-rank,
    .top-ranks {
      padding-top: 16px;
      min-width: 172px;
      min-height: 157px;
      border: 1px solid #000056;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 4px;

      .lable {
        position: absolute;
        top: -15px;
        left: 20px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 50px;
        text-align: center;
        padding: 6px 12px;
        background-color: #000056;
        color: #ffffff;
        z-index: 1;
      }

      .rank {
        font-size: 11px;
        font-weight: 500;
        min-width: 53px;
        border: 4px solid #ffffff;
        border-radius: 50px;
        text-align: center;
        padding: 2px 5px;
        background-color: #000056;
        color: #ffffff;
        margin-top: -15px;
        margin-bottom: 4px;
        z-index: 1;
      }
    }

    .top-ranks-wrapper {
      @media screen and (min-width: 600px) {
        max-width: calc(100% - 232px);
      }

      .top-ranks {
        align-items: flex-start;
        padding-top: 28px;

        .lable {
          left: 20px;
        }
      }
    }
  }

  .warning-bar {
    // position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
    border-radius: 8px 8px 0 0;
    font-size: 14px;
    background-color: #f26135;
    color: #ffffff;
  }
}

.user-profile-pic {
  position: relative;
  z-index: 99;

  .edit-profile {
    position: absolute;
    right: -2px;
    top: -2px;
  }
}

.site-profile {
  .MuiTabs-indicator {
    height: 3px;
    background: #ffffff;
  }

  .MuiTabs-flexContainer {
    justify-content: center;
  }
}

// agenda-filter

.agenda-filter {
  .react-multiple-carousel__arrow {
    width: 25px;
    min-width: 25px;
    height: 63px;
    background-color: #ffffff;
    border-radius: 0;

    &:hover {
      background-color: #ffffff;
    }

    &::before {
      color: #000;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .date-btn {
    background-color: #edecf5;
    color: #000;
    min-width: 41px;
    font-weight: 400;
    border-radius: 10px;
    padding: 8px 12px;

    &:hover,
    &.active {
      background-color: #5141e7 !important;
      color: #ffffff;

      .month {
        color: #ffffff;
      }
    }

    .date {
      font-size: 16px;
      font-weight: 700;
    }

    .month {
      color: #726f8e;
      font-size: 12px;
      text-transform: capitalize;
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
  margin: 0 12px;
}

.btn-file input[type='file'] {
  // margin: 0 20px;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  background-color: #000056 !important;
}

.crop-imag {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px;
}

.btn-primary {
  color: #fff;
  background-color: #5141e7 !important;
  border-color: #5141e7 !important;

  &:hover {
    background-color: #000056 !important;
    border-color: #000056 !important;
  }
}

.agenda-list {
  // box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #dae4ff;
  margin-bottom: 24px;
  border-radius: 8px;

  .addeventatc {
    font-size: 13px;
    text-transform: uppercase;
    color: #5b33f1 !important;
    padding: 8px 12px 7px 43px;
    border: 1px solid rgba(81, 65, 231, 0.5);
    text-shadow: none;
    box-shadow: none !important;

    &:hover {
      font-size: 13px;
      background-color: transparent;
    }

    &:active {
      border-width: 1px;
    }

    .addeventatc_icon {
      top: 5px;
      background: url(../../images/icons/calender.svg) no-repeat;
    }
  }
}

.template-slider {
  &.react-multi-carousel-list {
    max-height: 180px;
  }

  .react-multiple-carousel__arrow {
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    border-radius: 0;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .page-preview {
    min-height: 180px;
  }
}

.mobile-view {
  width: 390px;
  margin: 0 auto;
}

.template-iframe-wrapper {
  height: calc(100vh - 175px);
  // overflow: auto;

  .page-preview {
    pointer-events: all;
  }
}

.manual-note {
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;
  max-width: 300px;

  .note {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #edebff;
  }

  // .added-by {
  //   // padding-top: 3px;
  // }
}

.ql-snow {
  strong {
    font-weight: 600 !important;
  }

  .ql-tooltip.ql-flip {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.integrated-apps {
  width: 280px;
  max-width: 280px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #edecf5;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .app-icon {
    max-width: 64px;
    max-height: 64px;
  }

  .app-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000056;

    // line-clamp

    display: -webkit-box;
    //-webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .app-detail {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #726f8e;

    // line-clamp

    display: -webkit-box;
    //-webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.app-dashboard {
  .project-sidebar {
    @media #{$medium-and-up} {
      position: sticky;
      top: 0px;
      z-index: 2;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      background-color: #fff !important;
    }

    @media #{$extra-large-and-up} {
      position: unset;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
      padding: 0;
      background-color: initial;
    }

    .react-tabs__tab-list {
      @media #{$extra-large-and-up} {
        position: sticky;
        top: 10px;
        z-index: 2;
      }
    }
  }

  .container-sidebar-right {
    .tab-title {
      text-transform: uppercase;
    }

    .top-filter-area {
      position: sticky;
      top: -1px;
      z-index: 2;
      background-color: #ffffff;

      @media #{$medium-and-up} {
        top: 81px;
      }

      @media #{$extra-large-and-up} {
        top: -1px;
      }
    }
  }

  .desktop-chat,
  .mobile-chat {
    width: 315px;
    height: 40px;
    background: #5141e7;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #ffffff;
    padding: 8px;
  }

  .mobile-chat {
    width: 90px;
    padding: 6px 8px;
    border-radius: 4px;

    img {
      margin-right: 8px;
    }
  }

  .widget-position {
    min-width: auto;
    padding: 0;

    &.active {
      border: 1px solid #5141e7;
    }
  }
}

.bar-message {
  padding: 5px 8px;
  background-color: #f29b35;
  text-align: center;
  color: #ffffff;
  z-index: 1;
  width: 100%;
}

.plan-info-table {
  table {
    thead {
      tr {
        th {
          padding: 8px;
          background-color: #faf9fc;
          border-top: 1px solid #dacef3;
          border-bottom: 1px solid #dacef3;

          &:first-child {
            border-left: 1px solid #dacef3;
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-right: 1px solid #dacef3;
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 10px;
          border-color: #edecf5;
        }
      }
    }
  }
}

.billing-page {
  .plan-details {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 2;

    a.plan-link {
      color: #5141e7;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }

  .list-table {
    table {
      tbody {
        tr {
          // border: 1px solid #5141e7;
          &:hover {
            box-shadow: none;
          }
        }
      }
    }

    table.project-usage {
      tbody {
        tr {
          td {
            &.danger {
              color: #ff4170;
              font-weight: 500;
            }

            span.count {
              padding: 2px 6px;
            }

            span.danger {
              background: rgba(255, 0, 0, 0.08);
              color: #ff4170;
              border-radius: 10px;
              font-weight: 600;
            }
          }
        }
      }

      th,
      td {
        &.long-text {
          min-width: 250px;
          max-width: 250px;
        }

        &.short-text {
          min-width: 150px;
          max-width: 150px;
        }
      }
    }
  }
}

.notification {
  .title {
    font-size: 1.2em;
  }

  &:after {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    font-family: cursive;
    width: 28px;
    height: 28px;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    content: '\2715';
  }
}

.cap {
  text-transform: capitalize;
}

.search-filter {
  padding: 16px;

  .filter-title {
    font-size: 14px;
    color: #000056;
  }

  .filter-items {
    cursor: pointer;
  }
}

.filter-items {
  padding: 4px 12px;
  background: #f0eeee;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;

  &.active {
    background: #5141e7;
    color: #ffffff;

    .icon {
      color: #ffffff;
    }
  }

  .icon {
    font-size: 22px;
    color: #726f8e;
    margin-right: 12px;
  }

  .remove-btn {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.results-title {
  font-size: 12px;
  margin-bottom: 0;
}

.search-item {
  justify-content: space-between !important;
  color: #000056 !important;
  border-bottom: 1px solid #edecf5 !important;
  padding: 12px 16px !important;

  &:last-child {
    border-bottom: none !important;
  }

  .avatar {
    width: 24px;
    height: 24px;
    // background: #ff4170;
    color: #fff;
    font-size: 12px;
    margin-right: 16px;
  }

  .icon {
    font-size: 22px;
    color: #726f8e;
  }
}

.selected-guest {
  margin-top: 15px;

  .search-item {
    border-bottom: none !important;
    padding: 12px 8px !important;

    .recipents-email {
      max-width: calc(100% - 50px);

      .email {
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .recipents-status {
      min-width: 45px;
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .avatar {
      margin-right: 10px;
    }

    .icon {
      opacity: 0;
      cursor: pointer;
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
}

.cname-btns {
  button {
    text-transform: lowercase !important;
    border-radius: 25px !important;
    background-color: #edebff !important;
    padding: 6px 20px !important;
    font-weight: 400 !important;
  }
}

.domain-verification {
  background-color: rgba(237, 235, 255, 0.3);
  border-radius: 4px;

  .paragraph {
    font-size: 15px;
  }

  .small-subtitle {
    font-size: 12px;
  }

  .steps {
    .step {
      width: 18px;
      height: 18px;
      border: 1px solid transparent;
      border-radius: 42px;
      background: #edebff;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-align: center;
      margin-right: 10px;
      color: #726f8e;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 20px;
        background-color: #cbc6ff;
        left: calc(50% - 0.5px);
        top: 17px;
      }

      &.in-process {
        border: 1px solid #5141e7;
        color: #5141e7;
      }

      &.done {
        background: #ffffff;

        .icon {
          width: 20px;
          height: 20px;
          left: -2px;
          top: -2px;
          position: absolute;
        }
      }
    }

    &:last-child {
      .step {
        &::after {
          content: none;
        }
      }
    }
  }
}

.cname-table,
.collapse-table {
  .table,
  table {
    tr {
      display: flex;
      align-items: center;

      td,
      th {
        border: none;
        color: #000056;
        width: 100%;

        &.short-text {
          min-width: 150px;
          max-width: 150px;
        }
      }
    }

    thead {
      background-color: #edebff;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f6f8fe;
        }
      }
    }
  }
}

.collapse-table {
  // .table {
  tr {
    display: flex;
    align-items: center;

    td,
    th {
      padding: 8px;
      border: none;
      color: #000056;
      width: 100%;
      min-width: 200px;
      // max-width: 200px;

      &.short-text {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
      }

      &.checkbox {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
      }
    }
  }

  // thead {
  //   // background-color: #edebff;
  // }

  tbody {
    tr {
      background-color: #ffffff;

      &:nth-child(3n + 1) {
        background-color: #f6f8fe;
      }
    }
  }

  &.inner-table {
    tbody {
      tr {
        background-color: #ffffff;

        &:nth-child(even) {
          background-color: #f6f8fe;
        }
      }
    }
  }

  // }
}

.long-url {
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
}

.long-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.group-name {
  max-width: 200px;
  display: -webkit-box;
  // -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.long-list-name {
  /* max-width: 230px; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-record {
  color: #7070cb;
  border-radius: 4px;
  padding: 3px 10px;
  background: #edebff;
  opacity: 0;
  transition-duration: 300ms;
  margin-left: 10px;

  &.show {
    opacity: 1;
    transition-duration: 100ms;
  }
}

.default-tooltip {
  background-color: #000000;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  padding: 12px 24px;
}

.MuiOutlinedInput-root.mui-custom-select {
  border-radius: 4px;
}

.mui-custom-select {
  border-radius: 4px;

  &.bg-grey {
    background: #edecf5 !important;
  }

  .MuiSelect-select {
    padding: 6px 14px;
  }
}

.Container-Qr {
  min-height: 600px;
  max-width: 1140px;
  background: #ffffff;
}

.alinCenter {
  text-align: center;
  padding: 15px;
}

.tooltip-popup {
  min-width: 120px;
  max-height: 175px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 8px;
  padding-right: 2px;
  background: #ffffff;
  color: #000056;
  box-shadow: 0px 4px 28px rgba(0, 0, 89, 0.23);
  border-radius: 8px;
  top: 30px;
  right: -25px;
  z-index: 2;

  & ~ .MuiTooltip-arrow,
  ~ span {
    color: #fff;

    &::before {
      box-shadow: 0px 0px 4px rgba(0, 0, 86, 0.23);
    }
  }

  div {
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #000056;
    cursor: pointer;
    display: flex;
    align-items: center;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 150px;
    }

    img {
      margin-right: 10px;
    }

    &:hover {
      background: #edecf5;
    }

    a {
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
    }
  }
}

.disabled-overlay {
  backdrop-filter: blur(8px);
}

input[type='date'] {
  &:before {
    margin-right: 0 !important;
  }
}

// ---mobile-menu---

.mobile-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  .mobile-header {
    background-color: #281374;
    padding: 0px 10px;

    .mobile-logo {
      height: 30px;
    }
  }

  .mobile-navigation {
    background-color: #281374;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 6px;
    padding-bottom: 10px;

    .navbar-nav {
      flex-direction: row;
      justify-content: space-evenly;

      .nav-link {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        padding: 2px 10px;

        .sidebar-projects,
        .sidebar-setings {
          height: 30px;
          width: 30px;
          background-size: 30px;
        }

        .leftIcon {
          padding: 2.5px;

          img {
            max-width: 25px;
            max-height: 25px;
          }
        }

        .navMenus {
          color: #ffffff;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
}

// Google Sign in Button

.google-sign-in-button {
  display: inline-block;
  background: white;
  color: #444;
  width: 191px;
  height: 46px;
  //  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  border: 1 px solid #c2c8d0;
  margin-top: 5px;
  margin-bottom: 1px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    background-image: url('https://developers.google.com/static/identity/images/g-logo.png');
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }

  .buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */

    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #757575;
    width: calc(100% - 42px);
  }
}

.bagde-modal {
  zoom: 70%;

  .u_body {
    min-height: unset !important;
  }
}

img.attendeeImageBadges {
  width: 200px;
  border-radius: 50%;
}

@media print {
  img.attendeeImageBadges {
    width: 200px !important;
    border-radius: 50% !important;
  }

  // body {
  // -webkit-print-color-adjust: exact;
  // }
}

img.outputQrcodeclass {
  max-width: 100%;
}

/* style sheet for "A4" printing */
@media print and (width: 21cm) and (height: 29.7cm) {
  @page {
    margin: 10cm;
  }
}

/* style sheet for "letter" printing */
@media print and (width: 2.5in) and (height: 2in) {
  @page {
    margin: 3in;
  }
}

/* A4 Landscape*/
@page {
  size: A4 landscape;
  margin: 20%;
}

.attendee-search {
  .attendee-list {
    justify-content: space-between;
    cursor: default;

    .button {
      cursor: pointer;

      &.add {
        color: #000056;
      }

      &.remove {
        color: #ff4170;
      }
    }
  }
}

// .system-user {
//   // background: #a2df69 !important;

//  // td {
//     //  background: #a2df69 !important;
//  // }
// }

.kiosk-user-tag {
  color: #ffffff;
  background-color: #281374;
  font-size: 9.5px;
  font-weight: 600;
  border: 2px solid #ffffff;
  border-radius: 25px;
  padding: 3px 4px 2px;
  transform: translate(-14px, 30px);
  width: max-content;
  cursor: pointer;
}

.progress-button {
  position: relative !important;

  span {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #8e84f3;
    border-radius: 4px;
    animation: progressButtonAnimate 10s ease-in;
  }
}

.progress-button-print-preview {
  position: relative !important;

  span {
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #8e84f3;
    border-radius: 4px;
    animation: progressButtonAnimate 5s ease-in;
  }
}

@keyframes progressButtonAnimate {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.on-going-filter {
  &:before {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    top: 3px;
    right: 3px;
    border-radius: 3px;
    background: #0addb4;
    animation: animate 1.2s linear infinite;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  // 66% {
  //   opacity: 1;
  // }
  100% {
    opacity: 0;
  }
}

.ai-chat-general {
  .horizontal-tab {
    .tab {
      text-transform: capitalize;

      &:hover {
        border-bottom: 1px solid rgba(91, 51, 241, 0.3);
      }

      &.Mui-selected {
        color: #5b33f1 !important;
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background: #5b33f1;
      bottom: 6px;
    }

    .tab-panel {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 19px 17px;

      .tab-content {
        .tab-title {
          color: #000056;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 137.5% */
          letter-spacing: 0.074px;
          text-transform: uppercase;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 24px;
        }

        .dropzone {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 145px;
          border: 1px dashed rgba(0, 0, 0, 0.2);

          svg {
            display: none;
          }
        }

        .drop-title {
          font-size: 14px;
          color: #000;
        }

        .supported-files {
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
        }

        svg.delete-icon {
          color: #ff4949;
        }

        .files-instruction {
          color: rgba(0, 0, 0, 0.4);
          font-size: 13px;
          font-weight: 400;
          line-height: 22px;
          /* 169.231% */
        }

        .file-preview {
          .section-divider {
            margin-top: 24px;
            margin-bottom: 24px;

            .attached-file {
              color: #000;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 169.231% */
              top: -13px;
            }
          }

          .preview-chip {
            color: #000;
            width: 100%;
            border: 0;
            height: auto;
            padding: 8px;
            font-size: 13px;
            min-width: 160px;
            background: #f3f3f3;
            font-weight: 400;
            line-height: 22px;
            border-radius: 8px;
            justify-content: space-between;

            p {
              margin-bottom: 0;

              span {
                opacity: 0.5;
              }
            }
          }
        }

        .qna {
          padding: 16px;
          border-radius: 12px;
          background: #f7f7f7;
          margin-bottom: 24px;

          .question,
          .answer {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 160% */
            letter-spacing: 0.069px;
          }

          .question {
            color: #000;
          }

          .answer {
            color: #484848;
          }
        }
      }
    }
  }

  .sources {
    border-radius: 12px;
    background: #f7f5ff;
    padding: 32px;
    margin-top: 48px;
    margin-bottom: 13px;
    max-width: 350px;
  }
}

.agent-id {
  .copy {
    width: 0;
    transition-duration: all 10ms;
    text-align: center;
  }

  &:hover {
    .copy {
      width: 14px;
      cursor: pointer;
      transition-duration: all 800ms;
    }
  }
}

.chat-filter {
  .anaSelectDRange
    .inputField
    .formDate
    .react-datepicker-wrapper
    .outlineButton {
    min-height: 30.75px !important;
    color: #000056 !important;
    background-size: 16px;
    background-position: right 7px center;
    font-size: 13px;
    width: 140px;
  }
}
.ai-chat-dashboard {
  .chat-logs {
    .chat-window {
      .chat-list {
        max-height: 75vh;
        height: 75vh;
        overflow: auto;
        padding-right: 4px;

        @media #{$small-and-down} {
          height: auto;
        }

        .chat-tab {
          border-radius: 3px;
          padding: 16px 9px;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.1);

          &:hover,
          &.active {
            border: 1px solid #edecf5;
            background: #edecf5;
          }

          .customer {
            color: #5141e7;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            /* 183.333% */
            letter-spacing: 0.055px;
            text-transform: capitalize;
            width: 60%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            //  -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .time {
            color: rgba(0, 0, 0, 0.8);
            font-size: 10px;
            font-weight: 500;
            line-height: 22px;
            /* 220% */
            letter-spacing: 0.046px;
            text-transform: capitalize;
          }

          .bot {
            color: #000;
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            // -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
      }

      .chat-history {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 28px 17px;
        max-height: 75vh;
        height: 75vh;
        // margin-bottom: 16px;
        overflow-y: auto;

        .chat-bubble {
          display: flex;
          position: relative;

          .message {
            border-radius: 3px;
            display: inline-flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: baseline;

            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            /* 169.231% */
            letter-spacing: 0.06px;
            position: relative;
            max-width: 80%;

            .reaction {
              position: absolute;
              background: #ffffff;
              padding: 4px 12px;
              border-radius: 50px;
              bottom: -22px;
              left: 12px;
              border: 1px solid #5d2be3;
              visibility: hidden;
              opacity: 0;
              z-index: 1;

              .icon {
                font-size: 18px;
                cursor: pointer;
                margin: 2px 5px 2px 5px;

                &:hover {
                  font-size: 22px;
                  transition-duration: 300ms;
                  margin: 0px 3px 0px 3px;
                }
              }

              &.active {
                visibility: visible;
                opacity: 1;
                transition-duration: all 250ms;

                .icon {
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }

            &:hover,
            &.active {
              .reaction {
                visibility: visible;
                opacity: 1;
                transition-duration: all 250ms;
              }
            }

            &:hover {
              .reaction {
                .icon {
                  display: block;
                }
              }
            }
          }

          .chatbot-avatar {
            position: absolute;
            bottom: 0;
            left: 0;
          }

          &.bot {
            justify-content: start;

            .message {
              background: #edebff;
              color: #000056;
              // margin-left: 30px;
              // margin-bottom: 30px;
            }
          }

          &.client {
            justify-content: end;

            .message {
              background: #5141e7;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.ai-chat-widget {
  .chat-widget-preview {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    // background: #fff; // Bg-color added inline by chat interface
    position: relative;
    overflow: auto;

    .widget-title {
      color: #000056;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 137.5% */
      letter-spacing: 0.074px;
      text-transform: none !important;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .chatbot-avatar {
        width: 36px;
        height: 36px;

        img {
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      }
    }

    .chat-window {
      .chat-history {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 16px 8px;
        max-height: 75vh;
        height: calc(100vh - 330px);
        // margin-bottom: 16px;
        overflow-y: auto;

        .chat-bubble {
          display: flex;
          position: relative;

          .message {
            border-radius: 3px;
            display: inline-flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: baseline;

            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            /* 169.231% */
            letter-spacing: 0.06px;
            position: relative;
            max-width: 80%;

            .reaction {
              position: absolute;
              background: #ffffff;
              padding: 4px 12px;
              border-radius: 50px;
              bottom: -22px;
              left: 12px;
              border: 1px solid #5d2be3;
              visibility: hidden;
              opacity: 0;
              z-index: 1;

              .icon {
                font-size: 18px;
                cursor: pointer;
                margin: 2px 5px 2px 5px;

                &:hover {
                  font-size: 22px;
                  transition-duration: 300ms;
                  margin: 0px 3px 0px 3px;
                }
              }

              &.active {
                visibility: visible;
                opacity: 1;
                transition-duration: all 250ms;

                .icon {
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }

            &:hover,
            &.active {
              .reaction {
                visibility: visible;
                opacity: 1;
                transition-duration: all 250ms;
              }
            }

            &:hover {
              .reaction {
                .icon {
                  display: block;
                }
              }
            }
          }

          .chatbot-avatar {
            position: absolute;
            bottom: 0;
            left: 0;
          }

          &.bot {
            justify-content: start;

            .message {
              background: #edebff;
              color: #000056;
              // margin-left: 30px;
              // margin-bottom: 30px;
            }
          }

          &.client {
            justify-content: end;

            .message {
              background: #5141e7;
              color: #fff;
            }
          }
        }
      }
    }

    .chat-input {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 16px;
      // background-color: #ffffff; // Bg-color added inline by chat interface
    }
  }

  &.dark {
    .chat-widget-preview {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: #000000;

      .widget-title {
        color: #ffffff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }

    .chat-input {
      // border-top: 1px solid rgba(255, 255, 255, 0.1);
      background-color: #000000;

      input {
        color: #d4d4d8;
      }

      .send-icon {
        color: #d4d4d8;
      }
    }
  }
}

.meeting-calender-modal {
  .calender-service {
    cursor: pointer;

    .service-icon {
      border: 1px solid #e3e7eb;
      border-radius: 100px;
      max-height: 109px;
      max-width: 109px;

      &:hover,
      &.active {
        border-color: #5141e7;
      }
    }
  }
}

.floating-chat-widget {
  z-index: 9999;
  position: fixed;
  right: 25px;
  bottom: 40px;

  &.left {
    left: 25px;
    right: unset;
  }

  .chat-icon {
    cursor: pointer;
  }
}

.floating-chat-window {
  width: 350px;
  // min-height: 400px;
  border-radius: 4px;

  .ai-chat-widget .chat-widget-preview .chat-window .chat-history {
    max-height: calc(100vh - 355px);
  }
}

.files {
  .file-view-buttons {
    .viewBtn {
      min-width: 32px !important;
      padding: 6px !important;

      &.active {
        background-color: #edebff !important;
      }
    }
  }

  .files-container {
    max-height: calc(100vh - 250px);
    height: 100vh;
    overflow: auto;

    .files-heading {
      text-transform: uppercase;
    }

    .file-tile {
      width: 350px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #edebff;
      cursor: pointer;

      .file-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        color: #000056;
        max-width: 230px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          color: #5141e7;
        }
      }

      &.with-thumnail {
        padding: 6px;

        .file-thumnail {
          margin-bottom: 6px;
          background-color: #f2f2f2;
          height: 140px;
          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            max-width: 100%;
            border-radius: 6px;
            object-fit: cover;
            object-position: center;
            height: 140px;
            width: 100%;
          }

          .icon {
            object-position: center;
            width: 78px;
          }
        }
      }
    }

    &.list {
      padding-right: 8px;

      .file-tile {
        width: 100%;
        padding: 2px 4px;
        border-radius: 4px;

        &.list-header {
          border: 0;
        }

        .file-icon {
          img {
            width: 22px;
          }
        }

        .file-details {
          display: flex;
          align-items: center;
          width: 100%;

          .file-title {
            width: 50%;
            font-weight: 400;

            @media #{$small-and-down} {
              max-width: 160px;
            }

            @media #{$medium-and-up} {
              max-width: 300px;
            }

            @media #{$extra-large-and-up} {
              max-width: 500px;
            }
          }

          .date {
            width: 50%;
            max-width: 50%;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }

    .file-row {
      &:nth-last-child(-n + 4) {
        .options-list {
          bottom: 25px;
        }
      }

      &:nth-child(-n + 4) {
        .options-list {
          bottom: unset;
        }
      }
    }
  }

  &.choose-file-modal {
    .files-container {
      .file-tile {
        width: 130px;
        height: 130px;
        padding: 20px;
        border-radius: 0px;
        border: none;
        align-items: center;
        justify-content: center;
        display: flex;

        &:hover {
          background-color: #edebff;
        }

        &.with-thumnail {
          padding: 6px;
          width: 300px;
          height: auto;
          border-radius: 8px;
          border: 1px solid #edebff;
          background-color: unset !important;

          &:hover,
          &.active {
            border: 1px solid #5141e7;
          }

          .file-title {
            max-width: 200px;
          }
        }

        .file-title {
          max-width: 100px;
        }
      }
    }
  }
}

.upload-files-modal {
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 145px;
    border: 1px dashed rgba(0, 0, 0, 0.2);

    svg {
      display: none;
    }
  }

  .drop-title {
    font-size: 14px;
    color: #000;
  }

  .supported-files {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  svg.delete-icon {
    color: #726f8e;
  }

  .files-instruction {
    color: rgba(0, 0, 0, 0.4);
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
  }

  .file-preview {
    .section-divider {
      margin-top: 24px;
      margin-bottom: 24px;

      .attached-file {
        color: #000;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 169.231% */
        top: -13px;
      }
    }

    .preview-chip {
      color: #000;
      width: 100%;
      border: 0;
      height: auto;
      padding: 8px;
      font-size: 13px;
      min-width: 160px;
      background: #ffffff;
      font-weight: 400;
      line-height: 22px;
      border-radius: 8px;
      justify-content: space-between;
      border: 1px solid #edebff;
      display: inline-flex;
      align-items: center;

      p {
        margin-bottom: 0;

        span {
          opacity: 0.5;
        }
      }
    }
  }
}

textarea.code-interface {
  background-color: #f1f5f9 !important;
  border: none !important;
  resize: none;
}

.file-preview-header {
  margin-top: 8px;
  padding: 1px 100px;

  .title {
    font-size: 16px;
    color: #ffffff;
    @extend .long-text;
    max-width: 80%;
  }

  .button {
    .icon {
      color: #ffffff;
    }
  }
}

.file-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  width: 100%;

  img {
    max-width: calc(100% - 80px);
    max-height: calc(100% - 180px);
    height: 100%;
  }

  video {
    max-width: calc(100% - 80px);
    max-height: calc(100% - 180px);
    height: 100%;
  }

  iframe {
    border: none;
    width: 80%;
    height: 100%;
    // max-width: calc(100% - 80px);
    max-height: calc(100% - 80px);
  }
}

.multiselect-input {
  position: relative;
  background-color: #ffffff;
  border-radius: 7px;

  .controls {
    background-color: #ffffff;
  }

  input {
    &::placeholder {
      opacity: 0.8 !important;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      opacity: 0.8 !important;
      /* Firefox */
    }
  }
}

.chat-widget-list {
  .multiselect-input {
    width: 100%;
    max-width: 300px;
  }
}

.blur {
  filter: blur(3px);
}

.sort-asc,
.sort-des {
  position: relative;

  &::after {
    content: '';
    background: url(../../images/icons/sortingArrow.svg) no-repeat center;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transition-duration: 0.2s;
  }
}

.sort-asc {
  &::after {
    transform: translate(5px, -50%) rotateX(0deg);
  }
}

.sort-des {
  &::after {
    transform: translate(5px, -50%) rotateX(180deg);
  }
}

.account-details {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;

  position: relative;

  .sub-account-details {
    background-color: #f5f4ff;
    border: 1px solid #726f8e;
    border-radius: 8px;
    padding: 16px;
    position: absolute;
    width: calc(100% - 32px);
    box-shadow: 0px 4px 16px 0px #00000026;
  }
}

// .multiselect-input {
//   background-color: #ffffff;

//   .controls {
//     background-color: #ffffff;
//   }
// }

.change-logs {
  max-height: calc(100vh - 150px);
  overflow: auto;

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  #changelog {
    display: none;
  }

  p {
    margin-bottom: 24px;
    font-size: 16px;
  }

  h3 {
    font-size: 16px;
    margin-top: 24px;

    &#features,
    &#bug-fixes {
      margin-top: 0;
    }

    a {
      font-size: 22px;
      margin-right: 8px;
      pointer-events: none;
    }
  }

  ul {
    margin-left: 28px;

    li {
      a {
        pointer-events: none;
      }
    }
  }
}

.opportunity-chat-history {
  .chat-history {
    height: calc(100vh - 86px) !important;
    max-height: calc(100vh - 86px) !important;
    margin-bottom: 0;
  }
}

.contact-group {
  display: flex;
  .group-sidebar {
    background-color: #ffffff;
    height: 100%;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    border-right: 2px solid #edebff;
    position: relative;

    min-width: 250px;
    max-width: 700px;

    .sidebar-content {
      transition-timing-function: ease-out;
      transition: 200ms;
      transform: translateX(-255px);
      opacity: 0;

      &.show {
        transition-timing-function: ease-in;
        transition: 200ms;
        transform: translateX(0);
        opacity: 1;
      }
    }

    .sidebar-collapse {
      cursor: pointer;
      position: absolute;
      writing-mode: vertical-lr;
      color: #5141e7;
      font-weight: 500;
      font-size: 20px;
      top: 100px;
      text-transform: uppercase;
      rotate: 180deg;

      transition-timing-function: ease-out;
      transition: 200ms;
      transform: translateX(56px);
      opacity: 0;

      &.show {
        transition-timing-function: ease-in;
        transition: 200ms;
        transform: translateX(0);
        transition-delay: 150ms;
        opacity: 1;
      }
    }

    .app-sidebar-resizer {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 2px;
      cursor: col-resize;
      resize: horizontal;

      &:hover,
      &:focus {
        width: 3px;
        background: #5141e7;
      }
    }

    .button-between {
      .MuiButton-label {
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
      }
    }

    .custom-button {
      padding: 10px 12px 10px 12px;
      border-radius: 4px;
      margin-bottom: 1px;
      color: #000056;
      font-size: 14px;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: #edebff;
      }
    }
    .groups-tree {
      // width: 250px;
      height: calc(100vh - 340px);
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 4px;
      margin-right: -8px;

      .MuiTreeItem-root {
        &:nth-last-child(-n + 3) {
          .options-list {
            bottom: 20px;
          }
        }

        &:nth-child(-n + 3) {
          .options-list {
            bottom: unset;
          }
        }
        .MuiTreeItem-content {
          // flex-direction: row-reverse;
          padding: 10px 12px 10px 12px;
          border-radius: 4px;
          margin-bottom: 1px;
          // width: 230px;

          &:hover {
            background-color: #edebff;
          }

          .MuiTreeItem-label {
            color: #000056;
            font-size: 14px;
            width: calc(100% - 19px);

            .group-icon {
              width: 18px;
            }

            .contact-group-name {
              @extend .long-text;
              display: block;
              // max-width: 130px;
            }

            &:hover,
            &:focus {
              background-color: transparent;
            }
          }
        }

        &.Mui-selected {
          > .MuiTreeItem-content {
            .MuiTreeItem-label {
              background-color: transparent;

              &:hover {
                background-color: transparent;
              }
            }
          }
          &.active {
            > .MuiTreeItem-content {
              background-color: #edebff;
            }
          }
        }
      }
    }
  }
  .group-content {
    // overflow: auto;
    // max-height: calc(100vh - 141px);

    .group-breadcrumb {
      a.parent,
      .current {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        color: #000056;
        border-radius: 25px;
        padding: 3px 12px;
        &:hover,
        &.active {
          color: #000056 !important;
          background-color: #e6e4ff;
        }
      }

      .separator-icon {
        color: #000056;
      }

      .MuiBreadcrumbs-separator {
        margin: 0;
      }

      .current {
        font-weight: 500;
      }
    }
  }
}

.PhoneInput {
  display: flex;
  align-items: center;
}

.PhoneInputCountrySelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px;
}

.comments {
  height: calc(100vh - 145px);
  min-height: 300px;
  .comment-header {
    border-bottom: 1px solid #edebff;
  }
  .attachments {
    .attachment-file {
      // width: 230px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid #edebff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;

      .file-name {
        font-size: 12px;
        font-weight: 400;
        color: #000056;
        max-width: 300px;
        @extend .long-text;
      }

      .file-size {
        font-size: 11px;
        font-weight: 400;
        color: #726f8e;
      }

      &.upload {
        border: 1px dashed #726f8e;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  .comments-list {
    overflow: auto;
    // height: 100%;
    max-height: calc(100vh - 384px);

    .comment-box {
      padding: 12px;
      background-color: #ffffff;
      border-bottom: 1px solid #edebff;
      .tag {
        background-color: #5141e7;
        padding: 2px 4px 2px 4px;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 600;
        color: #ffffff;
        margin-left: 8px;
      }

      .attachments {
        .attachment-file {
          .file-name {
            max-width: 115px;
          }
        }
      }
    }
  }

  .add-comment {
    border: 1px solid #ccced5;
    // padding: 12px;
    border-radius: 4px;
    width: 100%;

    .form-control {
      border: unset !important;
      border-bottom: 1px solid #edebff !important;
    }

    .attachments {
      .attachment-file {
        .file-name {
          max-width: 100px;
        }
      }
    }
  }
}

.collapsible-sidebar {
  background-color: #ffffff;
  transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  position: relative;
  position: sticky;
  top: 0;

  .sidebar-content {
    transition-timing-function: ease-out;
    transition: 200ms;
    transform: translateX(-255px);
    opacity: 0;
    overflow: hidden;

    min-width: 400px;
    max-width: 700px;

    &.right {
      transform: translateX(255px);
    }

    &.show {
      transition-timing-function: ease-in;
      transition: 200ms;
      transform: translateX(0);
      opacity: 1;
    }
  }

  .sidebar-tabs {
    border-left: 1px solid #edebff;
    min-height: calc(100vh - 145px);

    .tab-buttons {
      &.active {
        background-color: #5d2be3;
        color: #fff;
      }
    }
  }

  .app-sidebar-resizer {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    cursor: col-resize;
    resize: horizontal;

    &.left {
      right: unset;
      left: 0;
      border-left: 3px solid #d8d8d8;
    }

    &:hover,
    &:focus {
      border-left: 3px solid #5141e7;
      transition: 50ms;
    }
  }
}

.date-picker-with-icon {
  display: flex;
  align-items: center;
  padding: 0 15px 0 0 !important;
  min-height: unset !important;

  .form-control {
    border: none !important;
  }
}

.email-composer {
  height: calc(100vh - 100px);
  .quill {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .ql-container {
      flex-grow: 1;
    }
  }

  .ql-attachment::before {
    content: '';
    background-image: url('../../images/icons/attach_file_toolbar.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
    display: inline-block;
  }

  .attachments {
    .attachment-file {
      // width: 230px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid #edebff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;

      .file-name {
        font-size: 12px;
        font-weight: 400;
        color: #000056;
        max-width: 200px;
        @extend .long-text;
      }

      .file-size {
        font-size: 11px;
        font-weight: 400;
        color: #726f8e;
      }

      &.upload {
        border: 1px dashed #726f8e;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}
.attachment-container {
  .attachments {
    .attachment-file {
      // width: 230px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid #edebff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;

      .file-name {
        font-size: 12px;
        font-weight: 400;
        color: #000056;
        max-width: 200px;
        @extend .long-text;
      }

      .file-size {
        font-size: 11px;
        font-weight: 400;
        color: #726f8e;
      }

      &.upload {
        border: 1px dashed #726f8e;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}

.email-listing {
  .email-title {
    
    @media #{$small-and-down} {
      max-width: 200px;
    }
  
    @media #{$medium-and-up} {
      max-width: 300px;
    }

    @media #{$large-and-up} {
      max-width: 330px;
    }
  
    @media #{$extra-large-and-up} {
      max-width: 430px;
    }

    @media #{$xx-large-and-up} {
      max-width: 750px;
    }
  }
}
