// Page Heading
.pageHeading{
  h1{
      font-size: 26px;
      color: $HeadingTextColor;
      font-weight: $fontWeightMedium;
  }
  p{
      font-size: 16px;
      color: $SubHeadingTextColor;
  }
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
a:focus{outline: none;}
.emailLoweCase{text-transform: lowercase !important;}
// Form Section
.formSection{
    .field-group{
        // margin-bottom: 15px;
        label{
            font-size: 15px;
            font-weight: $fontWeightMedium;
            width: $fullWidth;
            display: block;
            padding-bottom: 0px;
            margin-bottom: 5px;
            @media screen and (min-width:1280px) and (max-width:1350px){
                font-size: 13px; 
            }
            @include large{
                font-size: 17px; 
                margin-bottom: 10px;
            }
        }
        .noteContent{
            padding-bottom: 8px;
            display: block;
            font-size: 13px;
            font-style: italic;
        }
        input{
            width: $fullWidth;
            min-height: 50px;
            // background: $inputBackgroundColor;
            border: 1px transparent;
            color: #3F3E43;
            font-weight: 500;
            font-size: 15px;
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 0.35rem;
            font-weight: 400;
            letter-spacing: 0.3px;
            // box-shadow: 0px 0px 4px #dedede;
            border: 1px solid #CCCED5;
            &:focus{
                outline: 0;
                border: 1px solid $themeDarkBlue;
                box-shadow: 0 15px 30px #eaeaea;
                border-radius: 0.35rem;
            }
        }
        .form-control{
          background: #fafafa;
        }
        textarea{
            &:focus{
                outline: 0;
                border: 1px solid $themeDarkBlue;
                box-shadow: 0 15px 30px #eaeaea;
                border-radius: 0.35rem;
            }  
        }
        select{
            width: $fullWidth;
            min-height: 50px;
            background: $inputBackgroundColor;
            border: 1px transparent;
            color: #3F3E43;
            font-weight: 500;
            font-size: 15px;
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 0.35rem;
            font-weight: 400;
            letter-spacing: 0.3px;
            box-shadow: 0px 0px 2px #dedede;
            border: 1px solid transparent;
            -moz-appearance:none; /* Firefox */
            -webkit-appearance:none; /* Safari and Chrome */
            appearance:none;
            position: relative;
            background: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 12px;
            // &::after{
            //     content: '^';
            //     position: absolute;
            //     top: 0px;
            //     right: 0px;
            // }
            &:focus{
                outline: 0;
                border: 1px solid $themeDarkBlue;
                box-shadow: 0 15px 30px #eaeaea;
                border-radius: 0.35rem;
            }  
        }
        a{
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
        }
        .checkBoxColl {
            display: flex !important;
            position: relative;
            padding-left: 40px;
            margin-bottom: 12px !important;
            cursor: pointer;
            font-size: 16px;
            height: 25px;
            -webkit-user-select: none;
            user-select: none;
            color: #8C8B8F !important;
            font-weight: 400 !important;
            align-items: center;
            min-height: 18px;
            a{
                color: #FF456E;
                text-decoration: none;
                padding-left: 5px;
                font-weight: 400;
            }
            input{
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked{
                    
                }
            }
            .checkmark{
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #ffffff;
                border: 1px solid #707070;
                border-radius: 35%;
            }
        }
        &.fieldFroup30{
            flex: 0 0 30%;
        }
        &.field-group20{
            flex: 0 0 22%;
        }
        .eventCheckbox{
            min-height: 2.1rem;
            width: 12%;
        }
    }
  form{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include phone { 
          display: block;
       }
      .field-group{
          margin-bottom: 15px;
          label{
              font-size: 15px;
              font-weight: $fontWeightMedium;
              width: $fullWidth;
              display: block;
              padding-bottom: 0px;
              margin-bottom: 5px;
              @media screen and (min-width:1280px) and (max-width:1350px){
                  font-size: 13px; 
              }
              @include large{
                  font-size: 17px; 
                  margin-bottom: 10px;
              }
          }
          .noteContent{
              padding-bottom: 8px;
              display: block;
              font-size: 13px;
              font-style: italic;
          }
          input{
              width: $fullWidth;
              min-height: 48px;
              border: 1px transparent;
              color: #3F3E43;
              font-weight: 500;
              font-size: 15px;
              padding: 10px 20px;
              box-sizing: border-box;
              border-radius: 0.35rem;
              font-weight: 400;
              letter-spacing: 0.3px;
            //   border: 1px solid #CCCED5;
              &:focus{
                  outline: 0;
                  border: 1px solid $themeDarkBlue;
                  border-radius: 0.35rem;
              }
          }
          .form-control{
            background: #fafafa;
          }
          textarea{
              &:focus{
                  outline: 0;
                  border: 1px solid $themeDarkBlue;
                  box-shadow: 0 15px 30px #eaeaea;
                  border-radius: 0.35rem;
              }  
          }
          select{
              width: $fullWidth;
              min-height: 50px;
              background: $inputBackgroundColor;
              border: 1px transparent;
              color: #3F3E43;
              font-weight: 500;
              font-size: 15px;
              padding: 10px 20px;
              box-sizing: border-box;
              border-radius: 0.35rem;
              font-weight: 400;
              letter-spacing: 0.3px;
              box-shadow: 0px 0px 2px #dedede;
              border: 1px solid transparent;
              -moz-appearance:none; /* Firefox */
              -webkit-appearance:none; /* Safari and Chrome */
              appearance:none;
              position: relative;
              background: transparent;
              background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
              background-repeat: no-repeat;
              background-position-x: 100%;
              background-position-y: 12px;
              // &::after{
              //     content: '^';
              //     position: absolute;
              //     top: 0px;
              //     right: 0px;
              // }
              &:focus{
                  outline: 0;
                  border: 1px solid $themeDarkBlue;
                  box-shadow: 0 15px 30px #eaeaea;
                  border-radius: 0.35rem;
              }  
          }
          a{
              text-decoration: none;
              font-size: 16px;
              font-weight: 500;
          }
          .checkBoxColl {
              display: flex !important;
              position: relative;
              padding-left: 40px;
              margin-bottom: 12px !important;
              cursor: pointer;
              font-size: 16px;
              height: 25px;
              -webkit-user-select: none;
              user-select: none;
              color: #8C8B8F !important;
              font-weight: 400 !important;
              align-items: center;
              min-height: 18px;
              a{
                  color: #FF456E;
                  text-decoration: none;
                  padding-left: 5px;
                  font-weight: 400;
              }
              input{
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                  &:checked{
                      
                  }
              }
              .checkmark{
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 25px;
                  width: 25px;
                  background-color: #ffffff;
                  border: 1px solid #707070;
                  border-radius: 35%;
              }
          }
          &.fieldFroup30{
              flex: 0 0 30%;
          }
          &.field-group20{
              flex: 0 0 22%;
          }
          .eventCheckbox{
              min-height: 2.1rem;
              width: 12%;
          }
      }
      .field-group-flex{
          display: flex;
          justify-content: space-between;
          width: $fullWidth;
          align-items: center;
          @include phone { 
              display: block;
          }
          &.text-center{
              justify-content: center;
          }
          .conditionsterms{
              .conditionGroup{
                  width: 100%;
                  label{
                      display: block;
                      color: $checkboxLabel;
                      margin-bottom: 0px;
                      .MuiIconButton-root{
                          padding: 3px !important;
                      }
                      span{
                          font-size: 17px;
                          @include phone { 
                              font-size: 14px;
                           }
                      }
                      a{
                          color: $Linkcolor;
                          text-decoration: none;
                      }
                  }
              }
              @include phone { 
                  margin-bottom: 0px;
              }
          }
          .submitForm{
              button{
                  text-transform: uppercase;
                  padding: 20px 40px;
                  width: 100%;
                  font-size: 17px;
              }
          }
      }
  }
  .bothfiledGroup{
      display: flex;
      justify-content: space-between;
      width: 100%;
  }
}
.registerFormInner{
.formSection{
  .loginForgotP{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      a{
          color: #8C8B8F;  
          font-weight: 400 !important;
          &:hover{
              color: $Linkcolor;
          }
      }
      .checkBoxColl{margin-bottom: 0px !important;}
  }
}  
}
/* select{
  width: $fullWidth;
  min-height: 50px;
  background: $inputBackgroundColor;
  border: 1px transparent;
  color: #3F3E43;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 0.35rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  box-shadow: 0px 0px 4px #dedede;
  border: 1px solid transparent;
  &:focus{
      outline: 0;
      border: 1px solid $themeDarkBlue;
      box-shadow: 0 15px 30px #eaeaea;
  }  
} */

.field-group{
  .formDate{
      .react-datepicker-wrapper{
          input{
              background-image: url('../assets/images/datePicker.png');
              background-repeat: no-repeat;
              background-position: top 13px right 15px;
              cursor: pointer; 
          }
      }
  }
  .formTime{
      .react-time-picker__wrapper{
          .react-time-picker__inputGroup{
              background-image: url('../assets/images/timePicker.png');
              background-repeat: no-repeat;
              background-position: top 13px right 15px;
              cursor: pointer; 
          }
      }
  }
  .formIcon{
      position: relative;
      &::after{
          display: none;
          content: "";
          position: absolute;
          top: 14px;
          right: 16px;
          width: 22px;
          height: 22px;
          cursor: pointer;
          background-repeat: no-repeat;
      }
      .react-datepicker-wrapper{
          width: 100%;
      }
  }
  .formDate{
      &::after{
         background-image: url('../assets/images/calendarForm.png'); 
      }
  }
  .formTime{
      &::after{
          background-image: url('../assets/images/clockForm.png'); 
       }
       div{
           width: 100%;
       }
  }
}
.page-card .mangepage-page-card iframe {
     position: relative;
     top: -55px;
     bottom: 55px;
     min-height:355px;
  }
.inputField .selectButton {
  position: relative;
  background: #fafafa;
  border-radius: 7px;
}
.inputField .selectButton select.form-control {
  background: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
}
.inputField .form-control {
  background: #fafafa;
  border-radius: 7px;
  border: 1px solid transparent;
  color: #3F3E43;
  font-size: 15px;
  font-weight: 500;
  min-height: 55px;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;

  &:disabled{
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
  }
}
label{
  color: #3F3E43;
  font-size: 15px;
  font-weight: $fontWeightMedium;
//   width: $fullWidth;
  display: block;
  padding-bottom: 0px;
  margin-bottom: 5px;
}
.fullWidth{width: $fullWidth;}
/* On mouse-over, add a grey background color */
.checkBoxColl:hover input ~ .checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.checkBoxColl input:checked ~ .checkmark {
    background-color: $themelightYellow;
    border-color: #5B33F1;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkBoxColl input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkBoxColl .checkmark:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid #5B33F1;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
// Buttons

button{outline: 0 !important;}
select{outline: 0;}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.themeBlueButton{
  background-color: $themeDarkBlue;
  border: 1px solid $themeDarkBlue;
  border: none !important;
  font-weight: $fontWeightMedium;
  color: $themelightYellow;
  box-sizing: border-box;
  line-height: 21px !important;
  letter-spacing: 1.0px !important;
  font-family: $FontRubik;
  min-height: 60px;
  font-size: 15px !important;
  border-radius: 10px !important;
  cursor: pointer;
  padding: 10px 30px!important;
  min-height: 50px !important;
  text-transform: capitalize !important;
  @include phone{
      min-height: 50px !important;
  }
}


.themeBlueSmallButton{
  background-color: $themeDarkBlue;
  border: 1px solid $themeDarkBlue;
  border: none !important;
  font-weight: $fontWeightMedium;
  color: $themelightYellow;
  box-sizing: border-box;
  line-height: 21px !important;
  letter-spacing: 1.0px !important;
  font-family: $FontRubik;
  font-size: 14px !important;
  border-radius: 10px !important;
  cursor: pointer;
  padding: 6px 10px!important;
  min-height: 30px!important;
  min-width: 130px;
  text-transform: capitalize !important;
  @include phone{
      min-height: 50px !important;
  }
}
.outlineButton{
  color: #908E9A !important;
  font-weight: $fontWeightNormal;
  border: 1px solid #D8D8D8 !important;
  font-family: $FontRubik;
  letter-spacing: 1.0px !important;
  display: inline-block;
  border-radius: 10px !important;
  letter-spacing: 0.5px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px !important;
  min-height: 50px !important;
  @include phone{
      font-size: 15px;
  }
  @include large{
      min-height: 50px !important;
      padding: 10px 25px !important;
  }
  @media screen and (min-width:1025px) and (max-width:1400px){
      font-size: 15px;
  }
}
.largeBlueThemeBttn {
  background: #5B33F1;
  width: 100%;
  border: 0;
  color: #fff;
  text-transform: capitalize;
  min-height: 50px;
  border-radius: 10px;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 70px;
  line-height: 35px;
  @include phone{
      min-height: 50px !important;
  }
}
.largeOutlineThemeBttn {
  height: 50px;
  font-size: 15px;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  color: #908E9A;
  @include phone{
      height: 55px !important;
  }
}
.outlineThemeButton {
  border: 3px solid #FF456E;
  font-size: 22px;
  border-radius: 18px;
  text-align: center;
  color: #67557D;
  padding: 10px 25px;
  background-color: #fff;
  height: 62px;
  cursor: pointer;
  @include large{
      min-width: 220px;
      height: 92px;
      width: 165px;
      font-size: 14px;
  }
  @include phone{
      height: 50px !important;
  }
}
.saveButton{
  background: $themeOrangeColor;
  color: #fff !important;
  border: 0 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  padding: 8px 30px !important;
  border-radius: 5px !important;
  margin-left: 15px !important;
  letter-spacing: 1px !important;
  min-height: 50px;
  text-decoration: none !important;
  font-family: $FontRubik;
  cursor: pointer;
  @include phone{
      font-size: 15px !important;  
      min-height: 45px;
      padding: 5px 30px !important;
  }
  @include LargeScreens{
      min-height: auto !important;  
      font-size: 23px !important;  
      padding: 12px 30px !important;    
  }
}
.disablegreyButton{
  background: #d4d4d4 !important;
  color: #000 !important;
  border: 0 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding: 8px 30px !important;
  border-radius: 5px !important;
  margin-left: 15px !important;
  letter-spacing: 1px !important;
  min-height: 50px;
  text-decoration: none !important;
  font-family: $FontRubik;
  cursor: pointer;
  @include phone{
      font-size: 15px !important;  
      min-height: 45px;
      padding: 5px 30px !important;
  }
  @include LargeScreens{
      min-height: auto !important;  
      font-size: 23px !important;  
      padding: 12px 30px !important;    
  }
}
.MobileSaveButton{
  background: $themeOrangeColor;
  color: #fff;
  border: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 9px;
  border-radius: 50%;
  margin: 0px;
  letter-spacing: 1px;
  width: 36px;
  height: 36px;
  text-decoration: none;
  font-family: $FontRubik;
  display: block;
  box-sizing: border-box;
  i{
      color:#fff;
  }
  @include LargeScreens{
      display: none;
  }
}
.error_mesage{
  margin: 0px;
  font-size: 13px;
  color: #ff0000;
}
.p-3 {
  padding: 1rem !important;
}
.mr-3, .mx-3 {
  margin-right: 1rem !important;
}
.mr-2{
  margin-right: 10px !important;
}
.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}
.mt-5, .my-5 {
  margin-top: 3rem !important;
}
.mt-0{margin-top: 0px !important;}
.mb-2{margin-bottom: 10px !important;}
.mb-3{margin-bottom: 15px !important;}
.paddingLeft25{padding-left: 25px !important;}
.pr-2{padding-right: 10px !important;}
.pt-0{padding-top: 0px !important;}
.p-0{padding: 0px !important;}
.paddingDB{padding: 0px 40px 30px 40px !important;}
.m-0{margin: 0px !important;}
.text-center{text-align: center;}
.text-right{text-align: right;justify-content: flex-end;}
.justify-content-center{justify-content: center !important;}
.justify-content-start{justify-content: flex-start !important;}
.justify-content-end{display:flex; justify-content: flex-end !important;}
.pr-0{padding-right: 0px !important;}
img {
  vertical-align: middle;
  border-style: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
// table 3 verticle dots
.tableDots{
  background: transparent;
  border: 0;
  padding: 0px 4px;
  .tableDotsExpo{
      background: #ffffff;
      border-radius: 50%;
      border: 2px solid #000000;
      display: block;
      height: 3px;
      margin-bottom: 3px;
      width: 3px;
  }
}
.tableDotMore{
  &:hover{
      .contacteditPopup{
          display: block;
      }
  }
}

// Pagination
.tablePagination{
  margin-top: 0px;
  .paginationNav{
      .pagination{
          display: flex;
          padding-left: 0;
          list-style: none;
          border-radius: 0.35rem;
          justify-content: flex-end;
          align-items: center;
          margin: 0;
          li{
              &.page-item{
                  margin: 0 2px;
                  a{
                      position: relative;
                      display: flex;
                      padding: 0px;
                      margin-left: -1px;
                      line-height: 1.25;
                      background-color: #fff;
                  }
              }
              &.prev{
                  a{
                      color: #959595;
                      border: 0;
                      font-weight: 500;
                      padding: 8px 12px;
                  }
              }
              &.next{
                  a{
                      color: #5b33f1;
                      border: 0;
                      font-weight: 500;
                      padding: 8px 12px;
                      @include phone{
                          padding: 8px 6px;
                      }
                  }
              }
              a{
                  border: 1px solid #E7E7E7;
                  display: flex;
                  width: 37px;
                  height: 37px;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  margin-left: 0;
                  color: #959595;
                  font-weight: 500;
                  text-decoration: none;
                  @include phone{
                      width: 32px;
                      height: 32px; 
                  }
              }
              &.active{
                  a{
                      background: #5b33f1;
                      border: 1px solid #5b33f1;
                      color: #fff;
                  }
              }
          }
      }
  }
}
ul.pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  li{
      padding: 0px 7px;
      list-style-type: none;
      a{
          color: #333;
          text-decoration: none;
      }
      &.active{
          a{
              background: #5b33f1;
              border: 1px solid #5b33f1;
              color: #fff;
              display: flex;
              width: 37px;
              height: 37px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              margin-left: 0;
              font-weight: 500;
              text-decoration: none;
          }
      }
      &.disabled{
          a{
              color: #959595;
              border: 0;
              font-weight: 500;
          }
      }
  }
}
// Responsive Display Property
.mobile-None{
  @media (max-width:767px){
      display: none !important;
  }
  @media screen and (min-width:1275px) and (max-width:2100px){
      display: flex !important;
  }
}
.desktop-None{
  @media screen and (min-width:768px) and (max-width:1274px){
      display: none !important;
  }
  @media screen and (min-width:1275px){
      display: none !important;
  }
}

// PageFooter css
.footer_main{
  text-align: center;
  background: #3F3E43;
  padding: 15px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  @include phone{
      padding: 15px;
  }
  .footer_logo{
      img{
          width: 120px;
          @include phone{
              width: 130px;
          }
      }
  }
}

// Image Responsive
.img-fluid {
  max-width: 100%;
  height: auto;
}


.checkBoxColl {
  display: flex !important;
  position: relative;
  padding-left: 40px;
  margin-bottom: 0px !important;
  cursor: pointer;
  font-size: 16px;
  height: 25px;
  -webkit-user-select: none;
  user-select: none;
  color: #8C8B8F !important;
  font-weight: 400 !important;
  align-items: center;
  min-height: 18px;
  box-sizing: border-box;
  a{
      color: #FF456E;
      text-decoration: none;
      padding-left: 5px;
      font-weight: 400;
  }
  input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked{
          
      }
  }
  .checkmark{
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #ffffff;
      border: 1px solid #707070;
      border-radius: 35%;
      box-sizing: border-box;
  }
  
}

// loader
.loader-main-hp{
  margin: 0px;
  text-align: center;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 86, 0.3);
  height: 100%;
  z-index: 9999999;
  overflow: hidden;
}


// Stepper Customize
// .MuiStepIcon-root {
//   color: #fff;
//   stroke-width: 2px;
//   stroke: $themeOrangeColor;
//   background-color: #fff;
//   border-radius: 50%;
//   fill: #fff;
//   width: 22px;
//   height: 22px;
//   z-index: 999;
// }
// .MuiStepConnector-alternativeLabel {
//   top: 12px;
//   left: calc(-50% + 10px) !important;
//   right: calc(50% + 10px) !important;
//   position: absolute;
// }
// .MuiStepConnector-lineHorizontal{
//   border-top-style: dashed !important;
// }
// .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text{display: none;}
// .MuiStepConnector-line {
//   display: block;
//   border-color: #D2D2D2;
// }
// .MuiStepConnector-lineHorizontal {
//   border-top-style: dotted;
//   border-top-width: 1px;
// }
// .MuiStepConnector-alternativeLabel {
//   top: 12px;
//   left: calc(-50% + 9px);
//   right: calc(50% + 11px);
//   position: absolute;
// }
// .MuiStepIcon-root.MuiStepIcon-completed{z-index: 999;color: #fff !important;}
// .MuiStepLabel-alternativeLabel .MuiStepIcon-text{display: none;}
// .MuiStepIcon-root circle{color: #ffffff;}
// .projectSteps .MuiStep-root.MuiStep-completed svg path{fill: #fff !important;}
// .MuiStepLabel-label.MuiStepLabel-completed {
//   color: $themeOrangeColor;
//   font-weight: 500 !important;
// }
// .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
//   color: #02000A;
//   font-weight: 500 !important;
//   margin-top: 16px;
//   text-align: center;
//   font-size: 15px;
//   @include phone{
//       font-size: 12px;
//   }
// }
.success_message{
  color: green;
}
.react-form-builder .react-form-builder-preview label{text-align: left;}
.react-confirm-alert-overlay{background: rgba(0, 0, 0, 0.68) !important;}
.react-confirm-alert{
  .react-confirm-alert-body{
      position: relative;
      width: 50%;
      margin: auto;
      text-align: center;
      padding-bottom: 85px;
      border-radius: 5px;
      min-width: 310px;
      box-sizing: border-box;
      max-width: 310px;
      @include phone{
          width: 65%;
          min-width: auto;
          max-width: auto;
      }
      h1{
          font-size: 20px;
          color: #000;
          margin-bottom: 10px;
          text-align: center;
      }
      .react-confirm-alert-button-group{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          button{
              width: 50%;
              border-radius: 0px !important;
              margin-right: 0px !important;
              &:first-child{
                  background: #dedede !important;
                  color: #000 !important;
                  font-size: 18px;
                  padding: 9px 15px;
                  border-bottom-left-radius: 5px !important;
              }
              &:last-child{
                  background: $themeOrangeColor;
                  color: #fff !important;
                  font-size: 18px;
                  padding: 9px 15px; 
                  border-bottom-right-radius: 5px !important;
              }
          }
      }
  }
  .custom-confirm{
      position: relative;
      width: 50%;
      margin: auto;
      text-align: center;
      padding-bottom: 85px;
      border-radius: 5px;
      min-width: 310px;
      box-sizing: border-box;
      max-width: 310px;
      font-family: Arial,Helvetica,sans-serif;
      //width: 400px;
      padding: 30px;
      //text-align: left;
      background: #fff;
     // border-radius: 10px;
      //box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
      color: #666;
      @include phone{
          width: 65%;
          min-width: auto;
          max-width: auto;
      }
      h1{
          font-size: 20px;
          color: #000;
          margin-bottom: 10px;
          text-align: center;
      }
      .react-confirm-alert-button-group{
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          button{
              width: 50%;
              border-radius: 0px !important;
              margin-right: 0px !important;
              &:first-child{
                  background: #dedede !important;
                  color: #000 !important;
                  font-size: 18px;
                  padding: 9px 15px;
                  border-bottom-left-radius: 5px !important;
              }
              &:last-child{
                  background: $themeOrangeColor;
                  color: #fff !important;
                  font-size: 18px;
                  padding: 9px 15px;
                  border-bottom-right-radius: 5px !important;
              }
          }
      }
  }
}


.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-bottom: 20px !important;
}

// Agenda Filter
.agendaFilter{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include phone{
      display: block;
  }
  .formSection{
      padding: 0px;
      @include phone{
          padding-bottom: 0px;
      }
      .outlineButton{
          min-width: 200px;
      }
  }
  .themeBlueButton{
      margin-bottom: 15px;
      @include phone{
          width: 100%;
      }
  }
}

.editingDashboard .templateNameField .eventFields{
  padding-left: .9rem;
}

.editingDashboard .templateNameField .redirect-url{
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-top: .5rem;
  min-height: 3rem;
}

.event_page_listing{
    .eventTableInner{
        .table{
            thead{
                tr{
                    th{
                        &:first-child{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.page_url_input{
    .redirect-url{
        border-radius: 0.35rem;
        font-weight: 400; 
        letter-spacing: 0.3px;
        box-shadow: 0px 0px 4px #dedede;
        border: 1px solid transparent;
        font-size: 15px;
        padding: 10px 20px;
        background: #fafafa;
        box-sizing: border-box;
        min-height: 50px !important;
        display: flex;
        align-items: center; 
        @media (min-width: 1450px){
            min-height: 60px !important;
        }
    } 
}
.register_pageCheckbox{
    @include phone{
        padding-left: 0px !important;
    }
    .select_register456{ 
        display: flex; 
        align-items: center;
        .checkBoxColl{   
            box-shadow: none !important;
            width: 30px !important; 
            margin-right: 10px;
        } 
    } 
}

.event_page_ids{
    .field-group{
        &:nth-child(3){
            margin-left: 15px;
            @include phone{
                margin-left: 0px;
            }
            @include tabletPortrait{
                margin-left: 0px; 
            }
        }
        &.fieldFroup20{
            &:nth-child(2){
                @media screen and (min-width:1024px) and (max-width:1200px) {
                    flex: 0 0 30%; 
                }
                @include tabletPortrait{
                    flex: 0 0 45%;
                }
            }
            @include tabletPortrait{
                flex: 0 0 45%;
            }
        }
    }
}
#pageDesignEditor{
    @include tabletPortrait{
        overflow: auto;
    }
}
.audienceTable{
    .checkedAllSection{
        padding-left: 7px;
    }
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"]{
display: none !important;
}