// Events Table
.jTime-clockIcon {
  display: none;
}
.myEventTable {
  margin-top: 20px;
  margin-bottom: 0px;
  .eventTableInner {
    .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #858796;
      border-collapse: collapse;
      box-sizing: border-box;
      thead {
        tr {
          display: flex;
          margin-bottom: 0px;
          th{
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            max-width: 100%;
            min-width: 100px;
            text-align: left;
          }
          th {
            border: 0;
            color: #000056;
            font-size: 14px;
            font-weight: 600;
            padding: 14px;
            &:first-child {
              @include phone {
                max-width: auto !important;
                min-width: auto !important;
              }
            }
            &:nth-child(2) {
              @include phone {
                display: none;
              }
            }
            &:nth-child(3) {
              @include phone {
                display: none;
              }
            }
            &:nth-child(4) {
              @include phone {
                display: none;
              }
            }
            &:last-child{
              text-align: right;
            }
            .tablesettingBtn {
              background: transparent;
              border: 1px solid #dacecb;
              display: flex;
              height: 40px;
              align-items: center;
              justify-content: center;
              width: 40px;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }
      }
      tbody {
        tr {
          background: #ffffff;
          border-radius: 10px;
          padding: 0px;
          display: flex;
          margin-bottom: 10px;

          &:hover{
            box-shadow: 0px 4px 28px rgba(0, 0, 86, 0.23);
          }
          td{
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            max-width: 100%;
            min-width: 100px;
          }
          td {
            border: 0;
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            vertical-align: middle;
            // &.tableDotMore{
            //   max-width: 42px;
            //   min-width: 42px;
            // }
            &.audience_tableDots{
              max-width: initial;
              min-width: auto;
              display: flex;
              justify-content: flex-end;
            }
            &:last-child{
              text-align: right;
            }
            &.inviteContactsTd {
              align-items: center;
              display: flex;
              padding: 0px;
              min-width: 130px;
              justify-content: flex-end;
              @media screen and (min-width: 1250px) and (max-width: 1290px) {
                min-width: 120px;
              }
              @include phone {
                min-width: auto;
              }
              .eventListImage34 {
                display: flex;
                align-items: center;
              }
              .tableInvite {
                display: flex;
                margin-left: -40px;
                width: 35px;
                height: 35px;
                align-items: center;
                border: 2px solid #fff;
                border-radius: 50%;
                box-sizing: content-box;
                &:first-child {
                  margin-right: 25px;
                }
                img {
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 0px 0px 3px lightgrey;
                  margin-left: 0px;
                }
              }
              .inviteMore {
                margin-left: -20px;
                display: flex;
                width: 35px;
                height: 35px;
                box-sizing: content-box;
                background: #ede83a;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                color: #3d3c2b;
                border: 3px solid #ffffff;
                font-size: 10px;
              }
              a {
                color: $backgroundWhite;
                font-size: 14px;
                font-weight: 500;
                margin-left: 10px;
                text-decoration: underline;
                min-width: 80px;
                @include phone {
                  min-width: 60px;
                }
              }
            }

            .eventName {
              text-transform: capitalize;
            }
            &:first-child {
              display: flex;
              align-items: center;
              @include phone {
                max-width: auto !important;
                min-width: auto !important;
              }
              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                @include phone {
                  display: none;
                }
              }
            }
            &:nth-child(2) {
              @include phone {
                display: none;
              }
            }
            &:nth-child(3) {
              @include phone {
                display: none;
              }
            }
            &:nth-child(4) {
              @include phone {
                display: none;
              }
            }
            &:last-child {
              width: auto;
            }
            &.tableUserInfo {
              .checkBoxColl {
                margin-bottom: 0px !important;
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
}
.createEventButton {
  margin-top: 50px;
  button {
    min-width: 160px !important;
    font-size: 16px;
    @include LargeScreens {
      font-size: 28px !important;
      min-height: auto !important;
      padding: 25px 50px !important;
    }
  }
}
.filterSearchBar {
  .filterControler {
    .eventControlList {
      .eventControlBtn {
        border-radius: 7px !important;
      }
    }
    .eventControlGrid {
      .eventControlBtn {
        border-radius: 7px !important;
      }
    }
  }
}
.eventOnlyList {
  padding-bottom: 120px !important;
  .table {
    tbody {
      tr { 
        td {
          .eventName { 
            cursor: pointer;
            &:hover {
              color: $sidebarBackground;
            }
          }
        }
      }
    }
  }
}
.dashboardMiddleArea {
  .eventGridBoxes {
    table {
      tbody {
        tr {
          td {
            &:nth-child(1) {
              max-width: 100% !important;
              min-width: 100% !important;
            }
            &:nth-child(2) {
              max-width: 100% !important;
              min-width: 100% !important;
            }
            &:nth-child(4) {
              max-width: 100% !important;
              min-width: 100% !important;
            }
          }
        }
      }
    }
  }
}
.domainLinkurl {
  color: $themeDarkBlue; 
  word-break: break-all; 
  text-decoration: none; 
}
.inviteNowEvent { 
  background-color: $themeOrangeColor;
  color: $backgroundWhite;
  border: none;
  padding: 8px;
  font-size: 15px !important;
  font-weight: 500 !important;
  width: 31px !important;
  display: flex;
  min-width: 31px !important;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none !important;
  border-radius: 4px;
}
// .contactTableInner {
//   .table {
//     thead {
//       tr {
//         th {
//           &:first-child {
//             max-width: 260px !important;
//             min-width: 260px !important;
//           }
//           &:nth-child(2) {
//             max-width: 250px !important;
//             min-width: 250px !important;
//           }
//           &:nth-child(3) {
//             max-width: 250px !important;
//             min-width: 250px !important;
//           }
//           &:nth-child(5) {
//             justify-content: flex-end;
//             min-width: 150px !important;
//           }
//         }
//       }
//     }
//     tbody {
//       tr {
//         td {
//           &:first-child {
//             max-width: 260px !important;
//             min-width: 260px !important;
//           }
//           &:nth-child(2) {
//             max-width: 250px !important;
//             min-width: 250px !important;
//           }
//           &:nth-child(3) {
//             max-width: 250px !important;
//             min-width: 250px !important;
//           }
//         }
//       }
//     }
//   }
// }
.eventRegisteredUser {
  .table {
    thead {
      tr {
        th {
          &:first-child {
            max-width: 50px !important;
            min-width: 50px !important;
          }
          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            max-width: 50px !important;
            min-width: 50px !important;
            padding-left: 12px !important;
          }
          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.editTempDesign {
  @include tabletPortrait {
    width: 100%;
    overflow: auto;
  }
}
.editableDomainName {
  display: flex;
  align-items: center;
  span {
    background: #fafafa;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px #dedede;
    position: relative;
    left: -3px;
  }
}
.eventListBoxes {
  .eventTableInner {
    table {
      tbody { 
        border-spacing: 0px !important;
        tr {
          td {
            h6 {
              display: none;
            }
          }
        }
      }
    }
    &.eventOnlyList{
      table{
        thead{
          tr{
            th{
              @media screen and (max-width:1650px) {
                min-width: 80px;
              }
            }
          }
        }
        tbody{
          tr{
            td{
              &.location_table{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.event_list_bars{
                width: 30px;
                min-width: 28px;
                max-width: 28px;
              }
              @media screen and (max-width:1650px) {
                min-width: 80px;
              }
            }
          }
        }
      }
    }
  }
}
.table tbody + tbody {
  border-top: none !important;
}
.eventGridBoxes {
  .eventTableInner {
    table {
      thead {
        display: none;
      }
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px;
        @include phone {
          padding: 0px;
          display: block;
        }
        @include tabletPortrait {
          padding: 0px;
          display: block;
        }
        @include large {
          justify-content: flex-start;
        }
        tr {
          flex: 0 0 49%;
          display: flex !important;
          flex-direction: column;
          margin-bottom: 25px !important;
          padding: 15px 10px !important;
          position: relative;
          @include large {
            flex: 0 0 30%;
            margin: 0px 15px;
          }
          td {
            padding: 8px 10px 2px 0px !important;
            display: flex;
            &:nth-child(3) {
              max-width: 100% !important;
              min-width: 100% !important;
            }
            h6 {
              font-weight: 500;
              color: #333;
              min-width: 100px !important;
              font-size: 13px;
            }
            .eventName {
              margin-left: 0px !important;
            }
            &.inviteContactsTd {
              justify-content: flex-start !important;
              flex-direction: column;
              min-height: 71px;
              h6 {
                width: 100%;
              }
              .eventListImage34 {
                display: flex;
                margin-top: 10px;
                width: 100%;
                align-items: center;
                justify-content: flex-end;
                min-height: 41px;
                position: absolute;
                bottom: 22px;
                right: 10px;
                .inviteNowEvent {
                  margin-left: 0px !important;
                  margin-right: 20px !important;
                }
              }
            }
            &.tableDotMore {
              position: absolute;
              bottom: 24px;
              right: 0px;
              width: 30px !important;
              justify-content: flex-end;
            }
            &:first-child {
              .tableEventListImage {
                margin-bottom: 20px;
                img {
                  width: 50px;
                  height: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.react-time-picker {
  .react-time-picker__wrapper {
    border: none;
    background-color: #fafafa !important;
    .react-time-picker__clear-button {
      display: none !important;
    }
  }
  .react-time-picker__inputGroup {
    display: flex;
    align-items: center;
    background-color: #fafafa !important;
    .react-time-picker__inputGroup__input {
      padding: 5px !important;
      min-width: 40px !important;
      text-align: center;
      box-shadow: none;
      border-radius: 0px;
      max-width: 60px;
      border-radius: 5px;
    }
  }
}
.eventDemoLogin {
  @include phone {
    padding-top: 0px !important;
  }
  .ViewGuestPrivewMain {
    .registorEditiorSection {
      @include phone {
        top: 0px;
      }
      @include tabletPortrait {
        width: 100% !important;
      }
      .banner_content {
        @include tabletPortrait {
          display: block;
        }
        padding: 25px 50px;
        @include phone {
          padding: 0px;
        }
        .banner_heading {
          h2 {
            @include tabletPortrait {
              margin-bottom: 20px;
            }
          }
        }
        .banner_form {
          width: 60% !important;
          margin: auto;
          padding: 30px 25px;
          @include phone {
            width: 100% !important;
            padding: 30px 15px;
          }
          h4 {
            font-size: 22px;
          }
          // .formSection{
          //     .field-group{
          //         input{
          //             min-height: 40px;
          //         }
          //         .largeBlueThemeBttn{
          //             height: 40px;
          //             min-height: 40px;
          //         }
          //     }
          // }
        }
      }
    }
  }
}
.demoEditDashboard {
  .ViewGuestPrivewMain {
    .registorEditiorSection {
      width: 100% !important;
    }
  }
}

// Event Login Clock
.eventLoginClock {
  .offerLimit {
    margin-bottom: 20px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        list-style: none;
        background-color: $themeOrangeColor;
        color: #fff;
        flex: 0 0 23%;
        border-radius: 2px;
        padding-bottom: 5px;
        padding-top: 3px;
        p {
          font-size: 16px;
          @media screen and (min-width: 1300px) and (max-width: 1366px) {
            font-size: 14px;
          }
          @media screen and (min-width: 1200px) and (max-width: 1299px) {
            font-size: 13px;
          }
          @media screen and (min-width: 1100px) and (max-width: 1199px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.createEventMarginTop {
  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    margin-top: 15px !important;
  }
}
.createYourEvntBttn {
  button {
    min-width: 240px;
  }
}
.tabletResponsiveNone {
  @include tabletPortrait {
    display: none;
  }
}
.eventLoginClock {
  margin-top: 0px !important;
  .offerLimit {
    margin-bottom: 0px !important;
    padding: 20px;
    background-color: #05005d;
    ul {
      justify-content: center;
      li {
        flex: initial;
        margin: 0px 10px;
        min-width: 80px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        background-color: #ffffff !important;
        h2 {
          color: #6957ff;
        }
        p {
          color: #6957ff;
        }
      }
    }
  }
}

.tdAlert {
  color: red !important;
}

.eventRegisteredUser {
  table {
    tbody {
      tr {
        td {
          &:last-child {
            width: 100% !important;
          }
        }
      }
    }
  }
}
.importContactss {
  display: flex;
  label {
    width: auto !important;
  }
}
.viewDetailsLists {
  padding: 20px;
  .agendaViewtable {
    max-height: 275px;
    overflow: auto;
    ul {
      li {
        list-style-type: none;
        display: flex;
        h4 {
          color: #333;
          font-weight: 500;
          padding-bottom: 12px;
          margin: 0px;
          font-size: 16px;
          flex: 0 0 35%;
        }
        span {
          color: #707070;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}


.no_data_found{
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
  }
}
.edit_event_form_page32{
  .chooseEmail_fields32{
    @include phone{
      display: block;
    }
    .fieldFroup20{
      @media screen and (max-width:1440px) {
        flex: 0 0 23%;
     }
      @media screen and (max-width:1300px) {
         flex: 0 0 30%;
      }
      @media screen and (max-width:1024px) {
        flex: 0 0 28%;
      }
      @include phone{
        flex: 0 0 100%;
      }
     &:last-child{
      @media screen and (max-width:991px) {
        flex: 0 0 38%;
      }
      @include phone{
        flex: 0 0 100%;
      } 
     }
    } 
  }
}