.emailTemplateDashboardInbox {
  .ETdetails_section {
    .react-swipeable-view-container {
      padding-top: 0px;
      .MuiBox-root {
        padding: 0px;
      }
    }
  }
  .ETBoxStyle {
    border: 1px solid #e6e6e6;
    overflow: hidden;
    border-radius: 35px;
    min-height: 400px;
    margin-top: 20px;
  }
}
.emailChatBox {
  padding: 0px 15px;
  .eventTableInner {
    .table {
      tr {
        td {
          &.tableDotMore {
            min-width: 85px;
            display: flex;
            justify-content: center;
          }
        }
        .lefRightAction {
          display: flex;
          margin-left: 10px;
          justify-content: space-between;
          .fa {
            font-size: 20px;
            color: #02000a;
            cursor: pointer;
            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
// Email DashboardTabs
.emailDashboardTabs {
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiTabs-root {
    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        .MuiButtonBase-root {
          text-transform: capitalize;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          padding: 23px 35px;
          box-sizing: border-box;
          color: #7b7b82;
          &.Mui-selected {
            background: #fff;
            color: #281374;
            border-top-right-radius: 35px;
            border-top-left-radius: 35px;
          }
        }
      }
      .MuiTabs-indicator {
        height: 0px !important;
      }
    }
  }
}
// Editing Dashboard

.editDashboardContent {
  padding-bottom: 73px;
  @include phone {
    position: relative;
    top: 67px;
  }
  .editDBDetails {
    padding: 20px;
  }
}
.editTemplateDashboard {
  .editTDashboardPartition {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 70px;
    @include phone {
      display: block;
    }
    .emailTemplateContent {
      flex: 0 0 70%;
      padding-bottom: 20px;
    }
    .right_sidebar_cust {
      flex: 0 0 25%;
      background: #f1f1f1;
      height: 100%;
      padding: 20px 20px;
      border-radius: 20px;
      box-sizing: border-box;
    }
  }
}

.right_sidebar_cust {
  flex: 0 0 25%;
  background: #f1f1f1;
  height: 100%;
  padding: 20px 20px;
  border-radius: 20px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 70px 20px;
    border-radius: 0px;
    position: fixed;
    top: 67px;
    width: 76%;
    left: -74%;
  }
  .mobileSlideSidebar {
    display: none !important;
    @media (max-width: 767px) {
      display: flex !important;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: 20px;
      color: #3f3e43;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
  .openSidebarIcon {
    right: -13px;
    transform: rotate(180deg);
    background-color: #f1f1f1;
    justify-content: center;
    padding-right: 10px;
  }
  .closeSidebaricon {
    background-color: #ffffff;
  }
  .sidebar_tabs_wrap {
    .nav.nav-tabs {
      border-bottom: 0px;
      li {
        a {
          color: #908e9a;
          border: 1px solid transparent;
          padding: 10px 30px;
          display: inline-block;
          border-radius: 16px;
          font-weight: 400;
          font-size: 15px;
          @media (max-width: 767px) {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
        a.active {
          border-color: #5b33f1;
          color: #5b33f1;
          font-weight: 500;
          background: #e3defc;
        }
      }
    }
    .ques_btn_wrap {
      .add-ques-btn {
        background: $themeOrangeColor;
        width: 100%;
        border: 0;
        color: #fff;
        text-transform: uppercase;
        min-height: 70px;
        border-radius: 10px;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        margin-top: 70px;
        span {
          height: 40px;
          display: inline-block;
          line-height: 40px;
          padding-left: 22px;
          border-left: 1px solid #d98872;
          margin-left: 30px;
        }
      }
    }
  }
}
.emailLayoutreSet {
  display: none;
}
.selectDropdown {
  .sidebarHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    .fa {
      color: #000000;
      font-size: 14px;
    }
  }
  .setEmailLayout {
    cursor: pointer;
  }
}
.sideCenterLink {
  margin: 30px 0px;
  text-align: center;
  a {
    color: #908e9a;
    font-size: 17px;
    text-decoration: underline;
  }
}
.emailTemplateContent {
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
  .mainContent_heading {
    h2 {
      font-size: 22px;
      color: #3f3e43;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      @include large {
        font-size: 25px;
      }
    }
    i {
      color: #5b33f1;
      font-size: 17px;
      margin-right: 7px;
      @include large {
        font-size: 20px;
      }
    }
  }
}
.selectDropdown {
  p {
    color: #3d3c2b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 400;
    @include large {
      font-size: 17px;
      font-weight: 500;
      padding: 15px 0px;
    }
    i {
      font-size: 14px;
      margin-right: 7px;
    }
  }
}
.right_sidebar_cust {
  .sidebarHeading {
    font-size: 19px;
    color: #02000a;
    margin-bottom: 10px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 10px 0px;
    padding: 15px 0px;
    @include large {
      font-size: 22px;
      padding: 25px 0px;
    }
  }
}
.borderBottom {
  border-bottom: 1px solid #d9d9d9;
}
.borderTop {
  border-top: 1px solid #d9d9d9;
}
.blueHeading {
  color: #281374 !important;
}
.largeDarkThemeBttn {
  background: $themeOrangeColor;
  width: 100%;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 17px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 50px;
  height: 50px;
  cursor: pointer;
  @include large {
    margin-top: 70px;
    font-size: 18px;
  }
}
.largeOutlineThemeBttn {
  height: 55px;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  color: #908e9a;
}
.emailTemplateContent {
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
  .mainContent_heading {
    h2 {
      font-size: 22px;
      color: #02000a;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      @include phone {
        font-size: 15px;
      }
      @include large {
        font-size: 26px;
      }
    }
    i {
      color: #5b33f1;
      font-size: 20px;
      margin-right: 7px;
      @include phone {
        font-size: 15px;
      }
      @include large {
        font-size: 24px;
      }
    }
  }
}
.emailFormSection {
  display: block;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  .inputField {
    flex: 0 0 31%;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}
.eventGredientBox {
  padding: 40px 60px;
  background-image: linear-gradient(to bottom right, #8c86cb, #cb6faa);
  border-radius: 10px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    padding: 20px;
  }
  .headss {
    text-align: center;
    p {
      color: #fff;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 25px;
      @include phone {
        font-size: 16px;
        margin-bottom: 10px;
      }
      @include large {
        font-size: 28px;
      }
    }
    h3 {
      color: #cab6e3;
      font-weight: 700;
      font-size: 35px;
      @include phone {
        font-size: 22px;
      }
      @include large {
        font-size: 46px;
      }
    }
  }
  .EventDetais {
    margin-top: 70px;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      .eventLocation {
        &:first-child {
          flex: 0 0 70%;
          @media (max-width: 767px) {
            margin-bottom: 15px;
          }
        }
      }
    }
    h5 {
      color: #fff;
      font-size: 22px;
      padding-bottom: 10px;
      font-weight: 500;
    }
    p {
      color: #f3d6eb;
      font-size: 16px;
      font-family: 'Segoe UI Regular';
    }
  }
}
.eventLocation {
  &:first-child {
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}
.eventReciptTable {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 30px;
  .receiptHead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @include phone {
      display: block;
    }
    h5 {
      font-size: 22px;
      color: #02000a;
      font-family: 'Segoe UI';
      @media (max-width: 767px) {
        font-size: 18px;
        flex: 0 0 45%;
        padding-bottom: 10px;
      }
    }
    p {
      color: #7b7b82;
      font-family: 'Segoe UI';
      font-size: 16px;
      b {
        color: #02000a;
      }
    }
  }
  .receiptTbleBody {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
    }
    .receipttableBox {
      h6 {
        font-size: 16px;
        color: #02000a;
        font-family: 'Segoe UI';
        @include phone {
          font-size: 14px;
        }
      }
      p {
        font-size: 16px;
        color: #7b7b82;
        font-family: 'Segoe UI';
        @include phone {
          font-size: 14px;
        }
      }
    }
  }
}
.confirmationMessageBox {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d5d5d5;
  @media (max-width: 767px) {
    min-height: 120px;
  }
  h3 {
    font-size: 25px;
    color: #c1c1c1;
    @include phone {
      font-size: 20px;
    }
    @include large {
      font-size: 28px;
    }
  }
  p {
    font-size: 14px;
    color: #858796;
    text-align: center;
  }
}
.eventUpdateOthers {
  margin-top: 60px;
  text-align: center;
  p {
    color: #02000a;
    font-size: 22px;
    margin-bottom: 30px;
    @include phone {
      font-size: 16px;
    }
  }
}
.backtoeditTemp {
  cursor: pointer;
  padding-right: 8px;
}
.emailFormSection {
  .form_group {
    label {
      color: #3d3c2b;
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    input {
      height: 50px;
      box-sizing: border-box;
      @include large {
        height: 74px;
      }
    }
  }
}
.editDBSteppers {
  width: 70%;
  margin: auto;
  @include phone {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 50px;
  }
}

// recipientsPage
.recipientsPage {
  padding: 0px 100px;
  @include phone {
    padding: 20px;
  }
  @include tabletPortrait {
    padding: 0px 50px;
  }
}
.fullSreenHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0px;
  @media (max-width: 767px) {
    display: block;
  }
  .headingRecipients {
    h2 {
      color: #02000a;
      font-weight: 300;
      font-size: 32px;
      @media (max-width: 767px) {
        font-size: 27px;
        margin-bottom: 0px;
      }
    }
  }
  .buttonsRecipient {
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
    .largeBlueThemeBttn {
      padding: 10px 20px;
      min-height: auto;
      text-decoration: none;
      cursor: pointer;
      @media (max-width: 767px) {
        display: block;
      }
    }
    .largeOutlineThemeBttn {
      min-width: 160px;
      cursor: pointer;
    }
    select {
      // appearance: none;
      // -moz-appearance: none;
      // -webkit-appearance: none;
      position: relative;
    }
    .selectButton {
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
      &:after {
        top: 25px !important;
      }
    }
  }
}
.contactsTable {
  padding-bottom: 70px;
}

// Reviews
.reviewContentSection {
  padding-bottom: 70px;
  .reviewcardList {
    .reviewcard {
      padding: 30px;
      box-shadow: 0px 0px 3px #e9dcdc;
      background-color: #ffffff;
      border-radius: 10px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        padding: 15px;
        display: block;
      }
      .getLink {
        a {
          font-size: 15px;
          color: #5b33f1;
          text-decoration: underline;
          font-weight: 500;
        }
        .accessRequest {
          color: #ff456e;
        }
      }
      .fa {
        color: #5b33f1;
      }
    }
  }
  .eventpageSetting {
    h3 {
      font-size: 20px;
      color: #02000a;
      padding-bottom: 10px;
      margin-top: 20px;
    }
    .reviewcard {
      padding: 30px;
      box-shadow: 0px 0px 3px #e9dcdc;
      background-color: #ffffff;
      border-radius: 10px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        padding: 15px;
        display: block;
      }
      .getLink {
        a {
          font-size: 15px;
          color: #5b33f1;
          text-decoration: underline;
        }
      }
      .fa {
        color: #5b33f1;
      }
    }
  }
}
.SubchildReviewCard {
  padding: 30px;
  box-shadow: 0px 0px 3px #e9dcdc;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    padding: 15px;
    display: block;
  }
  .InnerMainChildCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d4d4d4;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .InnerViewcard {
    display: flex;
    justify-content: space-between;
  }
  .getLink {
    a {
      font-size: 15px;
      color: #5b33f1;
      text-decoration: underline;
    }
    .accessRequest {
      color: #ff456e;
    }
  }
  .fa {
    color: #5b33f1;
  }
}
.eventSummary {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  .eventDetailsicon {
    margin-right: 15px;
    .fa {
      font-size: 35px;
    }
  }
  .eventDetailsFull {
    label {
      font-size: 16px;
      color: #707070;
      margin-bottom: 0px;
      font-family: 'Segoe UI Regular';
      padding-bottom: 5px;
    }
    p {
      font-size: 16px;
      color: #02000a;
      font-family: 'Segoe UI Regular';
      a {
        color: #5b33f1;
      }
    }
  }
}
.getLink {
  @media (max-width: 767px) {
    text-align: left;
    padding-left: 48px;
    display: flex;
    flex-flow: column;
  }
}
.errorRecipients {
  border: 1px solid #ff456e;
  background-color: #ffeff3 !important;
  .fa {
    color: #ff456e !important;
  }
}
.successRecipients {
  border: 1px solid #67d25f;
  background-color: #f2fff1 !important;
  .fa {
    color: #67d25f !important;
  }
}

// Steps
.projectStepRow {
  margin-top: 60px;
  @include phone {
    margin-bottom: 0px !important;
  }
  .projectSteps {
    ul.projectStepColl {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 55%;
      position: relative;
      @media (max-width: 767px) {
        max-width: 100%;
      }
      @include tabletPortrait {
        max-width: 100%;
      }
      &:after {
        border-top: 1px dashed #d2d2d2;
        content: '';
        height: 2px;
        left: 52%;
        position: absolute;
        top: -60%;
        transform: translate(-50%, -50%);
        width: 72%;
        z-index: 0;
        @media (max-width: 767px) {
          top: -100%;
        }
        @include LargeScreens {
          top: -50%;
        }
      }
      li {
        list-style: none;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        padding-top: 10px;
        @media (max-width: 767px) {
          font-size: 12px;
        }
        @include LargeScreens {
          font-size: 24px;
        }
        span.projectProcess {
          position: absolute;
          display: inline-block;
          background: #ffffff;
          width: 25px;
          height: 25px;
          left: 50%;
          top: -22px;
          border: 2px solid $themeOrangeColor;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          z-index: 1;
          box-sizing: border-box;
          @include phone {
            left: 45%;
            top: -28px;
          }
          &.singleDot {
            &::after {
              content: '';
              position: absolute;
              top: 2px;
              left: 2px;
              width: 17px;
              height: 17px;
              background-color: $themeOrangeColor;
              border-radius: 50%;
            }
          }
        }
      }
      li.active {
        color: $themeOrangeColor;
        span.projectProcess {
          background: $themeOrangeColor;
          &:after {
            content: '';
            position: absolute;
            left: 7px;
            top: 3px;
            width: 7px;
            height: 13px;
            border: solid white;
            box-sizing: border-box;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 0px;
          }
        }
      }
    }
    .fourStepColl {
      display: none;
      &:after {
        left: 52% !important;
        width: 86% !important;
        @include phone{
          left: 50%!important;
          width: 91%!important;
        }
      }
    }
  }
}
.eventDetailsStepsSec {
  padding-bottom: 100px;
  .completeRegisterboxes {
    display: block;
    .submitForm {
      margin: 20px;
      justify-content: center;
    }
  }
}
.recipientsMainsec {
  .dashboardMiddleArea {
    .typeProjectName {
      padding-bottom: 100px;
    }
  }
}

.emailSchedulerModal {
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      left: 40px !important;
    }
  }
}
