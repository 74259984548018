.loader-main-hp {
  margin: 0px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.loader-hp {
  margin: 15px auto;
  border: 7px solid #5b33f1;
  border-radius: 100%;
  border-top: 7px solid #281474;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.small {
  margin: 0 auto;
  border: 4px solid #85716873;
  border-top: 4px solid #fcfafa;
  width: 25px;
  height: 25px;
}

.inverted {
  border: 5px solid #fff;
  border-top: 5px solid#ef5655;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
