p {
    margin: 0;
}

.custom_tab {
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    margin: 0 0 10px;
    padding: 5px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom_tab li {
    border-top: none;
    border-right: none;
    border-left: none;
}

.custom_subtab {
    display: inline-block;
}

.custom_subtab h5 {
    display: inline-block;
    font-size: 11px;
    line-height: 22px;
    text-transform: uppercase;
}

.custom_subtab i {
    padding-right: 8px;
    font-size: 11px;
}

.custom_tab .react-tabs__tab--selected {
    background: #fff;
    border-bottom: 3px solid #5B33F1;
    color: black;

}

.tabpanel_1 {
    width: 100%;
    height: 100%;
    grid-gap: 0.5em;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.tabpanel_1-1 {
    width: 100%;
}

.tabpanel_1-2 {
    width: 100%;
}

.tabpanel_1-3 {
    width: 100%;
}

.tabpanel_1-2a {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
    grid-auto-rows: minmax(100px, auto);
}

.tabpanel_block2a:nth-child(2) h5 {
    color: #7D35C1;
}

.tabpanel_block2a:nth-child(3) h5 {
    color: #FF4170;
}

.tabpanel_block2a:nth-child(4) h5 {
    color: #16D1FC;
}

.tabpanel_block2a:nth-child(5) h5 {
    color: #00CA72;
}

.tabpanel_block2a:nth-child(6) h5 {
    color: #5B33F1;
}

.tabpanel_block2a {
    div {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #EDECF5;
        padding: 24px;
        height: 100%;
    }
}

.tabpanel_block2a h5 {
    font-size: 35px;
    font-weight: 500;
    color: #F26135;
}

.tabpanel_block2a p {
    font-size: 14px;
}

.tabpanel_content1 {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px 15px;
    border: 1px solid #EDECF5;
}

.tabpanel_content3 {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px 15px;
    border: 1px solid #EDECF5;
}

.block_head1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #EDECF5;
}

.block_head3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #EDECF5;
}

.block_head1 h5 {
    text-transform: uppercase;
    font-size: 12px;
    color: $blue-primary;
    font-weight: 500;
    line-height: 24px;
}

.block_head3 h5 {
    text-transform: uppercase;
    font-size: 12px;
    color: $blue-primary;
    font-weight: 500;
    line-height: 24px;
}

.block_head1 h6 {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.004em;
    line-height: 24px;
    color: #5141E7; 
    font-weight: 500;
}

.block_head3 h6 {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.004em;
    line-height: 24px;
    color: #5141E7; 
    font-weight: 500;
}

.block_para1 {
    padding: 0;

}

.block_para1 .block_list {
    position: relative;
    margin: 15px 0;
}

.block_para1 .block_list img {
    margin-top: 4px;
}

.block_list {
    display: block;


}

.early-access-detail,
.dashboard-table-card {

    position: relative;

    .count {
        font-size: 10px;
        line-height: 22px;
        color: #000059;
        border: 0.5px solid rgba(0, 0, 89, 0.3);
        border-radius: 2px;
        padding: 0px 10px;
        display: block;
        min-width: 78px;
        font-weight: 600;
        text-align: center;
        display: block;
    }

    .page-info {
        p {
            line-height: 27px;
        }
    }

    .page-info,
    .group-info {
        display: none;
        position: absolute;
        width: 187px;
        max-height: 193px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 28px rgba(0, 0, 89, 0.23);
        border-radius: 4px;
        bottom: 30px;
        z-index: 2;

        &:hover {
            display: block;
        }
    }

    .group-info {
        width: 677px;
        max-height: 343px;
        padding: 0px;
        bottom: 26px;
        border-radius: 8px;


    }

    .info-table {
        height: 285px;
        min-height: 285px;
        max-height: 285px;
        overflow-y: scroll;

        table {
            tr {
                .tableHeadCell {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #000056;
                    border-top: 1px solid;
                    border-color: #EDECF5;
                    padding-top: 16px;
                    padding-bottom: 8px;
                    background-color: #FAF9FC;
                    min-width: 150px;
                    left: unset;

                    &.date-time {
                        min-width: 250px;
                        //  max-width: 200px;
                      }

                      &.long-text {
                        min-width: 250px;
                        // max-width: 250px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      }
              
                      &.short-text {
                        min-width: 150px;
                        // max-width: 150px;
                      }
                }

                .tableBodyCell {
                    border-color: #EDECF5;
                    font-size: 14px;
                    line-height: 22px;
                    color: #000056;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    min-width: 150px;

                    &.date-time {
                        min-width: 250px;
                        //  max-width: 200px;
                      }

                      &.long-text {
                        min-width: 250px;
                        // max-width: 250px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      }
              
                      &.short-text {
                        min-width: 150px;
                        // max-width: 150px;
                      }

                    &.pages {
                        position: relative;

                        .page-info {
                            display: none;
                            text-align: left;
                            top: 40px;
                            right: 50px;
                            bottom: unset;
                        }

                        .count {
                            font-weight: 500;
                            border: 0.5px solid rgba(0, 0, 89, 0.3);
                            border-radius: 3px;
                            padding: 2px 5px;
                            display: inline;
                        }

                        >span {
                            &:hover {
                                .page-info {
                                    display: block;
                                }
                            }
                        }



                    }
                }

                &.status_starting {
                    .tableBodyCell {
                        color: #DD3300;
                    }
                }

                &.status_completed {
                    .tableBodyCell {
                        color: #726F8E;
                    }
                }

                &.status_expected {
                    .tableBodyCell {
                        color: #00BC1E;
                    }
                }

                &:nth-last-child(-n+3) {
                    .tableBodyCell {

                        &.pages {
                            .page-info {
                                bottom: 40px;
                                top: unset;
                            }
                        }
                    }
                }

                &:nth-child(-n+3) {
                    .tableBodyCell {

                        &.pages {
                            .page-info {
                                bottom: unset;
                            }
                        }
                    }
                }
            }
        }

    }

    &:hover {

        .page-info,
        .group-info {
            display: block;
        }
    }
}

li.block_list:nth-child(11) h5 {
    font-size: 12px;
    background: #ccc;
    padding: 3px 5px;
    border-radius: 3px;
}

li.block_list .tags {
    font-size: 12px;
    color: $blue-primary;
    background: #EDECF5;
    padding: 5px 4px;
    border-radius: 4px;
    margin: 5px 5px 0px 0px;
    display: inline-flex;
}

li.block_list:nth-child(11) h6 {
    font-size: 12px;
    display: inline-block;
    margin-left: 4px;
    background: #ccc;
    padding: 3px 5px;
    border-radius: 3px;
}

.block_list h5 {
    display: inline-block;
    margin-left: 20px;
    color: $blue-primary;

    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
}

.block_para1 .block_list {
    list-style: none;
}

.block_para3 .block_list1 {
    list-style: none;
    position: relative;
    margin: 10px 0 10px 0;
    border-bottom: 1px solid #EDECF5;
    padding-bottom: 15px;
}

.block_para3 .block_list1:last-child {
    border-bottom: none;
    margin-bottom: 10px;
    padding-bottom: 0;
}

.block_para3 .block_list1 span {

    background: #5c4de4;
    color: #fff;
    opacity: 0.7;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px;
    text-transform: uppercase;
    display: block;
}

.block_para3 .block_list1 i {
    font-size: 10px;
    color: #726F8E;
    margin-left: 43px;
}

.block_para3 .block_list1 h5 {
    display: inline-block;
    margin-bottom: 0;
    color: $blue-primary;
}

.block_para3 .block_list1 p {
    font-size: 12px;
    color: $blue-primary;
    line-height: 24px;
}

// filter style

.filterSearchBar {
    .SearchGrid {

        .eventControlSearch {
            button {
                border-bottom: 1px solid #D3D0E4 !important;
                border-radius: 0;
                height: 33px;
                width: 25px;
                padding: 0;
                text-align: left;
            }

            input {
                background: transparent;
                border: none;
                border-bottom: 1px solid #D3D0E4;
                font-size: 14px;
                font-weight: 400;
                height: 100%;
                padding: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                height: 34px;
                outline: 0;
            }
        }

        button {
            background: transparent;
            border: none !important;
            padding: 4px;
            width: 34px;
            height: 32px;

            &.active {
                border: none !important;
                background: #000056;
            }


            img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// filter style end

// myEventTable start
.myEventTable {
    .eventTableInner {
        .table {
            tr {

                td,
                th {
                    &.checkbox {
                        width: 50px;
                        min-width: 50px;
                        max-width: 50px;
                    }

                    &.status {
                        width: 160px;
                        min-width: 160px;
                        max-width: max-content;
                    }

                    width: 160px;
                    min-width: 160px;
                    max-width: max-content;
                } 
            }


        }
    }
}

// myEventTable end

//pagination
.tablePagination {
    margin-top: 20px;

    select {
        width: 70px;
        height: 34px;
        background: #EDECF5;
        border-radius: 4px;
        border: 0;
        margin: 0 7px;
        padding: 6px 8px;
    }
}

ul.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 0 15px;

    li {
        padding: 0px 4px;
        list-style-type: none;

        a {
            color: #747678;
            text-decoration: none;
            display: flex;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-left: 0;
            font-weight: 500;
            text-decoration: none;
            border: 1px solid #D5D9DC;
        }

        &.active {
            a {
                background: #4F46BA;
                border: 1px solid #4F46BA;
                color: #fff;
                width: 32px;
                height: 32px;
            }
        }

        &.disabled {
            a {
                border: 1px solid #D5D9DC;
                cursor: not-allowed;
            }
        }
    }
}

.required {
    color: #ff0000;
}

// scrollbar design

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888888;
}

// scrollbar design end

// select

select {
    /*container for custom dropdown arrow*/
    -webkit-appearance: none;
   -moz-appearance: window;
    background-image: url("../../../assets/images/icons/select-dropdown.svg") !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 15px) center !important;
}

.MuiSelect-root {
    background-image: none !important;
}

.MuiOutlinedInput-root {
    legend {
        width: auto;
    }
}

.d-none {
    display: none !important;
} 

// for custom tool login page eye icon 
#toolLoginForm { 
    z-index: 7;
    position: relative;
}