.common-btn{
    font-size: 15px;
    font-weight: 500;
    background: #5141E7;
    color: #fff !important;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 15px 22px;
    display: inline-block;
    text-decoration: none !important;
}