.editingPageWrapper {
  width: 100%;
  .editingDashboard {
    padding: 0px;
    overflow-x: auto;
    height: calc(100vh - 48px);

    .editingHeader {
      background: #f8f8f8;
      padding: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;
      @include phone {
        padding: 15px;
        border-bottom: 1px solid #ebebeb;
        border-radius: 0px;
        margin: 0px;
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        z-index: 999;
      }
      .editingHeading {
        h3 {
          display: flex;
          align-items: center;
          font-size: 22px;
          color: #02000a;
          font-weight: 500;
          letter-spacing: 0.2px;
          margin: 0px;
          @include phone {
            font-size: 18px;
          }
          @include LargeScreens {
            font-size: 28px;
          }
          a {
            background: #fff;
            border: 1px solid #e3e3e3;
            border-radius: 17px;
            padding: 4px 7px;
            margin-right: 8px;
            width: 40px;
            height: 42px;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            @include phone {
              width: 26px;
              height: 26px;
            }
            @include large {
              width: 45px;
              height: 48px;
            }
            img {
              @include phone {
                width: 16px;
              }
            }
          }
        }
      }
    }
    .copyUrl {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      margin-right: 20px;
      a {
        padding: 7px 15px;
        background-color: $themeOrangeColor;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        border-radius: 4px;
        text-transform: uppercase;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.editingSubmitView {
  .outlineButton {
    font-size: 17px;
    background-color: transparent;
  }
  .backup {
    color: #908e9a;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-right: 20px;
  }
  .right_checkBttn {
    width: 50px;
    height: 50px;
    background-color: #67d25f;
    border-radius: 50%;
    border: none;
    font-size: 21px;
    cursor: pointer;
    .fa {
      color: #fff;
    }
  }
}

.editingDashboardContent {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 80px 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  flex-direction: row;
  @include phone {
    display: block;
    padding-bottom: 80px;
    padding-top: 50px;
  }
  &.pageDesktopView {
    display: block;
    .registerEditor {
      display: none;
    }
  }
  .registerFormArea {
    padding: 40px 20px 0px;
    flex: 0 0 72%;
    text-align: center;
    box-sizing: border-box;
    @include phone {
      padding: 20px 0px;
      padding-top: 0px;
    }
    .brandLogo {
      img {
        background-color: blueviolet;
        padding: 15px;
        border-radius: 10px;
      }
    }
    .registerFormInner {
      margin: 60px 80px 0px 80px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      margin-top: 70px;
      padding: 70px 150px 85px 150px;
      border-radius: 18px;
      width: auto;
      @include phone {
        margin: 60px 0px 0px;
        padding: 30px 20px;
      }
      .submitForm {
        width: 70%;
        margin: 50px auto;
        margin-bottom: 0px;
        .themeBlueButton {
          font-size: 19px;
          width: 100%;
          min-height: 60px !important;
          @include large {
            min-height: 74px !important;
          }
        }
      }
    }
  }
}

.registerEditor {
  padding: 40px 10px;
  flex: 0 0 25%;
  box-sizing: border-box;
  background: #f1f1f1;
  border-radius: 15px;
  bottom: 0px;
  width: 25%;
  @include tabletPortrait {
    flex: 0 0 30%;
  }
  @include phone {
    padding: 70px 20px;
    border-radius: 0px;
    position: fixed;
    top: 67px;
    width: 76%;
    left: -74%;
  }
  .inputField {
    label {
      margin-bottom: 30px !important;
      font-size: 20px;
    }
  }
  .branding_form_wrap {
    .inputField {
      label {
        margin-bottom: 5px !important;
        font-size: 16px;
      }
    }
  }
}
.registerEditor.Open {
  left: 0px !important;
  overflow: auto;
}
.registerEditor.Open .openSidebarIcon {
  right: 13px;
  transform: none;
  background-color: #fff;
  justify-content: center;
  padding-right: 0px;
}
.registerEditor.Open .openSidebarIcon .fa {
  right: 0px;
}
.cloudEyeStoreSec {
  display: none;
  @include phone {
    display: flex;
    padding-bottom: 20px;
    justify-content: flex-end;
    margin-top: 70px;
  }
  span {
    width: 36px;
    height: 36px;
    border: 1px solid #d5d6d6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    .fa {
      color: #3f3e43;
    }
  }
}

.mobileSlideSidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #3f3e43;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  i {
    right: 5px;
    position: relative;
  }
}
.openSidebarIcon {
  right: -13px;
  transform: rotate(180deg);
  background-color: #f1f1f1;
  justify-content: center;
  padding-right: 10px;
}
.closeSidebaricon {
  background-color: #ffffff;
}

// Tabs design

.modalTabsDesign {
  .MuiTabs-root {
    button {
      color: #908e9a;
      border: 1px solid transparent;
      padding: 10px 30px;
      display: inline-block;
      border-radius: 16px;
      font-weight: 400;
      font-size: 17px;
      text-transform: capitalize;
      @include phone {
        font-size: 14px;
        padding: 10px 4px;
      }
      &.Mui-selected {
        border-color: #5b33f1;
        color: #5b33f1;
        font-weight: 500;
        background: #e3defc;
      }
    }
  }
  .MuiTabs-indicator {
    bottom: 0;
    height: 50px !important;
    background-color: transparent !important;
    position: absolute;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .add_form_btn {
    text-align: center;
    button {
      font-size: 19px;
      min-height: 74px !important;
      min-width: 300px;
      @include phone {
        min-width: auto;
      }
    }
  }
}
.asideTabs {
  .MuiTabs-scroller {
    span {
      &.MuiTabs-indicator {
        bottom: 0;
        height: 50px !important;
        background-color: transparent !important;
        position: absolute;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
  .MuiTabs-fixed {
    padding: 0px 10px;
    box-sizing: border-box;
  }
  .MuiBox-root {
    padding: 10px;
  }
  .MuiTabs-flexContainer {
    button {
      padding: 0px;
      box-sizing: border-box;
      border-radius: 16px;
      border: 1px solid transparent;
      text-transform: capitalize;
      &.Mui-selected {
        border-color: #5b33f1;
        color: #5b33f1;
        font-weight: 500;
        background: #e3defc;
      }
      &.active {
        border-color: #5b33f1;
        color: #5b33f1;
        font-weight: 500;
        background: #e3defc;
      }
    }
  }
}

// Add Question button

.ques_btn_wrap {
  padding: 24px;
  @include phone {
    padding: 24px 0px;
  }
  .add-ques-btn {
    background: $themeOrangeColor;
    width: 100%;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    min-height: 60px;
    border-radius: 10px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 70px;
    cursor: pointer;
    @include phone {
      min-height: 70px;
    }
    span {
      height: 40px;
      display: inline-block;
      line-height: 40px;
      padding-left: 22px;
      border-left: 1px solid #d98872;
      margin-left: 30px;
    }
  }
}

// Editor Main Form

.form_group {
  margin-bottom: 15px;
  label {
    color: #908e9a;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    padding: 0;
    padding-bottom: 12px;
    font-size: 17px;
    font-weight: 500;
    color: #3d3c2b;
    outline: 0;
  }
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    .btn {
      color: #a8a793;
      background-color: #fafafa;
      padding: 8px 20px;
      border-radius: 10px;
      font-size: 15px;
      width: 100%;
      min-height: 70px;
      font-weight: 400;
      border: none;
      span {
        color: $themeOrangeColor;
        font-weight: 500;
      }
    }
    input {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .colo_pik_wrap {
    color: #a8a793;
    background-color: #fafafa;
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 15px;
    width: 100%;
    min-height: 70px;
    font-weight: 400;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    .color_piker {
      margin-right: 12px;
      .color-picker {
        align-items: center;
        input {
          background: transparent;
          border: 0;
          font-size: 15px;
          padding-right: 0;
          color: #3f3e43;
          font-weight: 500;
          position: relative;
          flex: 1 1 auto;
          width: 1%;
          min-width: 0;
          margin-bottom: 0;
          padding-bottom: 0px;
        }
        .current-color {
          display: block;
          width: 44px;
          height: 44px;
          cursor: pointer;
          border-radius: 50%;
          border: 4px solid #fff;
        }
      }
    }
    .dots_icon {
      margin-right: 10px;
    }
  }
}
.selectButton {
  position: relative;
  background: transparent;
  border-radius: 7px;
  .form-control {
    background-color: #ffffff !important;
    padding: 8px 20px;
    border-radius: 10px;
    font-size: 15px;
    width: 100%;
    min-height: 70px;
    font-weight: 500;
    border: 1px solid transparent;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 34px;
      right: 30px;
      background: url('../assets/images/chevron-down.png');
      width: 10px;
      height: 6px;
    }
  }
}
// .heading {
//   font-size: 20px;
//   text-transform: uppercase;
//   font-weight: 500;
//   color: #281374;
//   margin-top: 34px;
//   margin-bottom: 36px;
// }
.heading1 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  color: #281374;
  margin-top: 34px;
  margin-bottom: 36px;
}
.branding_form_wrap {
  .field-group {
    margin-bottom: 10px;
  }
  .form_group {
    margin-bottom: 35px;
    label {
      color: #3d3c2b;
      font-size: 16px;
      font-weight: 500;
      @include tabletPortrait {
        font-size: 13px;
      }
    }
    .add_social_link {
      ul {
        margin: 0px;
        padding: 0px;
        li {
          position: relative;
          list-style: none;
          margin-bottom: 15px;
          span {
            position: absolute;
            width: 30px;
            height: 28px;
            background: #f4f1ff;
            border-radius: 4px;
            text-align: center;
            left: 6px;
            top: 5px;
          }
          input {
            width: 100%;
            min-height: 38px;
            border: 0;
            padding: 5px;
            border-radius: 5px;
            padding-left: 45px;
            background-color: #fff;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }
    .form-control {
      box-sizing: border-box;
    }
  }
}
.react-swipeable-view-container {
  padding-top: 40px;
  .MuiBox-root {
    padding: 0px 10px !important;
  }
}

// modal

.modalContentMain {
  width: 60%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  transition: none !important;
  @include phone {
    width: 80%;
    margin-top: 30px;
  }
  .headingBar {
    h2 {
      text-align: center;
      color: #02000a;
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 24px;
      @include phone {
        font-size: 20px;
      }
    }
  }
  .modalTabsDesign {
    margin-top: 40px;
    .tabsHeads {
      .MuiTabs-root {
        width: 60%;
        margin: auto;
        @include phone {
          width: 100%;
        }
      }
    }
  }
}
.modalTabsDesign {
  .MuiToggleButtonGroup-root {
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
    @include phone {
      display: block !important;
    }
    .MuiToggleButton-root {
      flex: 0 0 32%;
      margin-right: 0px !important;
      max-width: 32%;
      border-radius: 17px !important;
      background: #f8f8f8 !important;
      border: 1px solid transparent;
      min-height: 80px;
      align-items: center;
      padding: 10px 35px;
      display: flex;
      margin-bottom: 18px;
      display: -webkit-flex;
      justify-content: flex-start;
      @include phone {
        max-width: 100%;
        width: 100%;
      }
      &.Mui-selected {
        border: 1px solid #ff456e !important;
      }
      .MuiToggleButton-label {
        display: flex;
        flex-direction: column;
      }
      h4 {
        margin: 0px;
        font-size: 15px;
        color: #3f3e43;
        font-weight: 500;
        text-align: left;
        margin-bottom: 0px;
        text-transform: capitalize;
        width: 100%;
      }
      p {
        width: 100%;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 15px;
        margin: 0px;
        text-align: left;
      }
    }
  }
}

// responsive device
.renposive_selector {
  ul {
    display: flex;
    display: -webkit-flex;
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      padding: 0 4px;
      a {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-radius: 10px;
        background: #f4f1ff;
        display: flex;
        align-items: center;
        justify-content: center;
        @include phone {
          width: 40px;
          height: 40px;
        }
        img {
          &:last-child {
            display: none;
          }
          @include phone {
            width: 14px;
          }
        }
        &:hover {
          img {
            &:last-child {
              display: inline-block;
            }
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

// registration1
.registorEditiorSection {
  flex: 0 0 71%;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  @include tabletPortrait {
    width: 80% !important;
    flex: 0 0 68%;
  }
  @media (max-width: 767px) {
    top: 100px;
  }
  .formBanner {
    background-image: url('../assets/images/banner_image.png');
    border-radius: 24px;
    min-height: 580px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    @include phone {
      background-size: contain;
      min-height: auto;
      box-shadow: none;
    }
  }
  .banner_content {
    padding: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include phone {
      display: block;
      padding: 10px;
    }
    .banner_heading {
      flex: 0 0 55%;
      h2 {
        color: #fff;
        font-size: 47px;
        font-weight: bold;
        width: 70%;
        text-align: left;
        margin: 0px;
        @include phone {
          font-size: 27px;
          text-align: center;
          padding: 24px;
          width: 80%;
          margin: auto;
        }
      }
    }
    .banner_form {
      background: rgba(255, 255, 255, 0.8);
      padding: 40px 32px;
      border-radius: 24px;
      flex: 0 0 38%;
      @include phone {
        background-color: #ffffff;
        min-width: 96%;
        margin: auto;
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
        border: 1px solid #707070;
      }
      h4 {
        font-size: 26px;
        color: #02000a;
        font-weight: bold;
        margin-bottom: 24px;
        margin-top: 0px;
        text-align: left;
      }
      .themeBlueButton {
        width: 100%;
        color: #fff !important;
        font-size: 19px;
      }
    }
  }
}
.brandProfileInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  .brandLogo {
    background-color: #e2e2e2;
    padding: 8px;
    border-radius: 10px;
    width: 120px;
    height: 45px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .userPicture {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      border: 5px solid #e2e2e2;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
}
.activeSelectSection {
  border: 4px solid #ff456e;
}
.intro_vedioMainSection {
  min-height: 450px;
  background-image: url('../assets/images/video_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  border-radius: 24px;
  padding: 55px 55px 55px 80px;
  position: relative;
  box-sizing: border-box;
  @include phone {
    padding: 10px;
    margin-bottom: 150px;
    min-height: auto;
  }
  .vedioOverlyContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tabletPortrait {
      display: block;
    }
    @include phone {
      display: block;
    }
    .vedioDescription {
      flex: 0 0 35%;
      text-align: left;
      @include phone {
        padding-bottom: 60px;
      }
      @include tabletPortrait {
        margin-bottom: 25px;
      }
      h2 {
        color: #fff;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      p {
        color: #dcdcdc;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .vedioPortion {
      flex: 0 0 60%;
      position: relative;
      border-radius: 10px;
      @include phone {
        position: relative;
        top: 80%;
        left: 0px;
      }
      .vid_wrapper {
        .video {
          object-fit: cover;
          width: 100%;
          border-radius: 24px;
          min-height: 330px;
          @media (max-width: 767px) {
            min-height: 185px;
            height: 185px;
          }
          @include tabletPortrait {
            min-height: 230px;
          }
        }
        .playpause {
          background-image: url('../assets/images/video_icon.png');
          background-repeat: no-repeat;
          width: 50%;
          height: 50%;
          position: absolute;
          left: 0%;
          right: 0%;
          top: 0%;
          bottom: 0%;
          margin: auto;
          background-position: center;
          cursor: pointer;
        }
      }
    }
  }
}
#introVideo {
  background-image: url('../assets/images/video__poster.png');
  background-repeat: no-repeat;
}
.meetingSchedulerSection {
  position: relative;
  background-image: url('../assets/images/bg-423.png');
  background-size: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  @include phone {
    padding: 25px 0px;
    display: block;
  }
  .schedulerList {
    background: #fff;
    box-shadow: 0 3px 6px rgba(41, 62, 130, 0.16);
    position: relative;
    border-radius: 16px;
    min-height: 400px;
    flex: 0 0 48%;
    @include tabletPortrait {
      min-height: 200px;
    }
    &:first-child {
      @include phone {
        margin-bottom: 20px;
      }
    }
    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      color: #e3e3e3;
      font-size: 36px;
      font-weight: 500;
    }
  }
}

// library section
.library_section {
  position: relative;
  background-image: url('../assets/images/illuflip_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  &::before {
    content: '';
    background-image: url('../assets/images/bg--423.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: 100%;
    z-index: 0;
  }
  &::after {
    content: '';
    background-image: url('../assets/images/bg--424.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: 100%;
    z-index: 0;
  }
  h2 {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    margin: 0px;
  }
  .illuflip_lib_sec {
    padding: 100px 20px;
    @media (max-width: 767px) {
      padding: 20px;
    }
    @include tabletPortrait {
      padding: 50px;
    }
    .illuflip_image {
      width: 80%;
      margin: auto;
      height: 370px;
      @include tabletPortrait {
        height: auto;
      }
      @include phone {
        height: auto;
      }
      img {
        height: 100%;
        width: 100%;
      }
      .illu_heading {
        color: #ffffff;
        font-size: 26px;
        font-weight: 500;
      }
    }
  }
}
.illuflip_lib_sec {
  .illuflip_image {
    img {
      z-index: 99;
      position: relative;
    }
  }
}
.libraryBeforeSec {
  &::after {
    position: relative;
    background-image: url('../assets/images/bg--425.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-size: 100%;
  }
}
// Magzine Slider
.magzine_carousel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 0px 0px 0px;
  @include phone {
    display: block;
    padding: 30px 0px 0px 0px;
  }
  .magzine_item {
    box-shadow: 0 5px 21px rgba(0, 0, 0, 0.39);
    border-radius: 5px;
    flex: 0 0 24%;
    @include phone {
      margin-bottom: 20px;
    }
    @include tabletPortrait {
      flex: 0 0 48%;
      margin-bottom: 20px;
    }
    @media (min-width: 1280px) {
      flex: 0 0 23%;
    }
    .magzineImage {
      width: 100%;
      height: 240px;
      @media screen and (min-width: 1100px) and (max-width: 1300px) {
        height: 200px;
      }
      @media screen and (min-width: 1300px) and (max-width: 1500px) {
        height: 220px;
      }
      @include phone {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .magzineSliderContent {
      min-height: 160px;
      .McontentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding: 10px 15px;
        .magzinecategory {
          text-align: left;
          span {
            background: #fdebf7;
            color: $themeOrangeColor;
            font-size: 13px;
            padding: 5px 10px;
            border-radius: 5px;
            display: block;
            margin-right: 10px;
          }
        }
        .magzineResponse {
          display: flex;
          p {
            margin: 0px;
            width: 31px;
            background-color: #fdecf7;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            &:first-child {
              margin-right: 5px;
            }
            img {
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
      .McontentText {
        padding: 0px 15px 30px 15px;
        text-align: left;
        a {
          text-decoration: none;
        }
        p {
          color: #23bfef;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
          margin-top: 0px;
        }
        h3 {
          font-size: 15px;
          line-height: 20px;
          color: #2d2c2c;
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.pageDesktopView {
  .magzine_item {
    .magzineImage {
      @media (min-width: 1280px) {
        height: 260px;
      }
      @media (min-width: 1350px) {
        height: 310px;
      }
      @media (min-width: 1560px) {
        height: 270px;
      }
      @media (min-width: 1600px) {
        height: 330px;
      }
      @media (min-width: 1700px) {
        height: 400px;
      }
    }
  }
  .illuflip_lib_sec {
    .illuflip_image {
      height: 550px;
      img {
        height: 100%;
      }
    }
  }
  .innerLibrarySection {
    iframe {
      height: 550px;
    }
  }
}
.bottomDashboardFooter {
  position: relative;
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.ViewGuestPrivewMain {
  width: 100%;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    width: 100%;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
  }
}
.illuFlipLoginVideo {
  width: 100%;
  height: 550px;
  border: none;
  @media (max-width: 1025px) {
    height: auto;
  }
}
.editingSubmitView {
  display: flex;
  align-items: center;
}
.continueSaveBttn {
  display: flex;
}
.editTempDesign .blockbuilder-branding {
  display: none !important;
}
.pageresponsiveView {
  &.active {
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: flex;
      }
    }
  }
  a {
    cursor: pointer;
  }
}
.formSection {
  .react-datepicker-popper {
    left: -95px !important;
    @include phone {
      left: 0px !important;
    }
  }
}
.emailSchedulerModal {
  min-height: 340px;
}
.innerLibrarySection {
  padding: 50px 0px;
  .flipIframeHeading {
    width: 80%;
    text-align: right;
    margin: auto;
    padding-bottom: 40px;
    .illu_heading {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 17px;
        right: 135px;
        width: 65%;
        height: 2px;
        background-color: #fff;
      }
    }
  }
  iframe {
    width: 80%;
    z-index: 99999;
    position: relative;
    max-height: 500px;
    height: 400px;
  }
}
.registorEditiorSection {
  .validation-error {
    position: fixed;
    width: 200px !important;
    top: 0;
    left: auto !important;
    right: 0px;
    margin: 0;
    z-index: 99999999;
    background-color: #fafafa;
    padding: 15px;
    .fa-exclamation-triangle {
      color: red;
      padding: 20px;
      font-size: 35px;
    }
    ul {
      padding: 5px 0px;
      li {
        list-style: none;
        text-align: center;
        padding: 3px 5px;
        color: #333;
        font-size: 12px;
      }
    }
    a {
      text-align: center;
      color: #333;
      font-size: 13px;
      cursor: pointer;
    }
  }
}

.edit_template_field_id34 {
  display: flex;
  justify-content: space-between;
  @include phone {
    display: block;
  }
  .inputField {
    &:first-child {
      margin-right: 20px;
      @include phone {
        margin-right: 0px;
      }
    }
  }
  .event_list_che34 {
    .auto_save34 {
      display: flex;
      align-items: center;
      padding-left: 20px;
      min-height: 55px;
      @include phone {
        padding: 0px;
      }
      input {
        margin-bottom: 0px !important;
      }
      label {
        padding-left: 10px;
        margin: 0px !important;
      }
    }
  }
}
