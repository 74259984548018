.analyticsPage {
  .analyticsTitle {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 20px;

    @include phone {
      margin-bottom: 30px;
      display: block;
    }

    h3 {
      font-size: 18px;
      color: #3f3e43;
      font-weight: 500;
    }

    .analyticsSelect {
      @include phone {
        margin: 0px !important;
      }

      .form-group {
        margin: 0px;

        @include phone {
          width: 100%;
          margin: 0px !important;
          margin-bottom: 10px !important;
        }

        .analyticsSelectBox {
          .outlineButton {
            font-size: 17px;

            @include phone {
              font-size: 13px;
              border-radius: 20px !important;
              min-height: 49px !important;
              padding: 10px 17px !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .analyticsMails {

    // display: flex;
    // @include phone{
    //     display: block;
    // }
    // @include tabletPortrait{
    //     display: block;
    // }
    .analyticsMailsInner {
      display: flex;
      flex-wrap: wrap;

      @include phone {
        margin-bottom: 40px;
        flex: 0 0 100%;
      }

      @include tabletPortrait {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        justify-content: space-between;
      }

      .anaTotalMail {
        box-shadow: 0px 0px 8px lightgrey;
        padding: 13px;
        border-radius: 10px;
      }

      .analyticColl {
        margin-bottom: 20px;

        @media screen and (min-width: 768px) and (max-width: 1300px) {
          padding-right: 10px;
          padding-left: 10px;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 47%;
          flex: 0 0 47%;
          max-width: 47%;
        }

        h3 {
          color: #02000a;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 3px;
          min-height: 30px;
          margin-top: 0px;

          @include phone {
            font-size: 18px;
          }

          @media screen and (min-width:768px) and (max-width:1100px) {
            min-height: 17px;
          }
        }

        span {
          font-size: 14px;
          color: #02000a;

          @include phone {
            font-size: 14px;
          }

          @media screen and (min-width:768px) and (max-width:1100px) {
            font-size: 11px;
          }
        }

        p {
          color: #02000a;
          font-size: 15px;
        }

        &:nth-child(2) {
          span {
            cursor: pointer;
          }
        }

        &:nth-child(3) {
          span {
            cursor: pointer;
          }
        }
      }
    }

    .mailAnalyticsColl {
      flex: 0 0 30%;

      @include phone {
        margin-top: 20px;
        flex: 0 0 100%;
      }

      .analyticsProcess {
        display: flex;
        align-items: center;

        @include tabletPortrait {
          justify-content: flex-end;
        }

        .analyticsProBar {
          width: 100%;
          background: #f8f8f8;
          border-radius: 10px;
          overflow: hidden;
          margin-bottom: 8px;
          height: 27px;

          @include phone {
            width: 100%;
          }

          @include tabletPortrait {
            width: 60%;
          }

          .analyticsCurrentBar {
            background: #3f3e43;
            display: inline-block;
            height: 27px;
            width: 30%;

            @include phone {
              height: 15px;
            }
          }
        }

        h3 {
          color: #02000a;
          font-size: 23px;
          font-weight: 600;
          padding-left: 10px;
          margin-bottom: 3px;
          margin-top: 0px;

          @include phone {
            font-size: 18px;
            margin: 0px;
          }
        }
      }

      span {
        font-size: 15px;
        color: #02000a;

        @include phone {
          font-size: 14px;
        }
      }
    }
  }

  .analyticsGraph {
    display: flex;
    margin: 0px;
    justify-content: space-between;
    margin-top: 30px;

    @include phone {
      display: block;
    }

    @include tabletPortrait {
      display: block;
    }

    .graphBox {
      width: 100%;
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.07);
      border-radius: 20px;
      margin-bottom: 25px;

      @include phone {
        width: 100%;
      }

      @include tabletPortrait {
        width: 100%;
      }

      &:first-child {
        @include tabletPortrait {
          margin-bottom: 30px;
        }
      }

      img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}

.progressbar-container {
  .progressbar-progress {
    height: 34px !important;
  }
}

.graphBox {
  #chart {
    padding: 15px;

    @include phone {
      padding: 7px;
    }
  }
}

.sendBounceEmailModal {
  padding: 0px !important;

  .formSection {
    ul {
      padding: 15px 20px;
      max-height: 330px;
      overflow-y: auto;

      li {
        list-style: none;
        padding-bottom: 8px;
      }
    }
  }
}

#chart {
  .apexcharts-toolbar {
    display: none !important;
  }
}

.analyticsDimension {
  text {
    font-size: 12px !important;
  }
}

.analyticsSesons {
  text {
    font-size: 12px !important;
  }
}

.analyticsGraphtableArea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  @include tablet1 {
    display: block;
  }

  @include phone {
    display: block;
  }

  .analyticsDimension {
    @include tablet1 {
      margin-bottom: 20px;
    }

    @include phone {
      margin-bottom: 20px;
    }
  }

  .graphBox {
    flex: 0 0 48%;
    box-shadow: 0px 0px 8px lightgrey;
    border-radius: 10px;
  }

  .dashboardMiddleArea {
    flex: 0 0 100%;
    box-sizing: border-box;
    padding-right: 0px !important;
    padding-left: 0px !important;

    .eventAnalyticsPage {
      .createProjectAccount {
        margin-top: 0px !important;

        .projectCreateSection {
          .CreatePresentation {
            .eventAnalticsBoxes {
              // display: block;
              justify-content: space-between;

              .eventAnalticsList {
                margin-bottom: 25px;
                margin-right: 0px !important;

                .CRCircularBox {
                  border: none;
                  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.07);
                  border-radius: 20px;
                  height: 100%;

                  @include phone {
                    padding: 10px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// New PAges
.eventAnalyticsPage {
  .submitForm {
    @include tabletPortrait {
      padding: 20px 0px !important;
    }

    button {
      min-width: 210px;

      @include phone {
        min-width: 100%;
      }

      &:first-child {
        margin-right: 15px;

        @include phone {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }

      @include tabletPortrait {
        min-width: 180px;
      }
    }
  }
}

.eventAnalticsBoxes {
  display: flex;
  justify-content: flex-start;

  @include phone {
    display: block;
  }

  .eventAnalticsList {
    width: 100%;
    // flex: 0 0 27%;
    // @media screen and (min-width:1100px) and (max-width:1200px){
    //     flex: 0 0 35%;
    // }
    // @media screen and (min-width:1201px) and (max-width:1300px){
    //     flex: 0 0 30%;
    // }
    // @include phone{
    //     margin-bottom: 25px !important;
    // }
    // @include tabletPortrait{
    //     flex: 0 0 49%;
    // }
    // @include tablet1{
    //     flex: 0 0 40%;
    // }
    cursor: pointer;

    .CRCircularBox {
      padding: 15px 20px;

      &.active {
        border-color: #5b33f1;
      }
    }

    .selectTText {
      font-size: 15px;
      font-weight: 500 !important;
      padding-bottom: 0px;
      color: #3f3e43;
      padding: 8px 5px;
      text-align: center;
    }

    &:first-child {
      margin-right: 15px;

      @include phone {
        margin: 0px;
      }
    }

    .eventAnaHeading {
      font-size: 18px;
      font-weight: 500 !important;
      padding-bottom: 5px;
      color: #3f3e43;
      margin-bottom: 10px;

      @include tabletPortrait {
        font-size: 15px;
      }
    }

    .eventAnaListInner {
      display: flex;
      justify-content: space-between;
      width: 100%;

      p {
        background-color: #fff;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color: #02000a;
        font-size: 15px;
        box-shadow: 0px 0px 8px lightgrey;
        padding: 20px;
        border-radius: 10px;
        flex: 0 0 17%;

        @include tabletPortrait {
          font-size: 12px;
        }

        span {
          &:first-child {
            order: 2;
          }

          &:last-child {
            color: #02000a;
            font-size: 23px;
            font-weight: 600;
            margin-bottom: 3px;
            min-height: 30px;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

// Email Performance
.performanceBoxeDigital {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include phone {
    display: block;
  }

  .EmailPcard {
    flex: 0 0 30%;
    padding: 10px 20px;

    @include phone {
      margin-bottom: 20px;
    }

    @include tabletPortrait {
      flex: 0 0 31%;
    }

    h6 {
      font-size: 15px;
      font-weight: 500 !important;
      padding-bottom: 0px;
      color: #3f3e43;
      text-align: left;
    }

    h2 {
      color: $themeDarkBlue;
      padding: 20px;
      text-align: center;
      font-weight: 600;
      font-size: 36px;

      @include tabletPortrait {
        padding: 15px;
        font-size: 20px;
      }
    }
  }
}

.performanceListView {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @include phone {
    display: block;
  }

  @include tabletPortrait {
    display: block;
  }

  .performanceListCard {
    flex: 0 0 45%;

    @include phone {
      margin-bottom: 50px;
    }

    @include tabletPortrait {
      margin-bottom: 50px;
    }

    ul {
      li {
        border-bottom: 1px solid #e6e6e6;
        list-style-type: none;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .lsHeadingPV {
          color: $HeadingTextColor;
          font-size: 14px;
        }

        .activeColored {
          color: $themeDarkBlue;
          font-size: 14px;
        }
      }
    }
  }
}

.analyticsTwoFilter {
  justify-content: space-between !important;

  .analyticsSelectBox {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include phone {
      display: block;
    }

    .outlineButton {
      width: 205px;

      @media screen and (min-width:768px) and (max-width:991px) {
        width: 180px;
      }
    }

    .desktopLlable {
      @include phone {
        display: none;
      }
    }
  }

  .analyticsSelect {
    &:first-child {
      display: flex;
      justify-content: flex-start;

      @include phone {
        display: block;
      }
    }
  }
}

.analyticsFilterLot {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @include phone {
    display: block;
  }

  @include tabletPortrait {
    display: block;
  }

  .eventControlSearch {
    @include tabletPortrait {
      margin-bottom: 15px;
    }

    form {
      @include tabletPortrait {
        width: 100%;
      }

      @include phone {
        width: 100%;
      }

      display: flex;
      align-items: center;

      .exportBttnAna {
        margin-left: 15px;
      }
    }

    .filterSerchSubmit {
      @include tabletPortrait {
        width: 100%;
      }

      @include phone {
        width: 100%;
      }

      .form-control {
        background: #fafafa;
        border: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        padding: 0;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        height: 50px;
        outline: 0;
        min-width: 300px;

        @include phone {
          min-width: auto;
        }
      }
    }
  }

  .analyticsSelect {
    display: flex;
    align-items: center;

    @include phone {
      display: block;
      text-align: right;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .analyticsSelectBox {
      display: flex;
      align-items: center;

      @include phone {
        margin-top: 15px;
      }

      .outlineButton {
        min-width: 200px;
      }
    }

    .exportBttnAna {
      @include phone {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}

.analyticsListOnly {
  padding-bottom: 80px !important;

  .table {
    thead {
      tr {
        th {
          &:first-child {
            max-width: 110px !important;
            min-width: 110px !important;
          }

          &:nth-child(2) {
            max-width: 210px !important;
            min-width: 210px !important;

            @media screen and (min-width: 1250px) and (max-width: 1290px) {
              max-width: 250px !important;
              min-width: 250px !important;
            }
          }

          &:nth-child(3) {
            max-width: 260px !important;
            min-width: 260px !important;
          }

          &:nth-child(4) {
            max-width: 180px !important;
            min-width: 180px !important;
          }

          &:nth-child(5) {
            justify-content: flex-end;
            min-width: 100px !important;
            max-width: 100px !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            max-width: 110px !important;
            min-width: 110px !important;
            padding-left: 12px !important;
          }

          &:nth-child(2) {
            max-width: 210px !important;
            min-width: 210px !important;

            @media screen and (min-width: 1250px) and (max-width: 1290px) {
              max-width: 250px !important;
              min-width: 250px !important;
            }
          }

          &:nth-child(3) {
            max-width: 260px !important;
            min-width: 260px !important;
          }

          &:nth-child(4) {
            max-width: 180px !important;
            min-width: 180px !important;
          }

          &:nth-child(5) {
            justify-content: flex-end;
            min-width: 100px !important;
            max-width: 100px !important;
          }

          &:last-child {
            width: 100% !important;
          }

          .eventName {
            cursor: pointer;

            &:hover {
              color: $sidebarBackground;
            }
          }
        }
      }
    }
  }
}

.eventRegisterUserF {
  .formSection {
    form {
      flex-wrap: nowrap;

      .filterSearchBar {
        flex-direction: column;

        .filterSerchSubmit {
          .form-control {
            border: none;
            background: #fafafa;
          }
        }
      }
    }
  }
}

.anaSelectDRange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @include phone {
    display: block;
  }

  .inputField {
    .formDate {
      display: flex;

      @include phone {
        display: block;
      }

      .react-datepicker-wrapper {
        @include phone {
          margin-bottom: 10px;
        }

        .outlineButton {
          width: 155px;
          box-shadow: 0px 0px 4px #dedede;
          background-color: #fafafa;
          min-height: 34px !important;
          padding: 0px 10px !important;
          border-radius: 4px !important;
          background-position: top 4px right 8px;

          @media screen and (min-width: 768px) and (max-width: 850px) {
            min-width: 130px;
          }

          @include phone {
            width: 100%;
            border-radius: 20px !important;
          }

          &:focus {
            outline: 0;
            border: 1px solid #5b33f1 !important;
            box-shadow: 0 15px 30px #eaeaea;
          }
        }
      }
    }
  }

  .themeBlueButton {
    width: 205px;

    @media screen and (min-width: 768px) and (max-width: 850px) {
      min-width: 150px;
      font-size: 15px !important;
      padding: 10px 15px !important;
    }

    @include phone {
      width: 100%;
      margin: 0px !important;
    }
  }
}