@import '../sass/Variables.scss';
@import '../sass/ThemeResponsive.scss';
@import '../sass/register.scss';
@import '../sass/Typography.scss';
@import '../sass/Common.scss';
@import '../sass/Sidebar.scss';
@import '../sass/AppBarStyle.scss';
@import '../sass/EventList.scss';
@import '../sass/MyEvent.scss';
@import '../sass/FilterSearch.scss';
@import '../sass/EditingStyles.scss';
@import '../sass/Contact.scss';
@import '../sass/Analytics.scss';
@import '../sass/Profile.scss';
@import '../sass/ProjectType.scss';
@import '../sass/ChooseTemplate.scss';
@import '../sass/CompleteRegistration.scss';
@import '../sass/EmailStyles.scss';
@import '../sass/PageToolBar';
@import '../sass/Campaign.scss';
@import '../assets/styles/components/color';
@import '../assets/styles/components/variables';

.main_page {
  position: relative;
}
.dashboardMainPage {
  display: flex;
  justify-content: space-between;
  @media (max-width: 599px) {
    display: block;
    position: relative;
  }
  .sidebarCompo {
    height: 100vh;
    position: fixed;
    width: 100px;
    @include phone {
      width: 70px;
      background-color: #281374;
      height: 100%;
      position: fixed;
    }
  }
  .dashboardContentArea {
    position: absolute;
    left: 100px;
    right: 0px;
    @media (max-width: 599px) {
      position: unset;
      padding-bottom: 68px;
    }

    @media (min-width: 600px) and (max-width: 767px) {
      left: 70px;
    }
  }
  .dashboardMiddleArea {
    padding: 10px;

    @media #{$medium-and-up} {
      padding: 20px 20px;
    }

    @media #{$large-and-up} {
      padding: 20px 20px;
    }

    @media #{$extra-large-and-up} {
      padding: 20px 24px;
    }

    @media #{$xx-large-and-up} {
      padding: 20px 40px;
    }
  }
}
.MuiButtonBase-root {
  &:focus {
    outline: 0;
  }
}
.button {
  &:focus {
    outline: 0 !important;
  }
}

.error_class {
  color: red !important;
}

.MuiCheckbox-colorPrimary .Mui-checked {
  color: #3f51b5 !important;
}
