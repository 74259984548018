.dbProfile {
  .MuiPaper-root {
    background-color: #ffffff;
    padding: 0px 40px;
    margin: auto;
    box-shadow: none;
    @media (max-width: 1450px) {
      padding: 0px 25px;
    }
    @include phone {
      padding: 0px 10px;
    }
    .MuiToolbar-gutters {
      padding: 0px;
      border-bottom: 1px solid #ebebeb;
    }
    .AppbarNotification {
      @include phone {
        display: none;
      }
    }
    h6 {
      color: #02000a;
      font-size: 22px;
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      padding-right: 30px;
      display: flex;
      align-items: center;
      overflow: visible;
      text-transform: capitalize;
      @include phone {
        display: flex;
        font-size: 13px;
      }
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        font-size: 18px;
      }
      a {
        background: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 17px;
        padding: 4px 7px;
        margin-right: 8px;
        width: 45px;
        height: 48px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include phone {
          width: 28px;
          height: 28px;
        }
        @media screen and (min-width: 768px) and (max-width: 1400px) {
          width: 35px;
          height: 38px;
        }
        img {
          @include phone {
            width: 15px;
          }
          @media screen and (min-width: 768px) and (max-width: 1400px) {
            width: 15px;
          }
        }
      }
      .MuiBadge-root {
        span {
          background-color: #ff456e;
          border-radius: 9px;
          font-size: 15px;
          left: -10px;
          padding: 4px 10px;
          width: 36px;
          height: 23px;
        }
      }
    }
    .MuiSvgIcon-root {
      fill: #02000a;
    }
  }
  .profileImage {
    width: 100%;
    height: 35px;
    border-radius: 50%;
  }
  .notificationIcon {
    width: 19px;
  }
  .MuiToolbar-gutters {
    justify-content: space-between;
    .makeStyles-grow-1 {
      flex-grow: initial;
    }
  }
}
.MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}
.appBarRightPortion {
  position: relative;
  padding: 7px 0px;
  button {
    @include phone {
      padding: 0px;
    }
  }
  .AppbarNotification {
    width: 35px;
    height: 35px;
  }
  .profileBarUser {
    background-color: $sidebarBackground;
    padding: 0px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    &:hover {
      background-color: $sidebarBackground;
    }
    @include phone {
      width: 30px;
      height: 30px;
    }
    p {
      color: #ede83a !important;
      font-size: 15px;
      padding: 5px;
      text-transform: uppercase;
      @include phone {
        font-size: 13px;
      }
    }
  }
}
.MuiList-root {
  .MuiButtonBase-root {
    color: #3a3b45;
    a {
      color: #3a3b45;
      text-decoration: none;
    }
  }
}

.profileDropwdown {
  display: none;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  min-width: 200px;
  right: 0px;
  top: 100%;
  border-radius: 0px;
  z-index: 999;
  bottom: auto;
  padding: 0;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  border: 1px solid #e1e6f1;
  &::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 12px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
  }
  &.Open {
    display: flex;
  }
  a {
    text-decoration: none;
    border-radius: 0px;
    position: relative;
    padding: 10px;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #596882;
    box-sizing: border-box;
    &:hover {
      text-decoration: none;
      color: #5b34f1;
      background-color: transparent;
    }
    & + a {
      border-top: 1px solid #e1e6f1 !important;
    }
    .fa {
      font-size: 13px;
    }
  }
}
.notificationDropwdown {
  display: none;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  min-width: 265px;
  right: 15px;
  top: 100%;
  border-radius: 0px;
  z-index: 999;
  bottom: auto;
  padding: 0;
  border: 1px solid #e1e6f1;
  box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  &::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
  }
  &.Open {
    display: flex;
    animation-name: fadein;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  a {
    display: block;
    color: #3f3e43;
    padding: 8px 15px;
    font-size: 12px;
    text-decoration: none;
    border-radius: 0px;
    position: relative;
    &:hover {
      text-decoration: none;
      &::after {
        display: block;
      }
    }
    & + a {
      border-top: 1px solid rgba(123, 65, 216, 0.1);
    }
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0px;
      bottom: -1px;
      right: 0px;
      background-color: #f1f2f9;
      border-top: 1px solid #e1e6f1;
      border-bottom: 1px solid #e1e6f1;
      z-index: -1;
      display: none;
    }
  }
  p {
    color: #5b34f1;
    margin: 0px;
    padding-top: 10px;
    margin-bottom: 0px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid #ebebeb;
  }
}

.progessbar_Resipients {
  padding: 20px 100px 40px;
  position: relative;
  @include phone {
    padding: 0px 20px;
    margin-top: 85px;
  }
  span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 18px;
      left: -2px;
      width: 22px;
      height: 22px;
      background-color: #5b33f1;
      border-radius: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 18px;
      width: 22px;
      height: 22px;
      background-color: #5b33f1;
      border-radius: 50%;
    }
  }
  &:after {
    content: 'Campaign Progress';
    position: absolute;
    bottom: 10px;
    left: 45%;
  }
}
.progessbar_Analytics {
  padding: 7px 100px 7px;
  position: relative; 
  width: 100%;  
  @include phone {
    padding: 0px 30px;
  } 
  span {
    position: relative;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
    height: 23px;
    padding-right: 15px;
    &:before {
      content: '';
      position: absolute;
      top: 18px;
      left: -2px;
      width: 12px;
      height: 12px;
      background-color: #987cff;
      border-radius: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 18px;
      width: 12px;
      height: 12px;
      background-color: #987cff;
      border-radius: 50%;
    }
  }
  // &:after{
  //     content: 'Campaign Progress';
  //     position: absolute;
  //     bottom: 10px;
  //     left: 45%;
  // }
}

.campaignProgressText {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3px;
  color: #404040;
  font-size: 13px;
  font-weight: 400;
  @include phone{
    padding-top: 4px;
  }
}
