.common-table{
    width: 100%;

    tr{

        th{
            padding: 19px 12px;
        }
    }
    tbody{
        tr{
            height: 8px;
            min-height: 8px;
            td{
                padding: 19px 12px;
                border-top: 1px solid #EDECF5;
                border-bottom: 1px solid #EDECF5;

                &:first-child{
                    border-left: 1px solid #EDECF5;
                    -webkit-border-top-left-radius: 8px;
                    -webkit-border-bottom-left-radius: 8px;
                    -moz-border-radius-topleft: 8px;
                    -moz-border-radius-bottomleft: 8px;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                &:last-child{
                    border-right: 1px solid #EDECF5;
                }
            }

            &:nth-child(2n){
                border-right: none;
                border-left: none;
            }
        }
    }
}