// Font Family
$FontRubik: 'Rubik', sans-serif !important;

// Colors
$backgroundWhite: #ffffff !important;
$sidebarBackground: #281374;
$TextWhite: #ffffff;
$HeadingTextColor: #02000a;
$SubHeadingTextColor: #908e9a;
$themeDarkBlue: #5b33f1 !important;
$themeOrangeColor: #800080 !important;
$themelightYellow: #ede83a !important;
$checkboxLabel: #8c8b8f;
$Linkcolor: #ff456e;
$borderColor: #e6e6e6 !important;

// Font Weight

$fontWeightNormal: 400;
$fontWeightMedium: 500 !important;
$fontWeightBold: 700;

// Form Input

$inputBackgroundColor: #fafafa;

// Width/Height

$fullWidth: 100%;
