.filterImport {
  display: flex;
  align-items: center;
  .importExportBtn {
    display: flex;
    height: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 7px;
    padding: 10px 5px;
    min-height: 50px;
    box-sizing: border-box;
    align-items: center;
    a {
      color: #7b7b82;
      font-size: 13px;
      font-weight: 500;
      padding: 0 7px;
      line-height: normal;
      border-right: 1px solid #d8d8d8;
      border-radius: 0;
      text-decoration: none;
      cursor: pointer;
      @include large {
        font-size: 13px;
      }
      @include mainLargeScreen {
        font-size: 15px;
      }
      &:hover {
        color: #5b33f1;
        text-decoration: underline;
      }
      &:last-child {
        border-right: none;
      }
    }
    span {
      button {
        color: #7b7b82;
        font-size: 13px;
        font-weight: 500;
        padding: 0 7px;
        line-height: normal;
        border-right: 1px solid #d8d8d8;
        border-radius: 0;
        text-decoration: none;
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 0 7px !important;
        @include large {
          font-size: 13px;
        }
        @include mainLargeScreen {
          font-size: 15px;
        }
        &:hover {
          color: #5b33f1;
          text-decoration: underline;
        }
        // &:last-child{
        //     border-right: none;
        // }
      }
    }
  }
}

// Table
.tableUserInfo {
  position: relative;
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding: 0px 5px;
  }
  .tableAlphabet {
    color: #5b33f1;
    font-size: 18px;
    font-weight: 500;
    left: -30px;
    position: absolute;
    text-transform: uppercase;
  }
}
.contactsTable {
  .audiance_table_inner {
    padding-left: 0px !important;
    .checkedAllSection {
      padding-left: 45px;
    }
    .sc-iwajpm {
      padding-bottom: 60px;
    }
  }
  .contactTableInner {
    padding-left: 30px;
    box-sizing: border-box;
    @include phone {
      overflow-x: auto;
    }
    tbody {
      tr {
        &:last-child {
          margin-bottom: 30px;
        }
        td {
          img {
            @include phone {
              display: block !important;
            }
          }
        }
      }
      & + tbody {
        border-top: 1px solid #ebebeb;
        tr {
          &:first-child {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.userStatus {
  span {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-right: 5px;
  }
  .userOffline {
    background: #ff2323;
  }
  .userOnline {
    background: #67d25f;
  }
}

// checkbox customize
// .MuiFormControlLabel-root{
//   .MuiSvgIcon-root{
//       fill: #e5d7f8;
//       font-size: 30px;
//   }
// }

.tableDotMore {
  position: relative;
  .tableDots {
    cursor: pointer;
  }
  .contacteditPopup {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    min-width: 100px;
    right: 25px;
    top: 50%;
    border-radius: 3px;
    z-index: 999;
    bottom: auto;
    padding: 0;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    border: 1px solid #e1e6f1;
    &.d-block {
      display: block;
    }
    &.Open {
      display: flex;
    }
    p {
      text-align: left;
      span {
        color: #707070;
        cursor: pointer;
        padding: 7px 10px;
        display: block;
        line-height: 15px;
      }
      &:hover {
        background: #fafafa;
      }
    }
  }
}

span.tableAlphabet {
  min-width: 25px;
}

.listLetterContact {
  color: #ede83a !important;
  font-size: 15px;
  padding: 5px;
  background-color: #281474;
  border-radius: 50%;
  text-transform: uppercase;
  min-width: 35px;
  min-height: 35px;
  margin-right: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterSerchSubmit {
  position: relative;
}
.mainModalPopup {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0006;
  display: none;
  top: 0px;
  z-index: 9999;
  .mainModalInner {
    width: 40%;
    min-height: 400px;
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;
    position: relative;
    @include phone {
      width: 80%;
    }
    .formSection {
      margin: 0px 60px;
      padding-bottom: 0px;
      @include phone {
        margin: 0px 20px;
      }
      .editProfileBtn {
        .field-group-flex {
          justify-content: center;
        }
        @include phone {
          margin: 10px 0px 0px 0px;
        }
      }
    }
  }
  &.Open {
    display: flex;
  }
}
.editProfieInner {
  position: relative;
  width: 90px;
  margin: auto;
  margin-bottom: 50px;
  .profileImgBox {
    padding: 3px;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    border: 1px solid $themeOrangeColor;
    img {
      object-fit: cover;
      width: 100%;
      border-radius: 50%;
      height: 100%;
      box-sizing: border-box;
    }
  }
  .profileEditButton {
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border: 3px solid #fff;
    border-radius: 50%;
    input {
      height: 35px;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 100%;
      &::after {
        background: transparent;
        content: '';
        cursor: pointer;
        height: 35px;
        left: 0;
        position: absolute;
        top: 0;
        width: 35px;
        z-index: 0;
      }
    }
    .buttonText {
      background: #5b33f1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      width: 35px;
      height: 35px;
    }
  }
  .error_mesage {
    position: absolute;
    width: 200px;
    left: -29px;
  }
}
.closeModal {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #5b33f1 !important;
  color: #ede83a !important;
  border: none;
  width: 25px;
  height: 25px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.react-responsive-modal-modal {
  width: 47%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  padding: 0px 0px 20px 0px !important;
  @include phone {
    max-width: 95% !important;
    width: 90%;
  }
  @include tabletPortrait {
    max-width: 70% !important;
    width: 90%;
  }
  .rsvpEventfieldBox {
    padding: 15px 20px;
  }
  .campaignEmailpopup {
    .campaignStatusEmail {
      min-height: unset;
      padding: 40px 20px 20px;
    }
  }
  .campaignStatusEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    .formSection {
      text-align: center;
      padding: 0 70px;
      @include phone {
        padding: 0 0px;
      }
      p {
        font-size: 18px;
        color: #404040;
        margin-bottom: 40px;
        font-weight: 500;
        @include phone {
          font-size: 16px;
        }
      }
    }
  }
  h2 {
    color: #fff;
    font-size: 19px;
    background-color: $themeOrangeColor;
    padding: 12px;
    font-weight: 400;
  }
}
.react-responsive-modal-closeButton {
  top: 8px !important;
}
.checkedAllSection {
  padding: 10px 0px;
  min-height: 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 65px;
  .checkBoxColl {
    margin-left: 5px;
    margin-bottom: 0px !important;
    width: auto;
  }
  input {
    margin: 0px;
  }
  button {
    background-color: #5b34f1;
    border: none;
    padding: 10px;
    margin-left: 10px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    min-width: 140px;
    font-size: 16px;
    height: 40px;
    line-height: 20px;
  }
}
.tableActionBothButton {
  display: flex;
}
.selectManageGroup {
  margin-top: 10px;
  .eventControlSearch {
    text-align: right;
    select {
      width: auto;
    }
  }
}
.react-responsive-modal-closeButton {
  top: 9px;
  svg {
    fill: #fff;
  }
}

.sampleCSV {
  padding: 7px 0px;
  display: flex;
  justify-content: flex-end;
  span {
    color: #281374;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
  }
}
.mobile-tablet-None {
  @include MobileSmallDesktop {
    display: none !important;
  }
  @include phone {
    display: none !important;
  }
  .OnlyDesktopNone {
    display: block !important;
  }
}
.OnlyDesktopNone {
  @media (min-width: 1291px) {
    display: none !important;
  }
}
.mobileAddMember {
  display: none;
  @include phone {
    display: flex;
    position: fixed;
    top: 18%;
    right: 0px;
    background-color: #5c34f1;
    color: #fff;
    width: 38px;
    height: 38px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
}
.closeAddMember {
  width: 30px;
  height: 30px;
  background-color: #c1c0c0;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  cursor: pointer;
}

// Assign Audience Group
.assignRadioCustom {
  flex: 0 0 80% !important;
  .paymentRadio2 {
    .customRadioBtn {
      padding: 0px 0px 3px 30px !important;
    }
  }
}
.react-responsive-modal-modal {
  #multiselectContainerReact {
    .optionListContainer {
      position: absolute;
    }
  }
}
#multiselectContainerReact {
  .search-wrapper {
    border: none;
    padding: 0px !important;
    .chip {
      background-color: #5141e7;
    }
    .searchBox {
      margin: 0px !important;
    }
  }
  .optionListContainer {
    position: relative;
    .optionContainer {
      max-height: 200px !important;
      li {
        display: flex;
        align-items: center;
        input {
          width: 22px;
          box-shadow: none;
          min-height: 22px;
          margin-top: 0px;
        }
        &.highlight {
          background: $sidebarBackground;
          color: $backgroundWhite;
        }
        &:hover {
          background-color: $sidebarBackground;
          color: $backgroundWhite;
        }
      }
    }
  }
}

.rescipientsMulti {
  .multiselect-container {
    position: relative;
    .search-wrapper {
      min-height: 55px !important;
      box-sizing: border-box;
      padding: 0px;
      .searchBox {
        box-sizing: border-box;
        margin: 0px;
        padding: 10px 10px 10px 20px;
        min-height: 55px;
        min-width: 100%;
        font-size: 14px;
        max-width: 420px;
      }
    }
    .optionListContainer {
      position: absolute !important;
    }
  }
}
.contactMuliAbsolute {
  #multiselectContainerReact {
    .optionListContainer {
      position: absolute;
    }
  }
}
.resipientMulti3 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}
.resipientsHeader1Sec {
  flex-wrap: wrap;
  .headingRecipients {
    flex: 0 0 15%;
    margin-right: 10px;
  }
  .buttonsRecipient {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 50%;
    @include tabletPortrait {
      margin-right: 55px;
    }
    @include phone {
      margin-right: 55px;
    }
    .addRecipntss {
      min-width: 180px;
      max-width: 180px;
    }
  }
  .rescipientsMulti {
    margin-right: 10px;
    width: 100%;
    .search-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      .searchBox {
        background: #fafafa;
        width: 100%;
      }
    }
    .optionListContainer {
      width: 420px;
      right: 0px;
    }
  }
}
.mainAudianceField {
  #multiselectContainerReact {
    .optionListContainer {
      position: relative;
    }
  }
}

// Audience
.CoupleActionBttn {
  display: flex;
  p {
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      color: $sidebarBackground;
    }
  }
}
.myEventTable {
  .audienceTable {
    .contactTableInner {
      tr {
        width: 100%;
        justify-content: space-between;
        th {
          &:first-child {
            text-align: left;
            padding-left: 50px;
          }
          &:last-child {
            text-align: right !important;
          }
        }
        td {
          .CoupleActionBttn {
            p {
              &:first-child {
                margin-left: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}
.ImportCopy_main {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
  margin-bottom: 25px;
  .Import_copy_Bttns {
    flex: 0 0 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #d0ebff;
    padding: 30px;
    cursor: pointer;
    border-radius: 10px;
    color: black;
    font-size: 19px;
    &:hover {
      box-shadow: 0px 0px 7px 0px 0px 7px #e2cccc;
    }
    i {
      font-size: 33px;
      color: #800081;
    }
  }
}
.audience_copy_form {
  .fullWidthInput {
    width: 100%;
    .label_add_bar {
      display: flex;
      justify-content: space-between;
      .add_Input_field {
        cursor: pointer;
        color: #ede83a !important;
        background-color: #5b34f1;
        border: none;
        width: 70px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .box_shadow_card3 {
    box-shadow: 0px 0px 5px #c5c5c5;
    padding: 15px;
    border-radius: 8px;
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
.label_selct_bar3 {
  text-align: center;
  font-size: 17px;
  margin-bottom: 20px !important;
}
.selectbar_audience {
  display: flex;
  align-items: start;
  justify-content: space-between;

  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px dashed #b8b8b8;
    margin-bottom: 20px;
  }
  select {
    margin-right: 10px;
  }
  .audience_input_Cross {
    cursor: pointer;
    color: #fff !important;
    background-color: red;
    border: none;
    width: 21px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 900;
  }
}
.formSection {
  .audience_CSV_Import {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field-group {
      flex: 0 0 80% !important;
      input {
        width: auto;
      }
    }
  }
}

// Audience
.audiance_table_inner {
  .sc-jSFjdj {
    .sc-gKAaRy {
      .rdt_TableCol_Sortable {
        div {
          border: 0;
          color: #000000;
          font-size: 15px;
          font-weight: 500;
          display: flex;
          width: 100%;
          align-items: center;
        }
      }
      &.rdt_TableCol {
        &:last-child {
          justify-content: flex-end;
          min-width: 150px !important;
        }
        &:first-child {
          .rdt_TableCol_Sortable {
            padding-left: 50px;
          }
        }
      }
    }
  }
}
.sc-jSFjdj {
  .sc-gKAaRy {
    .rdt_TableCol_Sortable {
      div {
        border: 0;
        color: #000000;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
    &.rdt_TableCol {
      &:last-child {
        justify-content: flex-end;
        min-width: 150px !important;
      }
    }
  }
}
.sc-iemWCZ.PSuRn.rdt_TableHeader {
  display: none;
}
.iOJddW {
  padding-left: 0px !important;
}
.audiance_table_inner {
  .iOJddW {
    padding-left: 30px !important;
  }
}
.contactsTable {
  .audienceContacts {
    padding-left: 0px !important;
  }
}
.hwDwZw {
  label {
    top: 6px;
    max-width: 45px;
  }
}
.eRDZVL div:first-child {
  overflow: visible !important;
}
.rdt_TableHeadRow {
  border: none !important;
}
// .rdt_TableBody{
//   .rdt_TableRow{
//     background: #ffffff;
//     border-radius: 10px;
//     box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.07) !important;
//     padding: 0px;
//     display: flex;
//     margin-bottom: 10px;
//     border: none !important;
//       .rdt_TableCell{
//           div{
//             word-break: break-all !important;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//           }
//           .tableAlphabet{
//             color: #5b33f1;
//             font-size: 18px;
//             font-weight: 500;
//             left: -25px;
//             position: absolute;
//             text-transform: uppercase;
//           }
//           img{
//             top: 10px;
//             font-size: 18px;
//             font-weight: 500;
//             text-transform: uppercase;
//             margin-right: 15px;
//             width: 35px !important;
//             height: 35px;
//             min-width: 35px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-radius: 50px;
//             object-fit: cover;
//           }
//           .listLetterContact{
//             top: 10px;
//             font-size: 18px;
//             font-weight: 500;
//             text-transform: uppercase;
//             background-color: #281374;
//             margin-right: 15px;
//             width: 35px !important;
//             height: 35px;
//             min-width: 35px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//           }
//           div{
//               border: 0;
//               color: #707070;
//               font-size: 14px;
//               font-weight: 400;
//               vertical-align: middle;
//               display: flex;
//               align-items: center;
//               overflow: hidden;
//               white-space: nowrap;
//               text-overflow: ellipsis;
//               a{
//                   &:hover{
//                       text-decoration: none;
//                   }
//               }
//               .tdAlert{
//                   cursor: pointer;
//               }
//           }
//           .contacteditPopup{
//               display: none;
//               flex-direction: column;
//               background-color: #fff;
//               position: absolute;
//               width: 100px !important;
//               right: 25px;
//               top: 50%;
//               border-radius: 3px;
//               z-index: 999;
//               bottom: auto;
//               padding: 0;
//               box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
//               border: 1px solid #e1e6f1;
//               span{
//                   color: #707070;
//                   cursor: pointer;
//                   padding: 7px 10px;
//                   display: block;
//                   line-height: 15px;
//               }
//           }
//           &:last-child{
//             justify-content: flex-end;
//             display: flex;
//               div{
//                   justify-content: flex-end;
//                   min-width: 100px !important;
//                   .tableDots{
//                       cursor: pointer;
//                       padding: 0.75rem;
//                   }
//               }
//           }
//           .tableDotMore{
//               &:hover{
//                   .contacteditPopup{
//                       display: block;
//                   }
//               }
//           }
//       }
//   }
// }

.analyticsListOnly {
  .rdt_TableCell {
    &:last-child {
      justify-content: flex-end;
      text-align: right;
      display: flex;
    }
  }
}
.myEventTable {
  .email_campign_design {
    .table {
      th {
        text-align: left;
      }
      tbody {
        td {
          &.tableDotMore {
            max-width: initial !important;
            min-width: auto !important;
          }
        }
      }
    }
  }
}

.modal-searchable-dropdown {
  width: calc(47% - 40px - 40px);
  max-width: calc(550px - 40px - 40px);

  @include phone {
    width: calc(90% - 40px);
    max-width: calc(90% - 40px);
  }
  @include tabletPortrait {
    width: calc(90% - 40px);
    max-width: calc(70% - 40px);
  }
}
