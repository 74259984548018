.registerPage {
  display: flex;
  @include phone {
    display: block;
  }
  .leftSidebar {
    background-color: #281374;
    height: 100vh;
    padding: 60px 0px;
    box-sizing: border-box;
    position: fixed;
    width: 30%;
    @include tablet1 {
      width: 40%;
    }
    @include tabletPortrait {
      width: 40%;
    }
    @include phone {
      height: auto;
      padding: 40px 0px;
      width: 100%;
      position: relative;
    }
    .registerSidebarBrand {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
      width: 60%;
      margin: auto;
      @include tabletPortrait {
        width: 70%;
      }
      img {
        width: 200px;
        @include phone {
          width: 126px;
          margin: auto;
        }
        @include large {
          width: 250px;
        }
      }
      .brandingText {
        @include phone {
          display: none;
        }
        h1 {
          color: $TextWhite;
          font-size: 27px;
          font-weight: 700;
          @include large {
            font-size: 32px;
          }
        }
      }
    }
  }
  .registerFormMain {
    padding: 0px 70px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    position: relative;
    left: 30%;
    height: 100vh;
    @include tablet1 {
      width: 60%;
      left: 40%;
    }
    @include tabletPortrait {
      width: 60%;
      left: 40%;
      padding: 0px 40px;
    }
    @include phone {
      padding: 20px;
      width: 100%;
      left: 0px;
      height: auto;
    }
    .submitForm {
      button {
        width: 100%;
        min-height: 60px !important;
        @include large {
          min-height: 74px !important;
        }
      }
    }
  }
}

.registerFormInner {
  width: 100%;
  .pageHeading {
    h1 {
      margin-bottom: 25px;
    }
    p {
      margin: 0px;
    }
  }
  .LoginForm {
    width: 50%;
    margin: auto;
    @include tablet1 {
      width: 80%;
    }
    @include tabletPortrait {
      width: 80%;
    }
    @include phone {
      width: 100%;
      padding-bottom: 0px;
    }
  }
  .field-group {
    &.submitForm {
      @include phone {
        margin-top: 0px;
      }
    }
  }
  .formSection {
    @include phone {
      padding-bottom: 0px;
    }
  }
}
.formSection {
  // padding: 30px 0px;
  // @include phone {
  //   padding: 30px 0px;
  // }
  // @include large {
  //   padding: 70px 0px;
  // }
  .field-group {
    flex: 0 0 48%;
  }
  .field-group {
    &.fullWidthInput {
      flex: 0 0 100%;
      margin-bottom: 20px;
      label {
        text-align: left;
      }
    }
    &.loginHere {
      padding: 0px;
      text-align: center;
      p {
        color: #8c8b8f;
        font-size: 16px;
        margin: 0px;
        a {
          color: #ff456e;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .field-group-flex {
    margin-top: 20px;
  }
}
// Page left sidebar Shapes

.signupShapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  span {
    display: inline-block;
    position: absolute;
  }
  .shape-1 {
    transform: translate(-20%, -50%);
    top: 63%;
    left: 50%;
    @include phone {
      top: 75%;
      left: 10%;
    }
    img {
      width: 140px;
      @include phone {
        width: 45px;
      }
    }
  }
  .shape-2 {
    right: -5%;
    transform: translate(-10%, -10%);
    bottom: 16%;
    @include phone {
      bottom: 0%;
      right: 0px;
    }
    img {
      width: 160px;
      @include phone {
        width: 50px;
      }
    }
  }
  .shape-3 {
    left: 30%;
    transform: translate(-50%, -50%);
    top: 50%;
    @include phone {
      left: 80%;
      top: 30%;
    }
    img {
      width: 110px;
      @include phone {
        width: 40px;
      }
    }
  }
  .shape-4 {
    left: 12%;
    transform: translate(-50%, 0%);
    top: 63%;
    @include tabletPortrait {
      left: 18%;
    }
    @include phone {
      left: 16%;
      top: 7%;
    }
    img {
      width: 100px;
      @include phone {
        width: 40px;
      }
    }
  }
  .shape-5 {
    right: 11%;
    transform: translate(20%, 0%);
    top: 36%;
    @include phone {
      left: 45%;
      top: 75%;
    }
    img {
      width: 150px;
      @include phone {
        width: 40px;
      }
    }
  }
}
.registerFormInner {
  .field-group-flex {
    .field-group {
      &:first-child {
        @include tablet1 {
          flex: 0 0 61%;
        }
      }
      &:last-child {
        @include tablet1 {
          flex: 0 0 37%;
        }
      }
    }
  }
  .conditionsterms {
    .conditionGroup {
      .checkBoxColl {
        @include tablet1 {
          padding-left: 35px;
          font-size: 14px;
        }
        a {
          @include tablet1 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.react-confirm-alert-overlay {
  z-index: 999999999 !important;
}
.registerEditingMobil34 {
  .react-form-builder-form {
    margin: 0px;
    width: 100%;
    padding: 20px;
    text-align: left;
  }
}
.pageDesktopView {
  .registerEditingMobil34 {
    max-width: 50%;
    margin: 50px auto;
    @include tabletPortrait {
      max-width: 90%;
    }
    .react-form-builder-form {
      padding: 30px 70px;
    }
  }
}
.pageTabletView {
  .react-form-builder-form {
    padding: 50px 70px;
  }
}
.makeStyles-createRegistrationForm-1 {
  .editingDashboardView23 {
    padding-top: 0px;
    .registerEditingMobil34 {
      @include phone {
        max-width: 100%;
        top: 0px;
      }
      .react-form-builder-form {
        @include phone {
          padding: 20px;
        }
      }
    }
  }
}
#signInFormBanner {
}
