@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');
@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('../assets/fonts/Segoe UI Regular'),
    url('../assets/fonts/Segoe UI.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('../assets/fonts/Segoe UI Italic'),
    url('../assets/fonts/Segoe UI Italic.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('../assets/fonts/Segoe UI Bold'),
    url('../assets/fonts/Segoe UI Bold.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('../assets/fonts/Segoe UI Bold Italic'),
    url('../assets/fonts/Segoe UI Bold Italic.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI';
  src: url('../assets/fonts/SegoeUI-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
// html,
// body {
//   margin: 0px;
//   padding: 0px;
//   font-family: 'Rubik', sans-serif;
//   background-color: $backgroundWhite;
//   box-sizing: border-box;
//   color: #858796;
// }
// h1,
// h2,
// h3,
// h5,
// h6,
// button,
// input,
// label,
// select,
// span,
// a,
// p {
//   font-family: 'Rubik', sans-serif;
// }
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
