$Phone-width: 320px;
$tablet-width: 768px;
$tabletPortrait-width: 991px;
$desktop-width: 1024px;
$desktop-width1: 1025px;
$desktop-width2: 1290px;
$AboveNormalLP-Macbook: 1367px;
$large-screen: 1450px;
$AboveNormal-desktop: 1367px;
$largeScreenAbove: 1800px;

@mixin phone {
  @media (min-width: #{$Phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

/*      @include phone {  }  */

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin tablet1 {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width1 - 1px}) {
    @content;
  }
}
/*      @include tablet {  }  */

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
/*    @include desktop {  }   */

@mixin normalMiddiumScreen {
  @media (min-width: #{$desktop-width}) and (max-width: #{$AboveNormalLP-Macbook - 1px}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-screen}) {
    @content;
  }
}
@mixin mainLargeScreen {
  @media (min-width: #{$largeScreenAbove}) {
    @content;
  }
}

@mixin AboveDesktop {
  @media (min-width: #{$AboveNormal-desktop}) {
    @content;
  }
}

// tabletPortrait
@mixin tabletPortrait {
  @media (min-width: #{$tablet-width}) and (max-width: #{$tabletPortrait-width - 1px}) {
    @content;
  }
}
@mixin mobileTabletNew {
  @media (min-width: #{$Phone-width}) and (max-width: #{$tabletPortrait-width - 1px}) {
    @content;
  }
}

// MobileToSmallDesktop
@mixin MobileSmallDesktop {
  @media (min-width: #{$Phone-width}) and (max-width: #{$desktop-width2}) {
    @content;
  }
}

@mixin LargeScreens {
  @media (min-width: #{$largeScreenAbove}) {
    @content;
  }
}
