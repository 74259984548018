html, body{
  margin: 0;
  padding: 0;
}
.floating-chat-widget {
  z-index: 9999;
  position: fixed;
  right: 25px;
  bottom: 40px;
}

.floating-chat-widget.left {
  left: 25px;
  right: unset;
}

.floating-chat-widget .chat-icon {
  cursor: pointer;
}

.floating-chat-window {
  width: 350px;
  /* min-height: 400px; */
  /* border-radius: 4px; */
}

.floating-chat-window
  .ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history {
  max-height: calc(100vh - 355px);
  height: 300px;
}

/* .ai-chat-widget,
.chat-window {
  font-family: Roboto, sans-serif;
} */

.ai-chat-widget .chat-widget-container {
  /* border-radius: 12px; */
  /* background: #fff; */ /* Bg-color added inline by chat interface */
  position: relative;
  overflow: hidden;
}

.ai-chat-widget .chat-widget-container .widget-title .chatbot-avatar {
  width: 36px;
  height: 36px;
}

.ai-chat-widget .chat-widget-container .widget-title .chatbot-avatar img {
  width: 36px;
  height: 36px;
  object-fit: cover;
}


.ai-chat-widget 
.chat-widget-container
 .chat-window .chat-history {
  /* height: calc(100vh - 330px); */
  overflow: auto;
  scrollbar-color: #999999a3 #ffffff00;


  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

.ai-chat-widget 
.chat-widget-container 
.chat-window .chat-history .chat-bubble {
  display: flex;
  position: relative;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message {
  /* border-radius: 3px; */
  display: inline-flex;
  /* padding: 8px 16px; */
  justify-content: center;
  align-items: baseline;
  /* font-size: 13px; */
  /* font-weight: 400; */
  /* line-height: 22px; */
  /* 169.231% */
  /* letter-spacing: 0.06px; */
  /* position: relative; */
  /* max-width: 80%; */
  word-break: break-word;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message
  .reaction {
  position: absolute;
  background: #ffffff;
  padding: 4px 12px;
  border-radius: 50px;
  bottom: -22px;
  left: 12px;
  border: 1px solid #5d2be3;
  visibility: hidden;
  opacity: 0;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message
  .reaction.active {
  visibility: visible;
  opacity: 1;
  transition-duration: 250ms;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message
  .reaction
  .icon {
  font-size: 18px;
  cursor: pointer;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message
  .reaction
  .icon:hover {
    font-size: 22px;
    transition-duration: 300ms;
    margin: 0px 3px 0px 3px;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message:hover
  .reaction,
.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .message.active
  .reaction {
  visibility: visible;
  opacity: 1;
  transition-duration: 250ms;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble
  .chatbot-avatar {
  position: absolute;
  bottom: 0;
  left: 0;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble.bot {
  justify-content: start;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble.bot
  .message {
  background: #edecf5;
  color: #000;
  /* margin-left: 30px; */
  /* margin-bottom: 30px; */
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble.client {
  justify-content: end;
}

.ai-chat-widget
  .chat-widget-container
  .chat-window
  .chat-history
  .chat-bubble.client
  .message {
  background: #5141e7;
  color: #fff;
}


/* Adjusting the layout to make chat-history scrollable and chat-input stick at the bottom */
.ai-chat-widget {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Make the widget take full height of the parent */
}

.floating-chat-window
  .ai-chat-widget{
    height: unset; 
  }

.chat-widget-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Make it fill the parent's height */
}

.chat-window {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto; /* Make chat-history scrollable */
}

.chat-input {
  flex-shrink: 0;
  z-index: 1;
  /* Ensure the chat-input stays at the bottom */
}

/* --- for typing loader --- */

.typing {
  position: relative;
  padding: 6px 8px;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.confirmation-popup {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(4px); */
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.blur{
  filter: blur(4px);
}