
@import "components/color";
@import "components/variables";
@import "components/typography";
@import "components/material-base";
@import "components/mixins";
@import "components/table";
@import "components/responsive";
@import "components/base";
@import "components/custom";



@import "components/buttons";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: $blue-primary;
}
