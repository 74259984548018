.projectCreateSection {
  .CreatePresentation {
    .accountTypeTitle {
      h1 {
        font-size: 23px;
        font-weight: 400;
        text-align: center;
        color: #02000a;
        margin-bottom: 30px;
        @include phone {
          font-size: 20px;
        }
        @include large {
          margin-top: 30px;
          margin-bottom: 50px;
        }
      }
    }
  }
}

.rsvpEventfieldBox {
  margin: 0px;
  .eventTableInner {
    padding: 15px;
  }
  .completeRegisterboxes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include phone {
      display: block;
    }
    .eventFullInput {
      padding: 25px 15px 10px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &.recipeintsCard {
        width: 55%;
        margin: 0 auto 20px;
        padding: 30px 35px 20px;
        @include phone {
          width: 100%;
          padding: 20px 20px 10px;
        }
        @include tablet {
          width: 100%;
        }
      }
      @include phone {
        display: block;
      }
      .field-group {
        flex: 0 0 31%;
        @include tabletPortrait {
          flex: 0 0 48%;
        }
        &:first-child {
          @include tabletPortrait {
            flex: 0 0 100%;
          }
        }
        &:nth-child(2) {
          @include tabletPortrait {
            flex: 0 0 100%;
          }
        }
        &.event_location{
          @include tabletPortrait {
            flex: 0 0 100% !important;
          }
        }
        &.timeClock {
          @include tabletPortrait {
            flex: 0 0 100% !important;
          }
          .formTime {
            &:after {
              display: none;
            }
          }
        }
      }
      .field_group75 {
        flex: 0 0 65%;
        @include tabletPortrait {
          flex: 0 0 100%;
        }
      }
      .field_group75Both {
        flex: 0 0 65%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include phone {
          display: block;
        }
        .inputField {
          flex: 0 0 48%;
        }
        @include tabletPortrait {
          flex: 0 0 100%;
        }
      }
      .field_group25Both {
        flex: 0 0 31%;
        @include phone {
          flex: 0 0 100%;
        }
        .form-control {
          min-height: 145px;
        }
        @include tabletPortrait {
          flex: 0 0 100%;
        }
      }
    }
    .reseventRegBox {
      .formSection {
        .field-group {
          flex: 0 0 100%;
          @media screen and (max-width: 1366) {
          }
        }
        .editProfileBtn {
          margin-top: 30px;
        }
      }
    }
    .induvidualPayColl {
      padding: 15px;
      flex: 0 0 48%;
      box-sizing: border-box;
      &:first-child {
        @include phone {
          margin-bottom: 20px;
        }
      }
      h3 {
        font-size: 16px;
        color: #7b7b82;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: 0px;
      }
      .induvidualItemPayment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tablet1 {
          display: block;
        }
        @include phone {
          display: block;
        }
        .paymentColumn {
          flex: 0 0 48%;
          .paymentRadio {
            min-height: 45px;
            display: flex;
            align-items: center;
            .customRadioBtn {
              position: relative;
              padding: 6px 0 3px 33px;
              margin-bottom: 0px;
              cursor: pointer;
              font-size: 14px;
              -webkit-user-select: none;
              user-select: none;
              color: #8f8f8e;
              font-weight: 400 !important;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                min-height: 13px !important;
                @include phone {
                  width: 70%;
                }
                &:checked {
                  & + .radiobtn {
                    &::after {
                      display: block;
                    }
                  }
                }
              }
              .radiobtn {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #fff1ee;
                border-radius: 50%;
                border: 2px solid $themeOrangeColor;
                &:after {
                  background: $themeOrangeColor;
                  border-radius: 50%;
                  height: 10px;
                  left: 50%;
                  top: 50%;
                  width: 10px;
                  content: '';
                  position: absolute;
                  display: none;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
.react-time-picker__inputGroup{
  box-shadow: 0px 0px 4px #dedede;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.yourInformationCol {
  .field-group-flex {
    @include tablet1 {
      display: block !important;
    }
  }
}
.paymentRadio label.customRadioBtn input:checked ~ .radiobtn:after {
  display: block;
}
.paymentRadio label.customRadioBtn input:checked ~ .radiobtn {
  background-color: #fff1ee;
}
.CRCircularBox {
  border: 1px solid #e6e6e6;
  border-radius: 25px;
}
.paymentMethod {
  margin-top: 15px;
  padding: 15px;
  padding-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  @include large {
    margin-top: 30px;
  }
  .papymentCardPaypal {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    align-items: center;
    @include phone {
      display: block;
    }
    .buttonPaypalCard {
      @include phone {
        margin-bottom: 15px;
      }
    }
  }
  .papymentCardDetail {
    border-top: 1px solid #ebebeb;
    padding-top: 15px;
    .papymentCardField {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .field-group {
        flex: auto;
        padding-right: 20px;
        box-sizing: border-box;
        @include phone {
          padding-right: 0px;
        }
        &.cardNumber {
          width: 35%;
          @include phone {
            width: 100%;
          }
        }
        &.cardName {
          width: 35%;
          @include phone {
            width: 100%;
          }
        }
        &.cardExpire {
          width: 15%;
          @include phone {
            width: 45%;
            margin-right: 20px;
          }
        }
        &.cardCVCNumber {
          width: 15%;
          @include phone {
            width: 45%;
          }
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}
.buttonPaypalCard {
  .form-group {
    margin: 0;
    display: flex;
    .customRadioBtn {
      background: #fafafa;
      border-radius: 7px;
      margin-bottom: 0;
      padding: 14px 10px 17px 50px;
      font-size: 16px;
      font-weight: 500;
      color: #3f3e43;
      position: relative;
      min-width: 150px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      @include phone {
        min-width: 100px;
      }
      @include phone {
        padding: 10px 5px 10px 40px;
        font-size: 14px;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked {
          & + .radiobtn {
            background-color: #e5d7f8;
            border-color: #5b33f1;
            &::after {
              display: block;
            }
          }
        }
      }
      .radiobtn {
        position: absolute;
        top: 13px;
        left: 15px;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border-radius: 7px;
        border: 1px solid #707070;
        @include phone {
          top: 5px;
          left: 7px;
          height: 25px;
          width: 25px;
        }
      }
    }
    .active {
      background: #e3defc;
      border-color: #5b33f1;
      color: #5b33f1;
    }
  }
}
.papymentCardPaypal .buttonPaypalCard .customRadioBtn .radiobtn:after {
  content: '';
  display: none;
  position: absolute;
  left: 8px;
  top: 4px;
  width: 7px;
  height: 13px;
  border: solid #5b33f1;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.submittedPayment {
  h3 {
    font-size: 16px;
    color: #3f3e43;
    font-weight: 400;
    margin-bottom: 3px;
    margin-top: 0px;
  }
  .submittedAmt {
    font-size: 30px;
    font-weight: 600;
    color: #02000a;
    sub {
      font-size: 16px;
      color: #3f3e43;
      font-weight: 400;
    }
  }
}
.accountTypeContent {
  .submitForm {
    padding: 15px;
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @include phone {
      width: 100%;
      display: block;
      padding: 15px 0px;
    }
    button {
      flex: 0 0 48%;
      cursor: pointer;
      font-size: 19px;
      @include phone {
        width: 100%;
      }
      &:first-child {
        @include phone {
          margin-bottom: 10px;
        }
      }
    }
  }
}

// Project Steps design
.projectSteps {
  .MuiStep-root {
    &.MuiStep-completed {
      svg {
        path {
          fill: $themeOrangeColor;
        }
      }
    }
  }
  .MuiStepper-root {
    padding: 0px;
  }
}

.stepsButtonAlternate {
  padding: 0px 0px 25px 0px;
  text-align: center;
  button {
    border: none;
    background-color: transparent;
    padding: 15px 40px;
    font-size: 15px;
    color: #7b7b82;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    &.active {
      color: #5b33f1;
      background: #e3defc;
      border: 1px solid #5b33f1;
    }
  }
}

.paymentRadio2 {
  min-height: 45px;
  display: flex;
  align-items: center;
}
.formSection .paymentRadio2 label.customRadioBtn {
  position: relative;
  padding: 4px 0px 3px 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #8f8f8e;
  font-weight: 400 !important;
  min-height: 24px;
  display: flex;
  align-items: center;
}
.paymentRadio2 label.customRadioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.paymentRadio2 label.customRadioBtn .radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff1ee;
  border-radius: 50%;
  border: 2px solid $themeOrangeColor;
}
.paymentRadio2 label:first-child {
  width: 50% !important;
}
.paymentRadio2 label.customRadioBtn input:checked ~ .radiobtn:after {
  display: block;
}
.paymentRadio2 label.customRadioBtn .radiobtn:after {
  background: $themeOrangeColor;
  border-radius: 50%;
  height: 10px;
  left: 50%;
  top: 50%;
  width: 10px;
  content: '';
  position: absolute;
  display: none;
  transform: translate(-50%, -50%);
}
.completeRegisterboxes {
  .loc1 {
    position: relative;
    .autocomplete-dropdown-container {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      box-shadow: 0px 0px 4px #dedede;
      width: 100%;
      max-height: 180px;
      overflow: auto;
      .suggestion-item {
        background-color: rgb(255, 255, 255);
        cursor: pointer;
        padding: 3px 10px;
        font-size: 14px !important;
        width: 100%;
      }
      .suggestion-item--active {
        font-size: 14px !important;
        padding: 3px 10px !important;
      }
    }
  }
}

// Recepient
.recipientsMainsec {
  .fullSreenHeader {
    position: relative;
    @include phone {
      border: none;
      display: flex;
      align-items: center;
    }
    .buttonsRecipient {
      .addRecipntss {
        @include mobileTabletNew {
          display: none;
        }
      }
      .editProfileBtn {
        margin-top: 40px;
      }
      .selectButton {
        @include tabletPortrait {
          padding-right: 55px;
        }
      }
      .addRecipntssIcon {
        display: none;
        font-weight: 900;
        font-size: 31px;
        position: absolute;
        top: 20px;
        right: 0px;
        width: 55px;
        justify-content: center;
        align-items: center;
        color: #ede83a !important;
        @include mobileTabletNew {
          display: flex;
        }
      }
      .desktopSelectGroup {
        @include phone {
          display: none;
        }
      }
    }
    .eventControlSearch {
      @include mobileTabletNew {
        padding-right: 55px;
      }
      @include tabletPortrait {
        padding-right: 0px;
      }
      form {
        .filterSerchSubmit {
          display: flex;
          button {
            width: 60px;
            height: 55px;
            background: #fafafa;
            border: 1px solid #fafafa;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            cursor: pointer;
          }
          .form-control {
            background: #fafafa;
            border: 1px solid #fafafa;
            font-size: 14px;
            font-weight: 400;
            height: 100%;
            padding: 0;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            height: 50px;
            outline: 0;
            min-width: 200px;
            @include tabletPortrait {
              min-width: 200px;
            }
            @include phone {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .resipientsHeader1Sec {
    border-top: none !important;
    margin-left: 0px;
  }
}
.contactsTable {
  .eventTableInner {
    position: relative;
    @include phone {
      margin-top: 20px;
    }
    .MobileSelectgroup {
      display: none;
      position: absolute;
      top: 0px;
      right: 0px;
      @include phone {
        display: block;
      }
    }
  }
}

.emailEditorForm {
  @include phone {
    width: 100vw;
    overflow: auto;
    top: 200px;
    position: absolute;
  }
}
.emailSchedulerModal {
  .EmailSchdularRescipnt {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        left: -90px !important;
      }
    }
  }
}

.eventRegistrationPage {
  .eventFullInput {
    .field-group {
      &:nth-child(3) {
        @include tabletPortrait {
          flex: 0 0 100%;
        }
      }
      &:nth-child(5) {
        @include tabletPortrait {
          flex: 0 0 100%;
        }
      }
    }
  }
}
.completeRegisterboxes {
  .recipeintsCard {
    margin-top: 40px;
    flex-wrap: wrap;
    .formSection {
      width: 100%;
      .form-group {
        width: 100%;
        .field-group {
          flex: 0 0 48%;
        }
      }
    }
  }
}
