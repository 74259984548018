.typeProjectName {
  .eventIDRow {
    margin-bottom: 20px;
    .eventIDTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone {
        display: block;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 23px;
        color: #02000a;
        font-weight: 400;
        @include phone {
          font-size: 18px;
          padding-right: 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        @include LargeScreens {
          font-size: 35px;
          margin-bottom: 20px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1400px) {
          font-size: 20px;
        }
        .eventDateMobile {
          display: none;
          @include phone {
            width: 36px;
            height: 36px;
            display: flex;
            padding: 0px 7px;
            justify-content: center;
            align-items: center;
            border: 1px solid #d5d6d6;
            border-radius: 50%;
            box-sizing: border-box;
          }
          i {
            font-size: 22px;
            color: #3f3e43;
          }
        }
      }
      button {
        background-color: transparent;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 17px;
      }
    }
  }
  .eventTypeDateLoc {
    display: flex;
    justify-content: flex-start;
    .eventTypeVenue {
      padding-right: 40px;
      @include tabletPortrait {
        padding-right: 15px;
      }
      &:last-child {
        padding-right: 0px;
      }
      @include LargeScreens {
        flex: 0 0 25%;
      }
      @include phone {
        padding: 0px;
      }
    }
    @include phone {
      display: block;
      height: 0px;
      overflow: hidden;
    }
    &.Open {
      height: 100%;
      transition: all 0.3s;
    }
    .eventTypeVenue {
      ul {
        padding: 0px;
        li {
          list-style: none;
          position: relative;
          padding-left: 30px;
          @include phone {
            margin-bottom: 15px;
          }
          .venueIcon {
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            img {
              width: 100%;
            }
          }
          .venueTitle {
            font-size: 15px;
            margin-bottom: 10px;
            color: #908e9a;
            @media screen and (min-width: 1025px) and (max-width: 1400px) {
              font-size: 13px;
            }
            @include tabletPortrait {
              min-width: 100px;
              display: block;
            }
            @include LargeScreens {
              font-size: 22px;
            }
          }
          h3 {
            font-size: 17px;
            color: #3d3c2b;
            font-weight: 500;
            margin-top: 5px;
            @media screen and (min-width: 1025px) and (max-width: 1400px) {
              font-size: 15px;
            }
            @media (min-width: 1200px) {
              max-width: 300px;
            }
            @include tabletPortrait {
              font-size: 12px;
            }
            @include LargeScreens {
              font-size: 26px;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .projectDesignRsvpRow {
    margin-top: 25px;
    display: flex;
    margin-bottom: 0px;
    @include phone {
      display: block;
    }
    @include tabletPortrait {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .projectDesignSystem {
      flex: 0 0 23%;
      margin-right: 20px;
      position: relative;
      @include mobileTabletNew {
        margin: 0px;
        margin-bottom: 20px;
      }
      @include tabletPortrait {
        flex: 0 0 48%;
      }
    }
    .designSystemImg {
      position: relative;
      background: #fff;
      padding: 15px;
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.07);
      border-radius: 25px;
      img {
        width: 100%;
      }
    }
    .rsvpFormSec {
      flex: 0 0 23%;
      margin-right: 20px;
      position: relative;
      @include mobileTabletNew {
        margin: 0px;
        margin-bottom: 20px;
      }
      @include tabletPortrait {
        flex: 0 0 48%;
      }
      .rsvpFormBox {
        position: absolute;
        top: 0;
        width: 80%;
        padding: 25px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        .rsvpFormBoxInner {
          background: #ffffff;
          padding: 10px;
          border-radius: 15px;
          h3 {
            font-size: 18px;
            color: #5b33f1;
            margin-bottom: 20px;
            margin-top: 0px;
            font-weight: 400;
            @include large {
              font-size: 25px;
            }
          }
          .formSection {
            form {
              @include phone {
                display: flex;
              }
            }
            label {
              margin-bottom: 4px;
              font-size: 13px;
            }
            input {
              height: 25px;
              min-height: 25px;
              padding: 10px;
              font-size: 13px;
              font-weight: 400;
              @include large {
                height: 27px;
              }
            }
            .themeBlueButton {
              width: 100%;
              font-size: 13px;
              text-transform: capitalize;
              height: 30px;
              min-height: 30px !important;
              font-weight: 400 !important;
              line-height: 11px !important;
              @include large {
                height: 30px;
              }
            }
            .fullWidthInput {
              margin-bottom: 15px;
            }
            .field-group {
              @include large {
                flex: 0 0 100%;
              }
            }
          }
        }
      }
    }
    .customemailEdit {
      flex: 0 0 23%;
      position: relative;
      margin-right: 20px;
      .editibaleEmailBox {
        position: relative;
        background: #fff;
        padding: 15px;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.07);
        border-radius: 25px;
        img {
          width: 100%;
          border-radius: 15px;
        }
      }
      @include tabletPortrait {
        flex: 0 0 48%;
        margin: 0px;
      }
    }
    .TypeprojectCardTilte {
      margin-top: 10px;
      h3 {
        font-size: 18px;
        color: #02000a;
        font-weight: 500;
        margin-top: 5px;
        display: flex;
        @include AboveDesktop {
          font-size: 20px;
          display: block;
        }
        @media screen and (min-width: 1025px) and (max-width: 1400px) {
          font-size: 16px;
          display: block;
        }
        @include tabletPortrait {
          display: block;
        }
        @include LargeScreens {
          font-size: 26px;
        }
        span {
          display: block;
          margin-left: 5px;
          @media (min-width: 1100px) {
            margin: 0px;
          }
          @include tabletPortrait {
            margin: 0px;
          }
        }
      }
    }
    .editEmailBttn {
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #5b34f1;
      border-radius: 3px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        width: 11px;
        height: 11px;
        fill: #fff;
      }
    }
  }
  .projectTypeContinue {
    padding: 50px 10px 0px;
    text-align: center;
    button {
      min-width: 230px;
      min-height: 55px !important;
      font-size: 19px !important;
    }
  }
}
.DesignSystemText {
  position: absolute;
  bottom: 80px;
  width: 135px;
  left: 26%;
  @media (min-width: 1500px) {
    width: 62%;
  }
  @include LargeScreens {
    bottom: 110px;
  }
  h4 {
    color: #5b33f1;
    margin-bottom: 15px;
    font-size: 25px;
    @media screen and (min-width: 1025px) and (max-width: 1450px) {
      font-size: 21px;
    }
    @include tabletPortrait {
      font-size: 19px;
    }
    @include LargeScreens {
      font-size: 30px;
    }
    span {
      display: block;
    }
  }
  p {
    font-size: 12px;
    color: #908e9a;
    @include LargeScreens {
      font-size: 17px;
    }
  }
}
.designSystemImg {
  .DesignSystemText {
    h4 {
      @media screen and (min-width: 1025px) and (max-width: 1400px) {
        font-size: 21px;
      }
    }
  }
}
.projectStepRow {
  .submitForm {
    padding: 20px;
    text-align: center;
  }
}
