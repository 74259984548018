.filterSearchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .SearchGrid {
    display: flex;
    .mr-2 {
      @include phone {
        margin-right: 0px !important;
      }
    }
    .eventControlList {
      @include tabletPortrait {
        display: none;
      }
    }
    .eventControlGrid {
      @include tabletPortrait {
        display: none;
      }
    }
    .eventControlSearch {
      position: relative;
      form{
        display: flex;
      }
      input {
        background: #fafafa;
        border: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        padding: 0px;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        height: 50px;
        outline: 0;
        @media screen and (min-width: 1367px) and (max-width: 1550px) {
          width: 200px;
        }
        @include normalMiddiumScreen {
          width: 130px;
        }
        @media (min-width: 1367px) and (max-width: 1450px) {
          width: 160px;
        }
        @media (min-width: 1451px) and (max-width: 1550px) {
          width: 160px;
        }
        @media screen and (min-width: 320px) and (max-width: 350px) {
          width: 40px;
        }
        @media screen and (min-width: 351px) and (max-width: 365px) {
          width: 75px;
        }
        @media screen and (min-width: 366px) and (max-width: 380px) {
          width: 90px;
        }
        @media screen and (min-width: 381px) and (max-width: 440px) {
          width: 130px;
        }
      }
      .eventListFilter34 {
        input {
          @media screen and (min-width: 320px) and (max-width: 350px) {
            width: 70px;
          }
          @media screen and (min-width: 351px) and (max-width: 365px) {
            width: 120px;
          }
          @media screen and (min-width: 366px) and (max-width: 380px) {
            width: 130px;
          }
          @media screen and (min-width: 381px) and (max-width: 440px) {
            width: 170px;
          }
        }
      }
      .searchIcon {
        position: absolute;
        left: 15px;
        top: 18px;
      }
    }
    button {
      // width: 60px;
      // height: 50px;
      background: #fafafa;
      border: 1px solid #fafafa;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      cursor: pointer;
      @include phone {
        width: 40px;
        height: 48px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 1px solid #d8d8d8;
      }
      &.active {
        border: 1px solid $themeDarkBlue;
      }
    }
    .dropdown {
      position: relative;
      .dropdown-menu {
        right: 0 !important;
        left: unset !important;
        margin-top: -1px;
        background: #e4defd;
        border: 1px solid $themeDarkBlue;
        border-radius: 7px;
        border-top-right-radius: 0;
        padding: 20px 0;
        z-index: 0;
        transform: translate(0px, 50px) !important;
        z-index: 9999;
        top: 0px;
        position: absolute;
        min-width: 150px;
        display: none;
        a {
          display: block;
          color: $themeDarkBlue;
          font-size: 14px;
          font-weight: 500;
          padding: 8px 20px;
          text-decoration: none;
          transition: all 0.3s ease;
          &:hover {
            color: #fff !important;
            background-color: $themeDarkBlue;
          }
        }
      }
    }
  }
  .buttonGroups {
    button {
      font-size: 14px;
      text-transform: capitalize;
      color: #7b7b82;
      @media (max-width: 1450px) {
        font-size: 11px;
        padding: 7px 16px !important;
        font-size: 12px !important;
      }
      @include large {
        font-size: 13px;
      }
      @include mainLargeScreen {
        font-size: 15px;
      }
      @include normalMiddiumScreen {
        padding: 7px 8px !important;
        font-size: 12px !important;
      }
      @media screen and (min-width: 1451px) and (max-width: 1550px) {
        padding: 7px 16px !important;
        font-size: 12px !important;
      }
    }
  }
  .filterControler {
    .ctrlBtn {
      height: 100%;
      padding: 12px 15px;
      font-size: 15px;
      font-weight: 500;
      border: 1px solid #d8d8d8;
      border-radius: 7px;
      background: #fff;
      color: #7b7b82;
      height: 48px;
      box-sizing: border-box;
      cursor: pointer;
      .fa {
        font-size: 20px;
      }
    }
    .newEvent {
      background-color: $themeDarkBlue;
      color: $backgroundWhite;
      border: none;
      cursor: pointer;
      &:hover {
        color: $backgroundWhite;
      }
    }
    .exportEvent {
      position: relative;
    }
    .tableDots {
      position: absolute;
      top: -1px;
      left: 0px;
      width: 30px;
      height: 43px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 0px;
      cursor: pointer;
      padding-top: 9px;
    }
  }
  .middleFilterCont {
    display: flex;
    .exportEvent {
      @include phone {
        margin-right: 5px !important;
      }
    }
    @include tabletPortrait {
      display: flex !important;
    }
  }
  .filterControler {
    &.buttonGroups {
      @include tabletPortrait {
        display: none;
      }
    }
  }
}
// .eventListFilter34{
//     input{
//         @media screen and (min-width:320px) and (max-width:350px){
//             width: 70px;
//         }
//         @media screen and (min-width:351px) and (max-width:380px){
//             width: 130px;
//         }
//         @media screen and (min-width:381px) and (max-width:440px){
//             width: 170px;
//         }
//     }
// }
.filterSerchSubmit {
  button {
    @include phone {
      border: none !important;
    }
  }
}
.eventControlfilter {
  position: relative;
  .eventControlBtn {
    border-radius: 7px;
  }
  .eventControlBtn {
    & + .dropdown-menu {
      margin-top: -1.6px;
    }
  }
  .dropdown-menu {
    display: none;
    a {
      color: #5b33f1;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 20px;
      display: block;
      text-decoration: none;
      &:hover {
        background: #5b33f1;
        color: #ffffff;
      }
    }
  }
  &.Open {
    .dropdown-menu {
      display: block;
      right: 0 !important;
      left: unset !important;
      margin-top: -1px;
      background: #e4defd;
      border: 1px solid #5b33f1;
      border-radius: 7px;
      border-top-right-radius: 0;
      padding: 20px 0;
      position: absolute;
      z-index: 0;
      transform: translate(0px, 50px) !important;
      z-index: 9;
      top: 0px;
      min-width: 160px;
    }
    button {
      background: #e4defd;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border: 1px solid #5b33f1;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 1;
      position: relative;
      border-bottom: 0;
      z-index: 99;
    }
  }
}

.filterControler {
  .exportEvent {
    .dropdown-menu {
      top: -8px !important;
    }
  }
}
